import PersonalData from '../../models/PersonalData';
import { api, QueryParamKey } from '.';

export interface StrongAuthStartIdCardResponse {
  sessionToken: string;
}

export interface StrongAuthStartResponse {
  status: string;
  token: string;
  controlCode: string;
}

export interface MobileIdStartResponse extends StrongAuthStartResponse {
  tempToken: string;
}

export interface StrongAuthStatusResponse {
  status: string;
  name?: string;
  surname?: string;
  personalCode?: string;
  birthDate?: string;
  token?: string;
  tempToken?: string;
  personalData?: PersonalData;
}

const strongAuthApi = api.injectEndpoints({
  endpoints: (builder) => {
    return {
      startMobileId: builder.mutation<
        MobileIdStartResponse,
        { personalCode: string; phone: string; recaptchaToken: string | undefined }
      >({
        query: ({ personalCode, phone, recaptchaToken }) => ({
          url: '/strongAuth/startMobileId',
          method: 'post',
          params: {
            personalCode: personalCode,
            phone: phone,
            [QueryParamKey.recaptchaToken]: recaptchaToken,
          },
        }),
      }),
      mobileIdStatus: builder.mutation<
        StrongAuthStatusResponse,
        { token: string | undefined; recaptchaToken: string | undefined; tempToken: string }
      >({
        query: ({ token, recaptchaToken, tempToken }) => ({
          url: '/strongAuth/mobileIdStatus',
          method: 'get',
          params: {
            token: token,
            tempToken: tempToken,
            [QueryParamKey.recaptchaToken]: recaptchaToken,
          },
        }),
      }),
      startSmartId: builder.mutation<
        StrongAuthStartResponse,
        { personalCode: string; recaptchaToken: string | undefined }
      >({
        query: ({ personalCode, recaptchaToken }) => ({
          url: '/strongAuth/startSmartId',
          method: 'post',
          params: {
            personalCode: personalCode,
            [QueryParamKey.recaptchaToken]: recaptchaToken,
          },
        }),
      }),
      smartIdStatus: builder.mutation<
        StrongAuthStatusResponse,
        { token: string | undefined; recaptchaToken: string | undefined }
      >({
        query: ({ token, recaptchaToken }) => ({
          url: '/strongAuth/smartIdStatus',
          method: 'get',
          params: {
            token: token,
            [QueryParamKey.recaptchaToken]: recaptchaToken,
          },
        }),
      }),
      startIdCard: builder.mutation<StrongAuthStartIdCardResponse, { recaptchaToken: string | undefined }>({
        query: ({ recaptchaToken }) => ({
          url: '/strongAuth/startIdCard',
          method: 'post',
          params: {
            [QueryParamKey.recaptchaToken]: recaptchaToken,
          },
        }),
      }),
      idCardStatus: builder.mutation<
        StrongAuthStatusResponse,
        { token: string | undefined; recaptchaToken: string | undefined }
      >({
        query: ({ token, recaptchaToken }) => ({
          url: '/strongAuth/idCardStatus',
          method: 'get',
          params: {
            token: token,
            [QueryParamKey.recaptchaToken]: recaptchaToken,
          },
        }),
      }),
    };
  },
});

export const {
  useStartMobileIdMutation,
  useMobileIdStatusMutation,
  useStartSmartIdMutation,
  useSmartIdStatusMutation,
  useStartIdCardMutation,
  useIdCardStatusMutation,
} = strongAuthApi;

export default strongAuthApi.reducer;
