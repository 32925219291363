import { Grid } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { UseFormReturnType } from '@mantine/form/lib/use-form';
import TextInput from '../../../../components/form/TextInput';
import { ProfileDataFormValues } from '../../../../utility/saveProfileUtils';
import { useAppSelector } from '../../../../hooks/store';

const CustomerPhoneRow = (props: {
  form: UseFormReturnType<ProfileDataFormValues>;
  setPromptContact: (params: { action: 'change' | 'add'; type: 'phoneNumber' }) => void;
}) => {
  const { t } = useTranslation();
  const personalData = useAppSelector((state) => state.user.personalData);

  return (
    <>
      <Grid
        gutter="lg"
        sx={(theme) => ({
          marginTop: theme.other.spacing(2),
          alignItems: 'flex-start',
        })}
      >
        <Grid.Col sm={12}>
          <TextInput
            {...props.form.getInputProps('phoneNumber')}
            readOnly={true}
            onClick={() =>
              props.setPromptContact({
                action: personalData?.profileData.phoneNumber ? 'change' : 'add',
                type: 'phoneNumber',
              })
            }
            required
            label={t('birthdayOffers.profileData.phoneNumber')}
            placeholder={t('birthdayOffers.profileData.phoneNumber')}
          />
        </Grid.Col>
      </Grid>
    </>
  );
};

export default CustomerPhoneRow;
