import { Grid } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form/lib/use-form';
import { useTranslation } from 'react-i18next';
import { ProfileDataFormValues } from '../../../../utility/saveProfileUtils';
import { Language } from '../../../../utility';
import SelectInput from '../../../../components/form/SelectInput';

const CustomerPreferedLanguageRow = (props: { form: UseFormReturnType<ProfileDataFormValues> }) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid
        gutter="lg"
        sx={(theme) => ({
          marginTop: theme.other.spacing(2),
          alignItems: 'flex-start',
        })}
      >
        <Grid.Col sm={12}>
          <SelectInput
            label={t('birthdayOffers.profileData.communicationLanguage')}
            {...props.form.getInputProps('language')}
            required
            data={[
              {
                value: Language.ESTONIAN,
                label: t('language.estonian'),
              },
              {
                value: Language.ENGLISH,
                label: t('language.english'),
              },
              {
                value: Language.RUSSIAN,
                label: t('language.russian'),
              },
            ]}
          />
        </Grid.Col>
      </Grid>
    </>
  );
};

export default CustomerPreferedLanguageRow;
