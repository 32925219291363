import { Grid } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import TextInput from '../../../../components/form/TextInput';

const CustomerBirthDateRow = (props: { day?: string; month?: string; year?: string }) => {
  const { t } = useTranslation();

  return (
    <Grid
      gutter="lg"
      sx={(theme) => ({
        marginTop: theme.other.spacing(2),
        alignItems: 'flex-start',
      })}
    >
      <Grid.Col md={12} lg={4}>
        <TextInput value={props.day || ''} label={t('birthdayOffers.profileData.dateOfBirth')} disabled={true} />
      </Grid.Col>
      <Grid.Col md={12} lg={4}>
        <TextInput value={props.month || ''} label="&nbsp;" disabled={true} />
      </Grid.Col>
      <Grid.Col md={12} lg={4}>
        <TextInput value={props.year || ''} label="&nbsp;" disabled={true} />
      </Grid.Col>
    </Grid>
  );
};

export default CustomerBirthDateRow;
