import { Box } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { DataLine } from '../../../store/api/purchaseHistoryApi';
import useFormatCurrency from '../../../utility/hooks/useFormatCurrency';

interface CardLinesProps {
  data: DataLine;
}

const CardLines = ({ data }: CardLinesProps) => {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();
  const filteredKeys = Object.keys(data).filter((key) => {
    if (key == 'ExpWarning') return false;

    return true;
  });

  const renderNames = filteredKeys.map((key, i) => {
    let { name } = data[key];

    if (key == 'GetForRcp') name = t('purchaseHistory.card.moneyEarned');
    if (key == 'Balance') name = t('purchaseHistory.card.moneyBalance');

    return <Box key={i}>{name}</Box>;
  });

  const renderAmounts = filteredKeys.map((key, i) => {
    const { amount } = data[key];
    if (!amount) return;
    return <Box key={i}>{formatCurrency(amount)}</Box>;
  });

  return (
    <Box
      sx={(theme) => ({
        padding: `${theme.other.spacing(1.5)} 0`,
      })}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginRight: theme.other.spacing(5),
            textAlign: 'right',
          })}
        >
          {renderNames}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            fontFamily: 'ProximaNovaBold',
            textAlign: 'right',
            wordBreak: 'initial',
          }}
        >
          {renderAmounts}
        </Box>
      </Box>
    </Box>
  );
};

export default CardLines;
