import { api } from '.';

export interface RewardsResponse {
  rewardID: string;
  status: number;
  isEligible: boolean;
}

const rewardApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getRewards: builder.mutation<RewardsResponse[], number>({
      query: (accountId) => ({
        url: `/reward/${accountId}`,
        method: 'GET',
      }),
    }),
    getRewardsByCard: builder.mutation<RewardsResponse[], string>({
      query: (cardNo) => ({
        url: `/reward/card/${cardNo}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetRewardsMutation, useGetRewardsByCardMutation } = rewardApi;

export default rewardApi.reducer;
