import { Title, Text, Box } from '@mantine/core';
import { useCallback, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { ApiError, ErrorCode } from '../../../models/ApiError';
import RecaptchaAction from '../../../models/RecaptchaAction';
import {
  useProfileLandingSendConfirmationCodeMutation,
  useProfileLandingConfirmContactMutation,
} from '../../../store/api/profileLandingApi';
import { setTempToken } from '../../../store/profileLandingSlice';
import ConfirmCodeForm from '../../ForgotPassword/ConfirmCode/ConfirmCodeForm';

const ContactConfirmation = (props: { nextStep: () => void }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const tempToken = useAppSelector((state) => state.profileLanding.tempToken);
  const contactTypeToConfirm = useAppSelector((state) => state.profileLanding.contactTypeToConfirm);

  const [confirmContact, confirmContactQueryState] = useProfileLandingConfirmContactMutation();
  const [sendConfirmationCode, sendConfirmationCodeQueryState] = useProfileLandingSendConfirmationCodeMutation();

  const getSendCodeErrorMessage = useCallback(
    (error: ApiError) => {
      const errorCode = error.data?.message;
      if (error.status === 403) {
        return t('api.sessionExpiredError');
      }
      if (errorCode === ErrorCode.SendingCodeFailed) {
        return t('createAccount.sendCode.sendingCodeFailed');
      }
      if (errorCode === ErrorCode.RecaptchaError) {
        return t('api.recaptchaError');
      }
      return t('api.unknownError');
    },
    [t],
  );

  const getVerifyCodeErrorMessage = useCallback(
    (error: ApiError) => {
      const errorCode = error.data?.message;
      if (error.status === 403) {
        return t('api.sessionExpiredError');
      }
      if (errorCode === ErrorCode.ConfirmationCodeVerificationFailed) {
        return t('createAccount.verifyCode.codeVerificationFailedError');
      }
      if (errorCode === ErrorCode.RecaptchaError) {
        return t('api.recaptchaError');
      }
      return t('api.unknownError');
    },
    [t],
  );

  useEffect(() => {
    console.log('loading confirmation step');
  }, []);

  useEffect(() => {
    if (confirmContactQueryState.isSuccess) {
      dispatch(setTempToken(confirmContactQueryState.data?.tempToken));
      props.nextStep();
    }
  }, [confirmContactQueryState.isSuccess, confirmContactQueryState.data?.tempToken, dispatch, props]);

  useEffect(() => {
    if (sendConfirmationCodeQueryState.isSuccess) {
      toast(t('createAccount.verifyCode.newCodeSent'), { type: 'success' });
    }
    if (sendConfirmationCodeQueryState.isError && sendConfirmationCodeQueryState.error) {
      const error = sendConfirmationCodeQueryState.error as ApiError;
      const errorMessage = getSendCodeErrorMessage(error);
      toast(errorMessage, { type: 'error' });
    }
  }, [
    sendConfirmationCodeQueryState.isSuccess,
    sendConfirmationCodeQueryState.isError,
    sendConfirmationCodeQueryState.error,
    sendConfirmationCodeQueryState.data?.contactType,
    t,
    getSendCodeErrorMessage,
  ]);

  useEffect(() => {
    if (confirmContactQueryState.isError && confirmContactQueryState.error) {
      const error = confirmContactQueryState.error as ApiError;
      const errorMessage = getVerifyCodeErrorMessage(error);
      toast(errorMessage, { type: 'error' });
    }
  }, [confirmContactQueryState.isError, confirmContactQueryState.error, getVerifyCodeErrorMessage]);

  const handleVerifyCode = useCallback(
    async (code: string) => {
      if (executeRecaptcha && contactTypeToConfirm) {
        const recaptchaToken = await executeRecaptcha(RecaptchaAction.ProfileLandingConfirmContact);
        confirmContact({
          contactType: contactTypeToConfirm,
          code: code,
          tempToken: tempToken || '',
          recaptchaToken: recaptchaToken,
        });
      }
    },
    [executeRecaptcha, confirmContact, tempToken, contactTypeToConfirm],
  );

  const handleResendCode = useCallback(async () => {
    if (executeRecaptcha && contactTypeToConfirm) {
      const recaptchaToken = await executeRecaptcha(RecaptchaAction.ProfileLandingSendConfirmationCode);
      sendConfirmationCode({ contactType: contactTypeToConfirm, tempToken: tempToken || '', recaptchaToken });
      confirmContactQueryState.reset();
    }
  }, [executeRecaptcha, sendConfirmationCode, tempToken, confirmContactQueryState, contactTypeToConfirm]);

  const isLoading = sendConfirmationCodeQueryState.isLoading;

  return (
    <Box
      sx={(theme) => ({
        width: '60% !important',
        [theme.fn.smallerThan(960)]: {
          width: '100% !important',
        },
      })}
    >
      <Title
        sx={(theme) => ({
          color: theme.colors.brandGray,
          fontFamily: 'ProximaNovaBold',
          fontSize: '1.25rem',
          fontWeight: 700,
          letterSpacing: '0.01em',
          [theme.fn.smallerThan(800)]: {
            fontSize: '2.2rem',
          },
          [theme.fn.smallerThan(600)]: {
            fontSize: '1.6rem',
          },
        })}
      >
        <Text>
          {contactTypeToConfirm == 'email'
            ? t('profileLanding.contactConfirmationStep.titleEmail')
            : t('profileLanding.contactConfirmationStep.titlePhone')}
        </Text>
      </Title>
      <Text
        sx={(theme) => ({
          '&': {
            marginTop: theme.other.spacing(2),
            color: '#828282',
          },
        })}
      >
        {contactTypeToConfirm == 'email'
          ? t('profileLanding.contactConfirmationStep.descriptionEmail')
          : t('profileLanding.contactConfirmationStep.descriptionPhone')}
      </Text>
      <ConfirmCodeForm
        onSubmitCode={handleVerifyCode}
        onResendCode={handleResendCode}
        loading={isLoading}
        resendCodeLoading={false}
        error={''}
      />
    </Box>
  );
};

export default ContactConfirmation;
