import { AccountVerificationResponsePayload } from './verificationApi';
import PersonalData from '../../models/PersonalData';
import ProfileData from '../../models/ProfileData';
import UserConsents from '../../models/UserConsents';
import { api, QueryParamKey, RecaptchaProtected } from '.';

interface GetPersonalDataQueryPayload {
  accountId: number;
}

interface SaveProfileQueryPayload extends RecaptchaProtected {
  cardNumber: string;
  profileData: ProfileData;
  consents: UserConsents;
}

export interface UpdateProfileQueryPayload {
  cardNumber: string;
  profileData: ProfileData;
  consents: UserConsents;
}

interface UpdateContactAndSendCodeResponsePayload {
  verificationToken: string;
}

interface UpdateContactAndSendCodeQueryPayload {
  email?: string;
  phoneNumber?: string;
  cardNumber: string;
  accountId: number;
}

interface UpdateContactAndVerifyCodeResponsePayload {
  verificationToken: string;
  personalData: PersonalData;
}
interface UpdateContactAndVerifyCodeQueryPayload {
  code: string;
  verificationToken: string;
}

const profileApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUserData: builder.mutation<PersonalData, GetPersonalDataQueryPayload>({
      query: ({ accountId }) => ({
        url: `/profile/${accountId}`,
        method: 'GET',
      }),
    }),
    createProfileData: builder.mutation<AccountVerificationResponsePayload, SaveProfileQueryPayload>({
      query: ({ cardNumber, profileData, consents, recaptchaToken }) => ({
        url: `/profile/${cardNumber}/create`,
        method: 'POST',
        params: {
          [QueryParamKey.recaptchaToken]: recaptchaToken,
          [QueryParamKey.birthdate]: profileData.birthDate,
        },
        body: {
          profileData,
          consents,
        },
      }),
    }),
    updateProfileData: builder.mutation<PersonalData, UpdateProfileQueryPayload>({
      query: ({ cardNumber, profileData, consents }) => ({
        url: `/profile/${cardNumber}/update`,
        method: 'PATCH',
        body: {
          profileData,
          consents,
        },
      }),
    }),
    updateContactAndSendCode: builder.mutation<
      UpdateContactAndSendCodeResponsePayload,
      UpdateContactAndSendCodeQueryPayload
    >({
      query: ({ email, phoneNumber, cardNumber, accountId }) => ({
        url: '/contact/update/verify',
        method: 'POST',
        body: {
          email,
          phoneNumber,
          cardNumber,
          accountId,
        },
      }),
    }),
    updateContactAndVerifyCode: builder.mutation<
      UpdateContactAndVerifyCodeResponsePayload,
      UpdateContactAndVerifyCodeQueryPayload
    >({
      query: ({ code, verificationToken }) => ({
        url: `/contact/update/confirm`,
        params: {
          [QueryParamKey.code]: code,
        },
        method: 'POST',
        body: {
          verificationToken,
        },
      }),
    }),
  }),
});

export const {
  useGetUserDataMutation,
  useCreateProfileDataMutation,
  useUpdateProfileDataMutation,
  useUpdateContactAndSendCodeMutation,
  useUpdateContactAndVerifyCodeMutation,
} = profileApi;

export default profileApi.reducer;
