import { Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { Payments } from '../../../store/api/purchaseHistoryApi';
import useFormatCurrency from '../../../utility/hooks/useFormatCurrency';

interface PaymentLinesProps {
  data: Payments;
}

const PaymentLines = ({ data }: PaymentLinesProps) => {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();
  const { SumCash, SumNonCash, CashChange, payment } = data;
  const totalSum = Number(SumCash) + Number(SumNonCash);

  return (
    <View
      style={{
        marginTop: 10,
      }}
    >
      <View style={{ flexDirection: 'row' }}>
        <Text style={{ width: '80%', textAlign: 'right' }}>{t('purchaseHistory.payment.paid')}</Text>
        <Text style={{ width: '20%', textAlign: 'right', fontWeight: 'bold' }}>{formatCurrency(totalSum)}</Text>
      </View>
      {payment?.map((item, i) => {
        const maskedCardNumber = item.bcnum || item.info?.CardNo || '';
        return (
          <View style={{ flexDirection: 'row' }} key={i}>
            <Text style={{ width: '80%', textAlign: 'right' }}>{`${t('purchaseHistory.payment.method')} ${
              item.type
            } ${maskedCardNumber}`}</Text>
            <Text style={{ width: '20%', textAlign: 'right', fontWeight: 'bold' }}>{formatCurrency(item.amount)}</Text>
          </View>
        );
      })}
      {Number(CashChange) > 0 ? (
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ width: '80%', textAlign: 'right' }}>{t('purchaseHistory.payment.returned')}</Text>
          <Text style={{ width: '20%', textAlign: 'right', fontWeight: 'bold' }}>{formatCurrency(CashChange)}</Text>
        </View>
      ) : null}
    </View>
  );
};

export default PaymentLines;
