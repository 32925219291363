import { useCallback, useEffect } from 'react';
import { Box, Title } from '@mantine/core';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import ConfirmCodeForm from './ConfirmCodeForm';
import { setStage, setVerificationToken } from '../../../store/forgotPasswordSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import SubHeader from '../../../components/SubHeader/SubHeader';
import { ApiError, ErrorCode } from '../../../models/ApiError';
import RecaptchaAction from '../../../models/RecaptchaAction';
import { useForgotPasswordConfirmCodeMutation } from '../../../store/api/forgotPasswordApi';

const ConfirmCode = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const verificationToken = useAppSelector((state) => state.forgotPassword.verificationToken);

  const [confirmCode, queryState] = useForgotPasswordConfirmCodeMutation();

  const getVerifyCodeErrorMessage = useCallback(
    (error: ApiError) => {
      const errorCode = error.data?.message;
      if (error.status === 403) {
        return t('api.sessionExpiredError');
      }
      if (errorCode === ErrorCode.ConfirmationCodeVerificationFailed) {
        return t('createAccount.verifyCode.codeVerificationFailedError');
      }
      if (errorCode === ErrorCode.RecaptchaError) {
        return t('api.recaptchaError');
      }
      return t('api.unknownError');
    },
    [t],
  );

  useEffect(() => {
    if (queryState.isSuccess) {
      dispatch(setVerificationToken(queryState.data.verificationToken));
      dispatch(setStage('changePassword'));
      toast(t('forgotPassword.confirmCode.passwordConfirmCodeSuccess'), { type: 'success' });
    }
  }, [queryState.isSuccess, queryState.data, dispatch, t]);

  useEffect(() => {
    if (queryState.isError && queryState.error) {
      const error = queryState.error as ApiError;
      const errorMessage = getVerifyCodeErrorMessage(error);
      toast(errorMessage, { type: 'error' });
    }
  }, [queryState.isError, queryState.error, getVerifyCodeErrorMessage]);

  const handleNavigateToSendCodeStage = () => {
    dispatch(setStage('sendCode'));
  };

  const handleCodeSubmit = useCallback(
    async (code: string) => {
      if (executeRecaptcha) {
        const recaptchaToken = await executeRecaptcha(RecaptchaAction.ForgotPasswordConfirmCode);
        confirmCode({ code, verificationToken, recaptchaToken });
      }
    },
    [executeRecaptcha, confirmCode, verificationToken],
  );

  return (
    <Box className="container">
      <SubHeader title={t('forgotPassword.confirmCode.subHeaderTitle')} />
      <Box
        className="content"
        sx={(theme) => ({
          paddingTop: theme.other.spacing(10),
          paddingBottom: theme.other.spacing(6),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: '55vh',
          maxHeight: '600px',
          [theme.other.smallerThanHeight(900)]: {
            paddingTop: theme.other.spacing(6),
          },
          [theme.other.largerThanHeight(1100)]: {
            paddingTop: theme.other.spacing(15),
          },
        })}
      >
        <Box
          sx={(theme) => ({
            maxWidth: '800px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: theme.other.spacing(4),
          })}
        >
          <Title
            order={2}
            sx={(theme) => ({
              fontSize: '1.2rem',
              fontFamily: 'ProximaNova',
              letterSpacing: '0.02em',
              textAlign: 'center',
              color: theme.colors.brandGray,
              [theme.fn.smallerThan(960)]: {
                fontSize: '1rem',
              },
            })}
          >
            {t('forgotPassword.confirmCode.formTitle')}
          </Title>
          <ConfirmCodeForm
            onSubmitCode={handleCodeSubmit}
            onResendCode={handleNavigateToSendCodeStage}
            loading={queryState.isLoading}
            error={queryState.isError ? getVerifyCodeErrorMessage(queryState.error as ApiError) : ''}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ConfirmCode;
