enum CardState {
  Activated = 0,
  Sold = 3,
  TemporaryBlocked = 5,
  Blocked = 7,
  NotActivated = 45,
  Changed = 254,
  Active = 255,
}

export default CardState;
