import {
  Box,
  LoadingOverlay as MantineLoadingOverlay,
  LoadingOverlayProps as MantineLoadingOverlayProps,
} from '@mantine/core';

const LoadingOverlay = (props: MantineLoadingOverlayProps) => {
  const { visible } = props;

  if (!visible) {
    return null;
  }

  return (
    <Box
      sx={{
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'absolute',
        '& + div': {
          height: visible ? '100vh' : undefined,
          overflow: visible ? 'hidden' : undefined,
        },
      }}
    >
      <MantineLoadingOverlay {...props} />
    </Box>
  );
};

export default LoadingOverlay;
