import { Box, Text } from '@mantine/core';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import BlockCardModal from '../modals/BlockCardModal';
import CardState from '../../../models/CardState';

interface BlockOrActivateCardProps {
  cardState: CardState;
}

const BlockOrActivateCard = (props: BlockOrActivateCardProps) => {
  const { t } = useTranslation();

  return (
    <BlockCardModal cardState={props.cardState}>
      {({ onOpen }) => (
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            gap: theme.other.spacing(2),
            '& .mantine-Button-root': {
              backgroundColor: theme.colors.brandOrangeLight,
              '&:hover': {
                backgroundColor: theme.colors.brandOrange,
              },
            },
            cursor: 'pointer',
          })}
          onClick={onOpen}
        >
          <Button>{props.cardState === CardState.TemporaryBlocked ? <LockOpenIcon /> : <LockOutlinedIcon />}</Button>
          <Text>
            {props.cardState === CardState.TemporaryBlocked
              ? t('cardSettings.activateCard')
              : t('cardSettings.blockCard')}
          </Text>
        </Box>
      )}
    </BlockCardModal>
  );
};

export default BlockOrActivateCard;
