import { Box, Text } from '@mantine/core';
import { useCallback, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import SubHeader from '../../components/SubHeader/SubHeader';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import useAppLogin from '../../hooks/useAppLogin';
import { ApiError, ErrorCode } from '../../models/ApiError';
import RecaptchaAction from '../../models/RecaptchaAction';
import { useCreateUserAccountMutation } from '../../store/api/authApi';
import { setCardAccountStage } from '../../store/cardSlice';
import EnterPasswordForm from '../ForgotPassword/EnterPassword/EnterPasswordForm';

const CreatePassword = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [login] = useAppLogin();

  const verificationToken = useAppSelector((state) => state.card.verificationToken);

  const [createAccount, createAccountQueryState] = useCreateUserAccountMutation();

  const getCreateLoginErrorMessage = useCallback(
    (error: ApiError) => {
      const errorCode = error.data?.message;
      if (error.status === 403) {
        return t('api.sessionExpiredError');
      }
      if (errorCode === ErrorCode.CreatingCredentialsFailed) {
        return t('createAccount.passwordCreation.creatingLoginFailedError');
      }
      if (errorCode === ErrorCode.LoginFailed) {
        return t('createAccount.passwordCreation.loginFailedError');
      }
      if (errorCode === ErrorCode.RecaptchaError) {
        return t('api.recaptchaError');
      }
      return t('api.unknownError');
    },
    [t],
  );

  useEffect(() => {
    if (createAccountQueryState.isSuccess) {
      login(createAccountQueryState.data);
      toast(t('createAccount.passwordCreation.creatingAccountSuccess'), { type: 'success' });

      // Google Tag Manager event
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'sign_up',
      });
    }
  }, [createAccountQueryState.isSuccess, createAccountQueryState.data, login, t]);

  useEffect(() => {
    const isError = createAccountQueryState.isError;
    const error = createAccountQueryState.error as ApiError;
    if (isError && error) {
      const errorMessage = getCreateLoginErrorMessage(error);
      toast(errorMessage, { type: 'error' });
      dispatch(setCardAccountStage(''));
    }
  }, [createAccountQueryState.isError, createAccountQueryState.error, dispatch, getCreateLoginErrorMessage]);

  const handleSubmit = useCallback(
    async (password: string) => {
      if (executeRecaptcha) {
        const recaptchaToken = await executeRecaptcha(RecaptchaAction.AccountCreationPassword);
        createAccount({ password, verificationToken, recaptchaToken });
      }
    },
    [executeRecaptcha, createAccount, verificationToken],
  );

  return (
    <Box className="container">
      <SubHeader title={t('createAccount.subHeaderTitle')} />
      <Box
        className="content"
        sx={(theme) => ({
          paddingTop: theme.other.spacing(10),
          paddingBottom: theme.other.spacing(12),
          flexDirection: 'column',
          alignItems: 'center',
          gap: theme.other.spacing(3),
        })}
      >
        <Text
          sx={(theme) => ({
            fontSize: '1.25rem',
            textAlign: 'center',
            color: theme.colors.brandGray,
          })}
        >
          {t('createAccount.passwordCreation.passwordCreation')}
        </Text>
        <EnterPasswordForm
          onSubmit={handleSubmit}
          submitButtonLabel={t('createAccount.passwordCreation.createAccount')}
          loading={createAccountQueryState.isLoading}
        />
        <Box>
          <Text
            sx={(theme) => ({
              color: theme.colors.brandGray,
            })}
          >
            {t('createAccount.passwordCreation.createAccountCloseWarning')}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default CreatePassword;
