enum RecaptchaAction {
  CheckCard = 'CheckCard',
  Login = 'Login',
  ForgotPasswordSendCode = 'ForgotPasswordSendCode',
  ForgotPasswordConfirmCode = 'ForgotPasswordConfirmCode',
  ForgotPasswordChangePassword = 'ForgotPasswordChangePassword',
  AccountCreationSendCode = 'AccountCreationSendCode',
  AccountCreationVerifyEmailAndSendCode = 'AccountCreationVerifyEmailAndSendCode',
  AccountCreationConfirmCode = 'AccountCreationConfirmCode',
  AccountCreationPassword = 'AccountCreationPassword',
  ProfileDataCreate = 'ProfileDataCreate',
  LocationSearch = 'LocationSearch',
  StartStrongAuth = 'StartStrongAuth',
  StrongAuthStatus = 'StrongAuthStatus',
  StartIdCard = 'StartIdCard',
  IdCardStatus = 'IdCardStatus',
  StrongAuthRegisterSaveEmail = 'StrongAuthRegisterSaveEmail',
  StrongAuthRegisterConfirmEmail = 'StrongAuthRegisterConfirmEmail',
  StrongAuthRegisterSendConfirmationCode = 'StrongAuthRegisterSendConfirmationCode',
  StrongAuthRegisterLinkCard = 'StrongAuthRegisterLinkCard',
  StrongAuthRegisterSavePhone = 'StrongAuthRegisterSavePhone',
  StrongAuthRegisterConfirmPhone = 'StrongAuthRegisterConfirmPhone',
  StrongAuthRegisterCreateAccount = 'StrongAuthRegisterCreateAccount',
  ProfileLandingSendConfirmationCode = 'ProfileLandingSendConfirmationCode',
  ProfileLandingSaveContact = 'ProfileLandingConfirmContact',
  ProfileLandingConfirmContact = 'ProfileLandingConfirmContact',
  ProfileLandingSaveLanguage = 'ProfileLandingSaveLanguage',
  ProfileLandingSaveProfile = 'ProfileLandingSaveProfile',
  ProfileLandingStart = 'ProfileLandingStart',
  ProfileLandingSaveStrongAuth = 'ProfileLandingSaveStrongAuth',
  ProfileLandingSavePassword = 'ProfileLandingSavePassword',
  ProfileLandingComplete = 'ProfileLandingSaveComplete',
}

export default RecaptchaAction;
