import { Box } from '@mantine/core';
import { useCallback } from 'react';
import PromptModalText from '../../Profile/PromptContactModal/Text/PromptModalText';
import PromptModalForm from '../../Profile/PromptContactModal/Form/PromptModalForm';
import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { PromptContact, setContactVerificationStage } from '../../../store/userSlice';
import { setVerificationToken } from '../../../store/forgotPasswordSlice';

const VerificationPartial = (props: {
  promptContact: PromptContact;
  setPromptContact: (data: PromptContact | null) => void;
}) => {
  const personalData = useAppSelector((state) => state.user.personalData);
  const profileData = personalData?.profileData;
  const dispatch = useAppDispatch();

  const contactVerificationStage = useAppSelector((state) => state.user.contactVerificationStage);
  const { promptContact, setPromptContact } = props;

  const handleCloseModal = useCallback(() => {
    setPromptContact(null);
    dispatch(setVerificationToken(''));
    dispatch(setContactVerificationStage('sendCode'));
  }, [dispatch, setPromptContact]);

  return (
    <>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '270px',
          gap: theme.other.spacing(1),
        })}
      >
        {promptContact && profileData && (
          <PromptModalText
            promptContact={promptContact}
            contactVerificationStage={contactVerificationStage}
            profileData={profileData}
          />
        )}
        {promptContact && profileData && (
          <PromptModalForm
            promptContact={promptContact}
            contactVerificationStage={contactVerificationStage}
            profileData={profileData}
            onClose={handleCloseModal}
          />
        )}
      </Box>
    </>
  );
};

export default VerificationPartial;
