import { Select, SelectProps } from '@mantine/core';

const SelectInput = (props: SelectProps) => {
  return (
    <Select
      {...props}
      sx={(theme) => ({
        borderRadius: '5px',
        '& .mantine-Select-label': {
          color: theme.colors.maximaBlueLight,
          letterSpacing: '0.02em',
          fontSize: '0.75rem',
        },
        '& .mantine-Select-input': {
          transition: 'all 0.1s',
          borderRadius: '5px',
          color: theme.colors.brandGray,
          fontWeight: 400,
          fontSize: '0.9rem',
          '&:not(.mantine-Select-disabled, .mantine-Select-invalid)': {
            borderColor: theme.colors.maximaBlueLight,
          },
          '&.mantine-Select-invalid': {
            borderColor: theme.colors.errorLight,
          },
          '&::placeholder': {
            color: theme.colors.maximaBlueLight,
          },
          '&:focus:not(:read-only)': {
            borderColor: theme.colors.orange,
          },
          '&:read-only': {
            cursor: 'default',
          },
        },
        '& .mantine-Select-icon': {
          paddingTop: '1px',
          '&.mantine-Select-disabled': {
            '&, & *': {
              color: theme.colors.disabledText,
            },
          },
        },
        '& .mantine-Select-error': {
          color: theme.colors.errorLight,
        },
        '& .mantine-Select-rightSection': {
          '& > svg': {
            display: 'none',
          },
          '& .mantine-ActionIcon-root': {
            '&:not(:disabled):active': {
              transform: 'translateY(0)',
            },
          },
        },
      })}
    />
  );
};

export default SelectInput;
