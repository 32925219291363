import { Text, TextInputProps as MantineTextInputProps } from '@mantine/core';
import TextInput from '../TextInput';

const PhoneNumberInput = (props: MantineTextInputProps) => {
  return (
    <TextInput
      autoComplete="username"
      iconWidth={46}
      icon={
        <Text
          sx={(theme) => ({
            paddingLeft: theme.other.spacing(1),
            fontSize: '0.9rem',
            color: props.disabled ? theme.colors.disabledText : theme.colors.maximaNavyBlueDefault,
          })}
        >
          +372
        </Text>
      }
      {...props}
      onChange={function (e) {
        e.target.value = `+372${e.target.value.replace(/(?:\+?372)*/y, '')}`;
        if (props.onChange) props.onChange(e);
      }}
      // eslint-disable-next-line prettier/prettier
      value={props.value?.toString().replace(/(?:\+?372)*/y, '')}
    />
  );
};

export default PhoneNumberInput;
