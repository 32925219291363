import { Title, Text, Box } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { ChangeEvent, useCallback, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Button from '../../../components/Button';
import PhoneNumberInput from '../../../components/form/PhoneNumberInput';
import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { ApiError, ErrorCode } from '../../../models/ApiError';
import RecaptchaAction from '../../../models/RecaptchaAction';
import { useProfileLandingSaveContactMutation } from '../../../store/api/profileLandingApi';
import { checkIfHasEmail, checkIfHasPhone, isValidEstonianMobilePhoneOrEmail } from '../../../utility';
import { setEmail, setPhone, setTempToken } from '../../../store/profileLandingSlice';
import TextInput from '../../../components/form/TextInput';

interface ContactEntryFormValues {
  phone: string | undefined;
  email: string | undefined;
  contactType: 'email' | 'phone' | null | undefined;
}

const ContactEntry = (props: { completeEntry: () => void }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [saveContact, saveContactQueryState] = useProfileLandingSaveContactMutation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const tempToken = useAppSelector((state) => state.profileLanding.tempToken);
  const contactType = useAppSelector((state) => state.profileLanding.contactTypeToConfirm);

  const form = useForm<ContactEntryFormValues>({
    schema: yupResolver(
      Yup.object().shape({
        phone: Yup.string().when('contactType', {
          is: 'phone',
          then: Yup.string()
            .ensure()
            .required()
            .test('empty-field', t('profileLanding.contactEntryStep.phoneMissing'), checkIfHasPhone)
            .test('phone-valid', t('profileLanding.contactEntryStep.phoneInvalid'), isValidEstonianMobilePhoneOrEmail),
        }),
        email: Yup.string().when('contactType', {
          is: 'email',
          then: Yup.string()
            .ensure()
            .required()
            .test('empty-field', t('profileLanding.contactEntryStep.emailMissing'), checkIfHasEmail)
            .test('email-valid', t('profileLanding.contactEntryStep.emailInvalid'), isValidEstonianMobilePhoneOrEmail),
        }),
      }),
    ),
    initialValues: {
      phone: '',
      email: '',
      contactType: contactType,
    },
  });

  const getErrorMessage = useCallback(
    (error: ApiError) => {
      if (error) {
        const errorCode = error.data?.message;
        if (error.status === 403) {
          return t('api.sessionExpiredError');
        }
        if (errorCode === ErrorCode.SendingCodeFailed) {
          return t('createAccount.sendCode.sendingCodeFailed');
        }
        if (errorCode === ErrorCode.EmailOrPhoneCannotBeUsed) {
          return t('profileLanding.contactEntryStep.emailOrPhoneCannotBeUsedError');
        }
        if (errorCode === ErrorCode.RecaptchaError) {
          return t('api.recaptchaError');
        }
      }

      return t('api.unknownError');
    },
    [t],
  );

  useEffect(() => {
    if (saveContactQueryState.isError && saveContactQueryState.error) {
      const errorMessage = getErrorMessage(saveContactQueryState.error as ApiError);
      toast(errorMessage, { type: 'error' });
      saveContactQueryState.reset();
    }
  }, [saveContactQueryState.isError, saveContactQueryState.error, getErrorMessage, t, saveContactQueryState]);

  useEffect(() => {
    if (saveContactQueryState.isSuccess) {
      dispatch(setTempToken(saveContactQueryState.data?.tempToken));
      if (contactType == 'email') dispatch(setEmail(form.values.email));
      if (contactType == 'phone') dispatch(setPhone(form.values.phone));
      saveContactQueryState.reset();
      props.completeEntry();
    }
  }, [
    saveContactQueryState.isSuccess,
    dispatch,
    form.values.email,
    form.values.phone,
    saveContactQueryState.data?.tempToken,
    saveContactQueryState,
    contactType,
    props,
  ]);

  const handleFormSubmit = useCallback(
    async (values: ContactEntryFormValues) => {
      if (form.validate().hasErrors) return;

      if (contactType) {
        const recaptchaToken = await executeRecaptcha?.(RecaptchaAction.ProfileLandingSaveContact);
        saveContact({
          contact: (contactType == 'email' ? values.email : values.phone) ?? '',
          contactType: contactType,
          tempToken: tempToken || '',
          recaptchaToken: recaptchaToken,
        });
      }
    },
    [form, executeRecaptcha, saveContact, tempToken, contactType],
  );

  const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    form.setFieldValue('phone', value);
    if (form.errors.email) {
      form.clearErrors();
    }
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    form.setFieldValue('email', value);
    if (form.errors.email) {
      form.clearErrors();
    }
  };

  return (
    <Box
      sx={(theme) => ({
        width: '60% !important',
        [theme.fn.smallerThan(960)]: {
          width: '100% !important',
        },
      })}
    >
      <Title
        sx={(theme) => ({
          color: theme.colors.brandGray,
          fontFamily: 'ProximaNovaBold',
          fontSize: '1.25rem',
          fontWeight: 700,
          letterSpacing: '0.01em',
          [theme.fn.smallerThan(800)]: {
            fontSize: '2.2rem',
          },
          [theme.fn.smallerThan(600)]: {
            fontSize: '1.6rem',
          },
        })}
      >
        {contactType == 'email'
          ? t('profileLanding.contactEntryStep.titleEmail')
          : t('profileLanding.contactEntryStep.titlePhone')}
      </Title>
      <Text
        sx={(theme) => ({
          '&': {
            marginTop: theme.other.spacing(2),
            color: '#828282',
          },
        })}
      >
        <Text>
          {contactType == 'email'
            ? t('profileLanding.contactEntryStep.descriptionEmail')
            : t('profileLanding.contactEntryStep.descriptionPhone')}
        </Text>
      </Text>
      <form onSubmit={form.onSubmit(handleFormSubmit)}>
        {contactType == 'email' ? (
          <TextInput
            name="email"
            type="email"
            label={t('profileLanding.contactEntryStep.email')}
            required
            onChange={handleEmailChange}
            error={form.errors.email}
            value={form.values.email}
          />
        ) : (
          <PhoneNumberInput
            name="phone"
            type="tel"
            label={t('profileLanding.contactEntryStep.phone')}
            onChange={handlePhoneChange}
            error={form.errors.phone}
            value={form.values.phone || undefined}
            required
            // disabled={props.loading || props.disabled}
          />
        )}

        <Button type="submit">{t('profileLanding.continue')}</Button>
      </form>
    </Box>
  );
};

export default ContactEntry;
