import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCardNumberFromMaximaAuthToken } from '../utility';
import CardStateEnum from '../models/CardState';

export type CardAccountStage =
  | ''
  | 'saveProfileData'
  | 'createAccount'
  | 'verifyEmail'
  | 'verifyCode'
  | 'addPassword'
  | 'firstProfileVisit';

export interface CardState {
  cardNumber: string;
  cardState: CardStateEnum | null;
  cardAccountStage: CardAccountStage;
  unverifiedEmail: string;
  obscuredEmail: string;
  verificationToken: string;
}

const initialState: CardState = {
  cardNumber: getCardNumberFromMaximaAuthToken(),
  cardState: null,
  cardAccountStage: '',
  unverifiedEmail: '',
  obscuredEmail: '',
  verificationToken: '',
};

export const cardSlice = createSlice({
  name: 'card',
  initialState,
  reducers: {
    setCardNumber: (state: CardState, action: PayloadAction<string>) => {
      state.cardNumber = action.payload;
    },
    setCardState: (state: CardState, action: PayloadAction<CardStateEnum>) => {
      state.cardState = action.payload;
    },
    setCardAccountStage: (state: CardState, action: PayloadAction<CardAccountStage>) => {
      state.cardAccountStage = action.payload;
    },
    setUnverifiedEmail: (state: CardState, action: PayloadAction<string>) => {
      state.unverifiedEmail = action.payload;
    },
    setObscuredEmail: (state: CardState, action: PayloadAction<string>) => {
      state.obscuredEmail = action.payload;
    },
    setVerificationToken: (state: CardState, action: PayloadAction<string>) => {
      state.verificationToken = action.payload;
    },
  },
});

export const {
  setCardNumber,
  setCardAccountStage,
  setUnverifiedEmail,
  setObscuredEmail,
  setVerificationToken,
  setCardState,
} = cardSlice.actions;
export default cardSlice.reducer;
