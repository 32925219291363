import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mantine/core';
import PromptModalText from './Text/PromptModalText';
import PromptModalForm from './Form/PromptModalForm';
import Modal from '../../../components/Modal';
import ProfileData from '../../../models/ProfileData';
import { setContactVerificationStage, setPromptContact } from '../../../store/userSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { convertToCamelCase } from '../../../utility';
import { setVerificationToken } from '../../../store/cardSlice';

export type PromptContactActionAndType = 'addEmail' | 'verifyEmail' | 'addPhoneNumber' | 'verifyPhoneNumber';

interface PromptContactModalProps {
  profileData?: ProfileData | null;
}

const PromptContactModal = (props: PromptContactModalProps) => {
  const { profileData } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const contactVerificationStage = useAppSelector((state) => state.user.contactVerificationStage);
  const promptContact = useAppSelector((state) => state.user.promptContact);

  const [opened, setOpened] = useState(Boolean(promptContact));

  const handleCloseModal = useCallback(() => {
    dispatch(setPromptContact(null));
    dispatch(setVerificationToken(''));
    dispatch(setContactVerificationStage('sendCode'));
  }, [dispatch]);

  useEffect(() => {
    setOpened(Boolean(promptContact));
  }, [promptContact]);

  const getTranslationKey = () => {
    return convertToCamelCase([promptContact?.action || '', promptContact?.type || '']) as PromptContactActionAndType;
  };

  return (
    <Modal
      opened={opened}
      onClose={handleCloseModal}
      withCloseButton={false}
      closeOnClickOutside={false}
      closeOnEscape={false}
      title={promptContact ? t(`myProfile.prompt.${getTranslationKey()}.title`) : ''}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.other.spacing(1),
        })}
      >
        {promptContact && profileData && (
          <PromptModalText
            promptContact={promptContact}
            contactVerificationStage={contactVerificationStage}
            profileData={profileData}
          />
        )}
        {promptContact && profileData && (
          <PromptModalForm
            promptContact={promptContact}
            contactVerificationStage={contactVerificationStage}
            profileData={profileData}
            onClose={handleCloseModal}
          />
        )}
      </Box>
    </Modal>
  );
};

export default PromptContactModal;
