import { Box, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { PromptContactActionAndType } from '..';
import ProfileData from '../../../../models/ProfileData';
import { AccountContactVerificationStage, PromptContact } from '../../../../store/userSlice';
import { convertToCamelCase } from '../../../../utility';

interface PromptModalTextProps {
  contactVerificationStage: AccountContactVerificationStage;
  promptContact: PromptContact;
  profileData: ProfileData;
}

const PromptModalText = (props: PromptModalTextProps) => {
  const { contactVerificationStage, promptContact, profileData } = props;
  const { t } = useTranslation();

  const getTranslationKey = () => {
    return convertToCamelCase([promptContact.action, promptContact.type]) as PromptContactActionAndType;
  };

  if (contactVerificationStage === 'sendCode') {
    return (
      <Box>
        <Text>
          {t(`myProfile.prompt.${getTranslationKey()}.description`, {
            email: profileData?.email || '',
            phoneNumber: profileData?.phoneNumber || '',
          })}
        </Text>
        <Text
          sx={(theme) => ({
            marginTop: theme.other.spacing(1),
            fontFamily: 'ProximaNovaSemibold',
          })}
        >
          {t(`myProfile.prompt.${getTranslationKey()}.question`)}
        </Text>
      </Box>
    );
  }

  if (contactVerificationStage === 'verifyCode') {
    return (
      <Box>
        <Text>{t(`myProfile.prompt.confirmCode.${promptContact.type}`)}</Text>
      </Box>
    );
  }

  return null;
};

export default PromptModalText;
