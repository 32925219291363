import { useTranslation } from 'react-i18next';

function useGetLocale() {
  const { i18n } = useTranslation();
  const localeMap = new Map([
    ['et', 'et-EE'],
    ['en', 'en-GB'],
    ['ru', 'ru-RU'],
  ]);

  return localeMap.get(i18n.language);
}

export default useGetLocale;
