import { UseFormReturnType } from '@mantine/form/lib/use-form';
import { Grid } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { ProfileDataFormValues } from '../../../../utility/saveProfileUtils';
import CheckboxGroup from '../../../../components/form/Checkbox/CheckboxGroup';

const CustomerOfferMethodsAgreementsRow = (props: { form: UseFormReturnType<ProfileDataFormValues> }) => {
  const { t } = useTranslation();
  const handleOfferMethodsChange = (value: string[]) => props.form.setFieldValue('agreementsOfferMethods', value);
  return (
    <>
      <Grid
        gutter="lg"
        sx={(theme) => ({
          marginTop: theme.other.spacing(2),
          alignItems: 'flex-start',
        })}
      >
        <Grid.Col sm={12}>
          <CheckboxGroup
            orientation="vertical"
            onChange={handleOfferMethodsChange}
            value={props.form.values.agreementsOfferMethods}
            label={t('registerCard.saveProfileData.agreementsOfferMethodsTitle')}
            options={[
              {
                value: 'agreeToGetEmail',
                label: t('registerCard.saveProfileData.agreeToGetEmail'),
              },
            ]}
          />
        </Grid.Col>
      </Grid>
    </>
  );
};

export default CustomerOfferMethodsAgreementsRow;
