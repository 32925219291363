import PersonalData from '../models/PersonalData';
import ProfileData from '../models/ProfileData';
import { getDateOfBirthString, populateCheckboxGroupValue } from '.';

export interface ProfileDataFormValues {
  firstName: string;
  lastName: string;
  dateOfBirth: string | Date;
  email: string;
  phoneNumber: string;
  personalCode: string;
  language?: string;
  agreementsPersonalOffers: string[];
  agreementsOfferMethods: string[];
  agreementsEReceipts: string[];
  agreementGeneral: string[];
}

type OptionalFieldNames = keyof Pick<
  ProfileDataFormValues,
  'firstName' | 'lastName' | 'phoneNumber' | 'personalCode' | 'language'
>;

export const getProfileFormValues = (personalData?: PersonalData | null, blankConsents = true) => {
  return {
    firstName: personalData?.profileData?.firstName || '',
    lastName: personalData?.profileData?.lastName || '',
    dateOfBirth: personalData?.profileData?.birthDate ? new Date(personalData?.profileData.birthDate) : '',
    email: personalData?.profileData?.email || '',
    phoneNumber: personalData?.profileData?.phoneNumber || '',
    personalCode: personalData?.profileData?.personalCode?.toString() || '',
    language: personalData?.profileData?.language?.toString() || '',
    agreementsPersonalOffers: populateCheckboxGroupValue({
      agreeToGetMarketingInformation: blankConsents ? false : personalData?.consents?.agreeToGetMarketingInformation,
      agreeToReceiveBirthdayOffers: blankConsents ? false : personalData?.consents?.agreeToReceiveBirthdayOffers,
    }),
    agreementsOfferMethods: populateCheckboxGroupValue({
      agreeToGetSms: personalData?.consents?.agreeToGetSms,
      agreeToGetEmail: personalData?.consents?.agreeToGetEmail,
    }),
    agreementsEReceipts: populateCheckboxGroupValue({
      agreeToReceiveTheEReciept: personalData?.consents?.agreeToReceiveTheEReciept,
      agreeToReceiveTheERecieptViaEmail: personalData?.consents?.agreeToReceiveTheERecieptViaEmail,
    }),
    agreementGeneral: populateCheckboxGroupValue({
      agreeWithLoyaltyTerms: personalData?.consents?.agreeWithLoyaltyTerms,
    }),
  };
};

export const getProfileDataFromForm = (values: ProfileDataFormValues, options?: { update?: boolean }) => {
  const { email, dateOfBirth } = values;
  const optionalFields: OptionalFieldNames[] = ['firstName', 'lastName', 'phoneNumber', 'language'];

  const profileData: ProfileData = {
    email,
    birthDate: getDateOfBirthString(dateOfBirth),
  };
  optionalFields.forEach((fieldName) => {
    if (options?.update) {
      if (fieldName === 'language') {
        profileData.language = values.language ? +values.language : undefined;
      } else {
        profileData[fieldName] = values[fieldName];
      }
    } else {
      if (values[fieldName] !== '') {
        if (fieldName === 'language') {
          profileData.language = values.language ? +values.language : undefined;
        } else {
          profileData[fieldName] = values[fieldName];
        }
      }
    }
  });

  return profileData;
};

export const getUserConsentsFromForm = (values: ProfileDataFormValues) => {
  const { agreementGeneral, agreementsOfferMethods, agreementsPersonalOffers, agreementsEReceipts } = values;
  const agreements = [
    ...agreementGeneral,
    ...agreementsOfferMethods,
    ...agreementsPersonalOffers,
    ...agreementsEReceipts,
  ];

  const optionalConsentsFieldArray = [
    'agreeToGetMarketingInformation',
    'agreeToReceiveBirthdayOffers',
    'agreeToGetSms',
    'agreeToGetEmail',
    'agreeToReceiveTheEReciept',
    'agreeToReceiveTheERecieptViaEmail',
  ];

  const consents: { [key: string]: boolean } = {};

  optionalConsentsFieldArray.forEach((field) => {
    consents[field] = false;
  });

  agreements.forEach((consent) => {
    consents[consent] = true;
  });

  return consents;
};
