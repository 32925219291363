import { MantineThemeOverride } from '@mantine/core';

const maximaTheme: MantineThemeOverride = {
  colorScheme: 'light',
  defaultRadius: 0,
  fontFamily: 'ProximaNova',
  colors: {
    brandRed: ['#ED1921'],
    brandBlue: ['#004A91'],
    brandGray: ['#333'],
    brandWhite: ['#fff'],
    grayLightest: ['#d9d9d9'],
    grayDark: ['#4F4F4F'],
    bgBlueDark: ['#11386C'],
    bgBlueMedium: ['#0E5495'],
    brandOrange: ['#EC7200'],
    brandOrangeLight: ['#EF7D00'],
    orange: ['#F2994A'],
    brandYellow: ['#FFD703'],
    successDefault: ['#27AE60'],
    successDark: ['#1D8348'],
    warningDefault: ['#F2C94C'],
    errorDefault: ['#DF1A22'],
    errorDark: ['#A7131A'],
    errorLight: ['#EF8C91'],
    maximaNavyBlueDefault: ['#0A2240'],
    maximaBlueDefault: ['#004990'],
    maximaBlueLight: ['#80A4C6'],
    disabledText: ['#adb5bd'],
    disabledIcon: ['#ced4da'],
    contentBackground: ['#F3F4F5'],
    midGrey: ['#636E72'],
    darkGrey: ['#363D40'],
  },
  other: {
    spacing: (value) => `${value * 8}px`,
    smallerThanHeight: (value) => `@media (max-height: ${value}px)`,
    largerThanHeight: (value) => `@media (min-height: ${value + 1}px)`,
  },
};

export default maximaTheme;
