import { Menu } from '@mantine/core';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

interface UserMenuItemProps {
  icon: ReactNode;
  title: string;
  route: string;
}

const UserMenuItem = (props: UserMenuItemProps) => {
  const navigate = useNavigate();
  const { icon, title, route } = props;
  return (
    <Menu.Item
      onClick={() => navigate(route)}
      icon={icon}
      sx={(theme) => ({
        '& div': {
          textDecoration: 'none',
          color: theme.colors.grayDark,
          fontSize: '1rem',
        },
        '& .MuiSvgIcon-root': {
          color: theme.colors.grayDark,
        },
      })}
    >
      {title}
    </Menu.Item>
  );
};

export default UserMenuItem;
