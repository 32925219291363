import { Grid, Image } from '@mantine/core';
import { Link as RouterLink } from 'react-router-dom';
import TopNavLink from './TopNavLink';
import useHeaderLinks from '../useHeaderLinks';
import maximaLogo from '../../../assets/svg/logos/maxima_logo.svg';
import Link from '../../Link';

const NavBar = () => {
  const { topNavLinks } = useHeaderLinks();

  return (
    <Grid
      align="center"
      sx={(theme) => ({
        padding: ` ${theme.other.spacing(1)} 8%`,
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: 0,
        [theme.fn.smallerThan(theme.breakpoints.md)]: {
          display: 'none',
        },
      })}
    >
      <Link component={RouterLink} to={'/'}>
        <Image width={160} src={maximaLogo} alt="" />
      </Link>

      <Grid
        sx={{
          display: 'flex',
          fontSize: '1rem',
          fontWeight: 'normal',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        {topNavLinks.map(({ href, title }) => (
          <TopNavLink key={href} title={title} href={href} />
        ))}
      </Grid>
    </Grid>
  );
};

export default NavBar;
