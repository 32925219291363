import { Grid } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form/lib/use-form';
import { useTranslation } from 'react-i18next';
import { ProfileDataFormValues } from '../../../utility/saveProfileUtils';
import DateInput from '../../../components/form/DateInput';
import TextInput from '../../../components/form/TextInput';

interface CustomerDateOfBirthAndPersonalCodeRowProps {
  form: UseFormReturnType<ProfileDataFormValues>;
  disabled?: boolean;
  isUpdate?: boolean;
  onReset: () => void;
}

const CustomerDateOfBirthRow = ({ form, disabled, isUpdate, onReset }: CustomerDateOfBirthAndPersonalCodeRowProps) => {
  const { t } = useTranslation();

  const handleDateOfBirthChange = (value: Date | null) => {
    form.setFieldValue('dateOfBirth', value as string | Date);
    if (form.errors.dateOfBirth) {
      onReset();
    }
  };

  return (
    <Grid
      gutter="lg"
      sx={(theme) => ({
        marginTop: theme.other.spacing(2),
        alignItems: 'flex-start',
      })}
    >
      <Grid.Col sm={6}>
        <DateInput
          {...form.getInputProps('dateOfBirth')}
          label={t('registerCard.saveProfileData.dateOfBirth')}
          placeholder={t('registerCard.saveProfileData.dateOfBirth')}
          onChange={handleDateOfBirthChange}
          clearable={false}
          required
          disabled={disabled || !!form.values.personalCode}
          maxDate={new Date()}
        />
      </Grid.Col>
      {isUpdate && (
        <Grid.Col sm={6}>
          <TextInput
            {...form.getInputProps('personalCode')}
            label={t('registerCard.saveProfileData.personalCode')}
            disabled={true}
          />
        </Grid.Col>
      )}
    </Grid>
  );
};

export default CustomerDateOfBirthRow;
