import { Loader, TextInput as MantineTextInput, TextInputProps } from '@mantine/core';
import { forwardRef, Ref } from 'react';

export interface ExtendedTextInputProps extends TextInputProps {
  isLoading?: boolean;
}

const TextInput = forwardRef((props: ExtendedTextInputProps, ref?: Ref<HTMLInputElement>) => {
  return (
    <MantineTextInput
      {...{ ...props, isLoading: undefined }}
      ref={ref}
      rightSection={props.rightSection ? props.rightSection : props.isLoading ? <Loader size="xs" /> : null}
      sx={(theme) => ({
        borderRadius: '5px',
        '& .mantine-TextInput-label': {
          color: theme.colors.maximaBlueLight,
          letterSpacing: '0.02em',
          fontSize: '0.75rem',
        },
        '& .mantine-TextInput-input': {
          transition: 'all 0.1s',
          borderRadius: '5px',
          color: theme.colors.brandGray,
          fontWeight: 400,
          fontSize: '0.9rem',
          paddingTop: '2px',
          '&:not(.mantine-TextInput-disabled, .mantine-TextInput-invalid)': {
            borderColor: theme.colors.maximaBlueLight,
          },
          '&.mantine-TextInput-invalid': {
            borderColor: theme.colors.errorLight,
          },
          '&::placeholder': {
            color: theme.colors.maximaBlueLight,
          },
          '&:focus:not(:read-only)': {
            borderColor: theme.colors.orange,
          },
          '&:read-only': {
            cursor: 'default',
          },
        },
        '& .mantine-TextInput-icon': {
          paddingTop: '1px',
          '&.mantine-TextInput-disabled': {
            '&, & *': {
              color: theme.colors.disabledText,
            },
          },
        },
        '& .mantine-TextInput-error': {
          color: theme.colors.errorLight,
        },
      })}
    />
  );
});

TextInput.displayName = 'TextInput';

export default TextInput;
