import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@mantine/core';
import { toast } from 'react-toastify';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import { useExitLoyaltyMutation } from '../../../store/api/loyaltyExitApi';
import useAppLogout from '../../../hooks/useAppLogout';
import { useAppSelector } from '../../../hooks/store';

interface LoyaltyExitModalRenderProps {
  opened: boolean;
  onOpen: () => void;
}

interface LoyaltyExitModalProps {
  children: (props: LoyaltyExitModalRenderProps) => ReactNode;
}

const LoyaltyExitModal = (props: LoyaltyExitModalProps) => {
  const [opened, setOpened] = useState(false);

  const { t } = useTranslation();
  const [triggerExitLoyalty, { isLoading, isError, isSuccess }] = useExitLoyaltyMutation();
  const [logout] = useAppLogout();
  const consents = useAppSelector((state) => state.user.personalData?.consents);
  const accountId = useAppSelector((state) => state.user.personalData?.accountId);

  const handleOpenModal = () => {
    setOpened(true);
  };

  const handleCloseModal = () => {
    setOpened(false);
  };

  useEffect(() => {
    if (isError) {
      toast(t('cardSettings.exitFailed'), { type: 'error' });
    }
  }, [isError, t]);

  useEffect(() => {
    if (isSuccess) {
      toast(t('cardSettings.exitSuccess'), { type: 'success' });
      logout();
    }
  }, [isSuccess, logout, t]);

  const handleOptOut = async () => {
    if (accountId) {
      triggerExitLoyalty(accountId);
    }
  };

  return (
    <>
      {props.children({ opened, onOpen: handleOpenModal })}
      <Modal opened={opened} onClose={handleCloseModal} title={t('cardSettings.loyaltyExitModalTitle')}>
        {!consents?.agreeWithLoyaltyTerms ? (
          <Box>
            <Box
              sx={(theme) => ({
                marginBottom: theme.other.spacing(1),
              })}
            >
              <Text>{t('cardSettings.loyaltyExitModal.exitedBody')}</Text>
            </Box>
            <Box
              sx={(theme) => ({
                display: 'flex',
                justifyContent: 'flex-end',
                gap: theme.other.spacing(1),
              })}
            >
              <Button variant="outline" onClick={handleCloseModal}>
                {t('cardSettings.close')}
              </Button>
            </Box>
          </Box>
        ) : (
          <Box>
            <Box
              sx={(theme) => ({
                marginBottom: theme.other.spacing(1),
              })}
            >
              <Text>{t('cardSettings.loyaltyExitModal.body')}</Text>
            </Box>
            <Box
              sx={(theme) => ({
                marginBottom: theme.other.spacing(2),
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                color: theme.colors.brandRed,
              })}
            >
              <Text
                sx={{
                  fontFamily: 'ProximaNovaSemibold',
                }}
              >
                {t('cardSettings.loyaltyExitModal.confirmation')}
              </Text>
            </Box>
            <Box
              sx={(theme) => ({
                display: 'flex',
                justifyContent: 'flex-end',
                gap: theme.other.spacing(1),
                [theme.fn.smallerThan(450)]: {
                  flexDirection: 'column-reverse',
                  gap: theme.other.spacing(2),
                },
              })}
            >
              <Button variant="outline" onClick={handleCloseModal}>
                {t('cardSettings.cancel')}
              </Button>
              <Button onClick={handleOptOut} loading={isLoading}>
                {t('cardSettings.loyaltyExitModal.exit')}
              </Button>
            </Box>
          </Box>
        )}
      </Modal>
    </>
  );
};

export default LoyaltyExitModal;
