import { Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import useFormatDate from '../../../utility/hooks/useFormatDate';

interface ReceiptFooterProps {
  data: { cashier: string; invnr: string; date: string };
}

const ReceiptFooter = ({ data }: ReceiptFooterProps) => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const { cashier = '', date = new Date().toISOString() } = data || {};

  return (
    <View
      style={{
        marginTop: 10,
      }}
    >
      <View style={{ flexDirection: 'row' }}>
        <Text style={{ width: '100%', textAlign: 'right' }}>{`${t(
          'purchaseHistory.receiptFooter.cashier',
        )} ${cashier}`}</Text>
      </View>
      <View style={{ flexDirection: 'row' }}>
        <Text style={{ width: '100%', textAlign: 'right' }}>{`${t('purchaseHistory.receiptFooter.date')} ${formatDate(
          date,
          {
            dateStyle: 'short',
            timeStyle: 'short',
          },
        )}`}</Text>
      </View>
    </View>
  );
};

export default ReceiptFooter;
