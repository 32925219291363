import { Box } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { Payments } from '../../../store/api/purchaseHistoryApi';
import useFormatCurrency from '../../../utility/hooks/useFormatCurrency';

interface PaymentLinesProps {
  data: Payments;
}

const PaymentLines = ({ data }: PaymentLinesProps) => {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();
  const { SumCash, SumNonCash, CashChange, payment } = data;
  const totalSum = Number(SumCash) + Number(SumNonCash);

  return (
    <Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-start',
          padding: `${theme.other.spacing(1.5)} 0`,
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            textAlign: 'right',
            width: '100%',
          }}
        >
          <Box>{t('purchaseHistory.payment.paid')}</Box>
          {payment?.map((item, i) => {
            const maskedCardNumber = item.bcnum || item.info?.CardNo || '';
            return (
              <Box key={i} sx={{ minHeight: '50px' }}>{`${t('purchaseHistory.payment.method')} ${
                item.type
              } ${maskedCardNumber}`}</Box>
            );
          })}
          {Number(CashChange) > 0 ? <Box>{t('purchaseHistory.payment.returned')}</Box> : null}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            fontFamily: 'ProximaNovaBold',
            textAlign: 'right',
            width: '25%',
          }}
        >
          <Box>{formatCurrency(totalSum)}</Box>
          {payment?.map((price, i) => {
            return (
              <Box sx={{ minHeight: '50px' }} key={i}>
                {formatCurrency(price.amount)}
              </Box>
            );
          })}
          {Number(CashChange) > 0 ? <Box>{formatCurrency(CashChange)}</Box> : null}
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentLines;
