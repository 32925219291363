import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router';
import { Box, Menu, SegmentedControl, Image } from '@mantine/core';
import languages from './languages';

const LanguageChanger = () => {
  const [currentLanguage, setCurrentLanguage] = useState('et');
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  const onLanguageChange = (languageCode: string) => {
    i18n.changeLanguage(languageCode);
    const nextPath = location.pathname.replace(`/${currentLanguage}`, `/${languageCode}`);
    navigate(nextPath, { replace: true });
    setCurrentLanguage(languageCode);
  };

  return (
    <Box>
      <Menu
        control={
          <Box
            sx={(theme) => ({
              cursor: 'pointer',
              fontFamily: 'ProximaNovaSemibold',
              '&:hover': {
                color: theme.colors.grayLightest,
              },
            })}
          >
            {languages.find((lang) => lang.value === currentLanguage)?.label}
          </Box>
        }
        sx={(theme) => ({
          margin: '0 1em',
          [theme.fn.smallerThan(theme.breakpoints.md)]: {
            display: 'none',
          },
        })}
      >
        {languages.map((language) => (
          <Menu.Item
            key={language.label}
            icon={<Image width={28} src={language.flag} alt={language.label} />}
            onClick={() => onLanguageChange(language.value)}
          >
            {`${language.label}`}
          </Menu.Item>
        ))}
      </Menu>
      <Box
        sx={(theme) => ({
          margin: '0 1em',
          [theme.fn.largerThan(theme.breakpoints.md)]: {
            display: 'none',
          },
        })}
      >
        <SegmentedControl value={currentLanguage} onChange={onLanguageChange} data={languages} />
      </Box>
    </Box>
  );
};

export default LanguageChanger;
