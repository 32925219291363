import { useTranslation } from 'react-i18next';

const useHeaderLinks = () => {
  const { t } = useTranslation();
  return {
    topBarLinks: [
      {
        href: t('header.topBar.1.url'),
        title: t('header.topBar.1.label'),
      },
      {
        href: t('header.topBar.2.url'),
        title: t('header.topBar.2.label'),
      },
      {
        href: t('header.topBar.3.url'),
        title: t('header.topBar.3.label'),
      },
      {
        href: t('header.topBar.4.url'),
        title: t('header.topBar.4.label'),
      },
    ],
    topNavLinks: [
      {
        href: t('header.topNav.1.url'),
        title: t('header.topNav.1.label'),
      },
      {
        href: t('header.topNav.2.url'),
        title: t('header.topNav.2.label'),
      },
      {
        href: t('header.topNav.3.url'),
        title: t('header.topNav.3.label'),
      },
      {
        href: t('header.topNav.4.url'),
        title: t('header.topNav.4.label'),
      },
      {
        href: t('header.topNav.5.url'),
        title: t('header.topNav.5.label'),
      },
    ],
  };
};

export default useHeaderLinks;
