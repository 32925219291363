import { Burger, Grid, Image, Menu, Divider, Col } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import TopBarLink from './TopBarLink';
import TopNavLink from './TopNavLink';
import useHeaderLinks from '../useHeaderLinks';
import UserMenuFields from '../UserMenu/UserMenuFields';
import maximaLogo from '../../../assets/svg/logos/maxima_logo.svg';
import LanguageChanger from '../../LanguageChanger';
import { useAppSelector } from '../../../hooks/store';
const MobileHeader = () => {
  const [opened, handlers] = useDisclosure(false);
  const { topBarLinks, topNavLinks } = useHeaderLinks();
  const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn);

  return (
    <Grid
      sx={(theme) => ({
        margin: 0,
        padding: '0 7%',
        [theme.fn.largerThan(theme.breakpoints.md)]: {
          display: 'none',
        },
      })}
    >
      <Col span={6} sx={{ display: 'flex', alignItems: 'center' }}>
        <Menu
          opened={opened}
          onOpen={handlers.open}
          size="auto"
          closeOnItemClick
          onClose={handlers.close}
          control={<Burger opened={opened} onClick={handlers.toggle} />}
        >
          {topBarLinks.map(({ href, title }) => (
            <TopBarLink href={href} title={title} key={href} />
          ))}
          <Divider />
          {topNavLinks.map(({ href, title }) => (
            <TopNavLink href={href} title={title} key={href} />
          ))}
          <Divider />
          <LanguageChanger />
          <Divider />
          {isLoggedIn && <UserMenuFields />}
        </Menu>
      </Col>
      <Col span={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Image width={160} src={maximaLogo} alt="" />
      </Col>
    </Grid>
  );
};

export default MobileHeader;
