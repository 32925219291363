import { Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { Tax } from '../../../store/api/purchaseHistoryApi';
import useFormatCurrency from '../../../utility/hooks/useFormatCurrency';

const defaultTaxLines = {
  vat: 20,
  noVat: 0,
  withVat: 0,
  amount: 0,
};

interface TaxLinesProps {
  data: Tax[];
}

const TaxLines = ({ data }: TaxLinesProps) => {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();

  const total = data.reduce(
    (acc, curr) => {
      acc.amount = acc.amount += Number(curr.amount);
      acc.noVat = acc.noVat += Number(curr.noVat);
      acc.withVat = acc.withVat += Number(curr.withVat);
      return acc;
    },
    { amount: 0, noVat: 0, withVat: 0 },
  );

  return (
    <View>
      <View style={{ marginTop: 20, borderBottom: '1 dashed #aeaeae' }}>
        <View
          style={{
            paddingBottom: 5,
            flexDirection: 'row',
          }}
        >
          <Text style={{ width: '25%' }}>{t('purchaseHistory.taxLine.VATPercent')}</Text>
          <Text style={{ width: '25%' }}>{t('purchaseHistory.taxLine.withoutVAT')}</Text>
          <Text style={{ width: '25%', textAlign: 'center' }}>{t('purchaseHistory.taxLine.VAT')}</Text>
          <Text style={{ width: '25%', textAlign: 'right' }}>{t('purchaseHistory.taxLine.withVAT')}</Text>
        </View>
        {data.map((taxLine, i) => {
          const { vat, noVat, withVat, amount } = taxLine || defaultTaxLines;
          return (
            <View style={{ paddingBottom: 5, flexDirection: 'row' }} key={i}>
              <Text style={{ width: '25%' }}>{vat}</Text>
              <Text style={{ width: '25%' }}>{formatCurrency(noVat)}</Text>
              <Text style={{ width: '25%', textAlign: 'center' }}>{formatCurrency(amount)}</Text>
              <Text style={{ width: '25%', textAlign: 'right', fontWeight: 'bold' }}>{formatCurrency(withVat)}</Text>
            </View>
          );
        })}
      </View>

      <View
        style={{
          marginTop: 10,
        }}
      >
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ width: '80%', textAlign: 'right', lineHeight: 1.55 }}>{t('purchaseHistory.taxLine.VAT')}</Text>
          <Text style={{ width: '20%', textAlign: 'right', fontWeight: 'bold' }}>{formatCurrency(total.amount)}</Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ width: '80%', textAlign: 'right', lineHeight: 1.55 }}>
            {t('purchaseHistory.taxLine.withoutVAT')}
          </Text>
          <Text style={{ width: '20%', textAlign: 'right', fontWeight: 'bold' }}>{formatCurrency(total.noVat)}</Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ width: '80%', textAlign: 'right', lineHeight: 1.55 }}>
            {t('purchaseHistory.taxLine.total')}
          </Text>
          <Text style={{ width: '20%', textAlign: 'right', fontWeight: 'bold' }}>{formatCurrency(total.withVat)}</Text>
        </View>
      </View>
    </View>
  );
};

export default TaxLines;
