import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from './store';
import useAppLogout from './useAppLogout';
import { getMaximaAuthTokenFromStorage, getMaximaAuthTokenPayload } from '../utility';
import { setPersonalData } from '../store/userSlice';
import { useGetUserDataMutation } from '../store/api/profileApi';
import { ApiError } from '../models/ApiError';

const useFetchPersonalData = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const fetchInitialized = useRef(false);

  const personalData = useAppSelector((state) => state.user.personalData);
  const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn);

  const [showLoading, setShowLoading] = useState(!personalData && isLoggedIn);

  const [getPersonalData, queryState] = useGetUserDataMutation();
  const [logout] = useAppLogout();

  const handleFetchData = useCallback(() => {
    const token = getMaximaAuthTokenFromStorage();
    const payload = getMaximaAuthTokenPayload(token);
    if (token && payload) {
      getPersonalData({ accountId: payload.accountId });
    } else {
      logout();
    }
  }, [getPersonalData, logout]);

  useEffect(() => {
    if (!personalData && !fetchInitialized.current) {
      fetchInitialized.current = true;
      handleFetchData();
    }
  }, [personalData, handleFetchData]);

  useEffect(() => {
    if (queryState.isSuccess) {
      fetchInitialized.current = false;
      dispatch(setPersonalData(queryState.data));
    }
  }, [queryState.isSuccess, queryState.data, dispatch]);

  useEffect(() => {
    setShowLoading(!personalData && isLoggedIn);
  }, [personalData, isLoggedIn]);

  useEffect(() => {
    if (queryState.isError) {
      fetchInitialized.current = false;
      const error = queryState.error as ApiError;
      const message = error.status === 403 ? t('api.sessionExpiredError') : t('api.unknownError');
      toast(message, { type: 'error', autoClose: false });
      setShowLoading(false);
    }
  }, [queryState.isError, queryState.error, logout, t]);

  return { showLoading, personalData };
};

export default useFetchPersonalData;
