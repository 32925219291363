import { Box } from '@mantine/core';
import Description from './Description';
import FormCard from './FormCard';
import OffersList from './OffersList';

const Login = () => {
  return (
    <Box
      className="gutters"
      sx={(theme) => ({
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 400px',
        gridTemplateAreas: `
        'main-title main-title'
        'list login-form'
        'description login-form'
        `,
        columnGap: theme.other.spacing(15),
        backgroundColor: theme.colors.contentBackground,
        [theme.fn.smallerThan(1080)]: {
          columnGap: theme.other.spacing(10),
          gridTemplateColumns: '1fr 360px',
        },
        [theme.fn.smallerThan(960)]: {
          gridTemplateColumns: '1fr',
          gridTemplateAreas: `
            'login-form'
            'main-title'
            'list'
            'description'
          `,
        },
        '&::before': {
          gridArea: 'main-title-start / main-title-start / list-end / main-title-end',
          marginLeft: '-30%',
          marginRight: '-30%',
          backgroundColor: theme.colors.maximaBlueDefault,
          display: 'block',
          content: '""',
          [theme.fn.smallerThan(960)]: {
            gridArea: 'login-form-start / login-form-start / list-end / login-form-end',
          },
        },
      })}
    >
      <Box
        sx={(theme) => ({
          marginTop: theme.other.spacing(1),
          gridArea: 'main-title',
          color: theme.colors.brandWhite,
        })}
      >
        &nbsp;
      </Box>
      <OffersList />
      <FormCard />
      <Description />
    </Box>
  );
};

export default Login;
