import { Box, Image, Text } from '@mantine/core';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import couponIcon from '../../assets/svg/icons/Coupon.svg';
import { useGetRewardsMutation, useGetRewardsByCardMutation } from '../../store/api/rewardApi';

const Marketing5RewardNotice = (props: { accountId?: number; cardNo?: string }) => {
  const [requestRewards, rewardsQueryState] = useGetRewardsMutation();
  const [requestRewardsCard, cardRewardsQueryState] = useGetRewardsByCardMutation();
  const { t } = useTranslation();

  useEffect(() => {
    if (!!props.accountId) {
      requestRewards(props.accountId);
    }
    if (!!props.cardNo) requestRewardsCard(props.cardNo);
  }, [props, requestRewards, requestRewardsCard]);

  useEffect(() => {
    if (rewardsQueryState.isError || cardRewardsQueryState.isError) {
      toast(t('rewards.fetchingError'), { type: 'error' });
    }
  }, [rewardsQueryState, cardRewardsQueryState, t]);

  if (!props.accountId && !props.cardNo) return null;

  if (!!props.accountId && (rewardsQueryState.isLoading || rewardsQueryState.isError || !rewardsQueryState.isSuccess))
    return null;
  if (
    !!props.cardNo &&
    (cardRewardsQueryState.isLoading || cardRewardsQueryState.isError || !cardRewardsQueryState.isSuccess)
  )
    return null;

  const data = !!props.accountId ? rewardsQueryState.data : !!props.cardNo ? cardRewardsQueryState.data : null;

  const [marketing5Reward] = data?.filter((reward) => reward.rewardID === 'Marketing5') || [];
  if (!marketing5Reward?.isEligible) return null;

  return (
    <Box
      sx={(theme) => ({
        border: '1px solid',
        borderColor: theme.colors.grayLightest,
        borderRadius: '3px',
        backgroundColor: theme.colors.brandWhite,
        padding: theme.spacing.xs,
        display: 'flex',
        flexDirection: 'row',
      })}
    >
      <Image
        src={couponIcon}
        sx={() => ({
          width: '24px',
          height: '24px',
        })}
      ></Image>
      <Text
        sx={(theme) => ({
          '&': {
            marginLeft: theme.spacing.xs,
            fontFamily: 'ProximaNovaBold',
            color: theme.colors.maximaBlueDefault,
          },
        })}
      >
        {t('rewards.marketing5')}
      </Text>
    </Box>
  );
};

export default Marketing5RewardNotice;
