import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Box } from '@mantine/core';
import SendVerificationCode from './SendVerificationCode';
import EnterNewEmailForm from './EnterNewEmailForm';
import EnterNewPhoneNumberForm from './EnterNewPhoneNumberForm';
import ProfileData from '../../../../models/ProfileData';
import {
  useUpdateContactAndSendCodeMutation,
  useUpdateContactAndVerifyCodeMutation,
} from '../../../../store/api/profileApi';
import {
  AccountContactVerificationStage,
  PromptContact,
  setContactVerificationStage,
  setPersonalData,
} from '../../../../store/userSlice';
import ConfirmCodeForm from '../../../ForgotPassword/ConfirmCode/ConfirmCodeForm';
import { useAppDispatch, useAppSelector } from '../../../../hooks/store';
import { setVerificationToken } from '../../../../store/cardSlice';
import { convertToCamelCase, getMaximaAuthTokenPayloadFromStorage } from '../../../../utility';
import { ApiError, ErrorCode } from '../../../../models/ApiError';
import { PromptContactActionAndType } from '..';

interface PromptModalFormProps {
  contactVerificationStage: AccountContactVerificationStage;
  promptContact: PromptContact;
  profileData: ProfileData;
  onClose: () => void;
}

const PromptModalForm = (props: PromptModalFormProps) => {
  const { contactVerificationStage, promptContact, onClose } = props;
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const verificationToken = useAppSelector((state) => state.card.verificationToken);

  const [updateContactAndSendCode, updateContactAndSendCodeQueryState] = useUpdateContactAndSendCodeMutation();
  const [updateContactAndVerifyCode, updateContactAndVerifyCodeQueryState] = useUpdateContactAndVerifyCodeMutation();

  const getTranslationKey = useCallback(() => {
    return convertToCamelCase([promptContact.action, promptContact.type]) as PromptContactActionAndType;
  }, [promptContact.action, promptContact.type]);

  const updateContactAndSendCodeErrorMessage = useCallback(
    (error: ApiError) => {
      const errorCode = error.data?.message;
      if (error.status === 403) {
        return t('api.sessionExpiredError');
      }
      if (errorCode === ErrorCode.SendingCodeFailed) {
        return t('createAccount.sendCode.sendingCodeFailed');
      }
      if (errorCode === ErrorCode.EmailOrPhoneCannotBeUsed) {
        return t('myProfile.profileDataForm.emailOrPhoneCannotBeUsedError');
      }
      return t('api.unknownError');
    },
    [t],
  );

  const updateContactAndVerifyCodeErrorMessage = useCallback(
    (error: ApiError) => {
      const errorCode = error.data?.message;
      if (error.status === 403) {
        return t('api.sessionExpiredError');
      }
      if (errorCode === ErrorCode.ConfirmationCodeVerificationFailed) {
        return t('createAccount.verifyCode.codeVerificationFailedError');
      }
      return t('api.unknownError');
    },
    [t],
  );

  useEffect(() => {
    if (updateContactAndSendCodeQueryState.isSuccess) {
      dispatch(setContactVerificationStage('verifyCode'));
      dispatch(setVerificationToken(updateContactAndSendCodeQueryState.data.verificationToken));
    }
  }, [updateContactAndSendCodeQueryState.isSuccess, updateContactAndSendCodeQueryState.data, dispatch]);

  useEffect(() => {
    if (updateContactAndVerifyCodeQueryState.isSuccess) {
      dispatch(setPersonalData(updateContactAndVerifyCodeQueryState.data.personalData));
      toast(t(`myProfile.prompt.${getTranslationKey()}.success`), {
        type: 'success',
      });
      onClose();
    }
  }, [
    updateContactAndVerifyCodeQueryState.isSuccess,
    updateContactAndVerifyCodeQueryState.data,
    promptContact.type,
    getTranslationKey,
    onClose,
    dispatch,
    t,
  ]);

  useEffect(() => {
    if (updateContactAndSendCodeQueryState.isError) {
      const error = updateContactAndSendCodeQueryState.error as ApiError;
      const errorMessage = updateContactAndSendCodeErrorMessage(error);
      toast(errorMessage, { type: 'error' });
    }
  }, [
    updateContactAndSendCodeQueryState.isError,
    updateContactAndSendCodeQueryState.error,
    updateContactAndSendCodeErrorMessage,
  ]);

  useEffect(() => {
    if (updateContactAndVerifyCodeQueryState.isError) {
      const error = updateContactAndVerifyCodeQueryState.error as ApiError;
      const errorMessage = updateContactAndVerifyCodeErrorMessage(error);
      toast(errorMessage, { type: 'error' });
    }
  }, [
    updateContactAndVerifyCodeQueryState.isError,
    updateContactAndVerifyCodeQueryState.error,
    updateContactAndVerifyCodeErrorMessage,
  ]);

  const handleSendCodeToContact = () => {
    const payload = getMaximaAuthTokenPayloadFromStorage();
    if (payload) {
      const { accountId, cardNumber } = payload;
      if (promptContact?.type === 'email') {
        updateContactAndSendCode({ email: props.profileData?.email, accountId, cardNumber });
      }
      if (promptContact?.type === 'phoneNumber') {
        updateContactAndSendCode({ phoneNumber: props.profileData?.phoneNumber, accountId, cardNumber });
      }
    }
  };

  const handleSendCodeToEmail = (email: string) => {
    const payload = getMaximaAuthTokenPayloadFromStorage();
    if (payload) {
      const { accountId, cardNumber } = payload;
      updateContactAndSendCode({ email, accountId, cardNumber });
    }
  };

  const handleSendCodeToPhoneNumber = (phoneNumber: string) => {
    const payload = getMaximaAuthTokenPayloadFromStorage();
    if (payload) {
      const { accountId, cardNumber } = payload;
      updateContactAndSendCode({ phoneNumber, accountId, cardNumber });
    }
  };

  const handleResendCode = () => {
    dispatch(setContactVerificationStage('sendCode'));
  };

  const handleSubmitVerifyCode = (code: string) => {
    updateContactAndVerifyCode({ code, verificationToken });
  };

  if (contactVerificationStage === 'verifyCode') {
    return (
      <Box
        sx={(theme) => ({
          marginTop: theme.other.spacing(1.5),
        })}
      >
        <ConfirmCodeForm
          inModalMode
          onSubmitCode={handleSubmitVerifyCode}
          onResendCode={handleResendCode}
          onCancel={onClose}
          loading={updateContactAndVerifyCodeQueryState.isLoading}
        />
      </Box>
    );
  }

  if (contactVerificationStage === 'sendCode') {
    if (promptContact.action === 'verify') {
      return (
        <Box>
          <SendVerificationCode
            onSubmit={handleSendCodeToContact}
            onClose={onClose}
            loading={updateContactAndSendCodeQueryState.isLoading}
          />
        </Box>
      );
    }
    if (promptContact.action === 'add' || promptContact.action === 'change') {
      if (promptContact.type === 'email') {
        return (
          <Box>
            <EnterNewEmailForm
              onSubmit={handleSendCodeToEmail}
              onCancel={onClose}
              loading={updateContactAndSendCodeQueryState.isLoading}
            />
          </Box>
        );
      }
      if (promptContact.type === 'phoneNumber') {
        return (
          <Box>
            <EnterNewPhoneNumberForm
              onSubmit={handleSendCodeToPhoneNumber}
              onCancel={onClose}
              loading={updateContactAndSendCodeQueryState.isLoading}
            />
          </Box>
        );
      }
    }
  }

  return null;
};

export default PromptModalForm;
