import { useTranslation } from 'react-i18next';
import Modal from '../../../../components/Modal';

interface ReceiptLoaderModalProps {
  opened: boolean;
  handleCloseModal: () => void;
}

const ReceiptErrorModal = ({ opened, handleCloseModal }: ReceiptLoaderModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      sx={() => ({
        '& .mantine-Modal-header': {
          alignItems: 'flex-start',
        },
      })}
      opened={opened}
      onClose={handleCloseModal}
      title={t('purchaseHistory.receiptNotFound')}
    ></Modal>
  );
};

export default ReceiptErrorModal;
