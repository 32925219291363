import { Box } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form/lib/use-form';
import { useTranslation } from 'react-i18next';
import CheckboxGroup from '../../../components/form/Checkbox/CheckboxGroup';
import CheckboxLabelWithTooltip from '../../../components/form/Checkbox/CheckboxLabelWithTooltip';
import { ProfileDataFormValues } from '../../../utility/saveProfileUtils';
import featureFlags from '../../../features';
import Marketing5RewardNotice from '../../../components/Rewards/Marketing5';
import { useAppSelector } from '../../../hooks/store';

interface PersonalOffersAgreementsGroupProps {
  form: UseFormReturnType<ProfileDataFormValues>;
  disabled?: boolean;
  isUpdate?: boolean;
}

const PersonalOffersAgreementsGroup = ({ form, disabled, isUpdate }: PersonalOffersAgreementsGroupProps) => {
  const { t } = useTranslation();

  const handlePersonalOffersChange = (value: string[]) => form.setFieldValue('agreementsPersonalOffers', value);
  const cardNumber = useAppSelector((state) => state.card.cardNumber);
  const accountId = useAppSelector((state) => state.user.personalData?.accountId);

  return (
    <Box
      sx={(theme) => ({
        marginTop: theme.other.spacing(6),
        alignItems: 'flex-start',
      })}
    >
      <CheckboxGroup
        orientation="vertical"
        value={form.values.agreementsPersonalOffers}
        onChange={handlePersonalOffersChange}
        label={t('registerCard.saveProfileData.agreementsPersonalOffersTitle')}
        options={[
          {
            disabled,
            value: 'agreeToGetMarketingInformation',
            label: (
              <>
                <CheckboxLabelWithTooltip
                  label={t('registerCard.saveProfileData.agreeToGetMarketingInformation')}
                  tooltipLabel={t('registerCard.saveProfileData.agreeToGetMarketingInformationTooltip')}
                />
                <Marketing5RewardNotice accountId={accountId} cardNo={cardNumber} />
              </>
            ),
            sx: (theme) => ({
              marginTop: theme.other.spacing(1),
              marginBottom: theme.other.spacing(1),
              gap: theme.other.spacing(0.5),
              '& .mantine-CheckboxGroup-inner': {
                color: theme.colors.grayDark,
                alignSelf: 'start',
                marginTop: '0.27em',
                '& .mantine-CheckboxGroup-icon': {
                  top: '1px',
                },
              },
              '& .mantine-CheckboxGroup-input': {
                borderRadius: '4px',
                borderWidth: '2px',
                '&:not(:disabled)': {
                  backgroundColor: theme.colors.maximaBlueDefault,
                  borderColor: theme.colors.maximaBlueDefault,
                  cursor: 'pointer',
                },
                '&:not(:checked, :disabled)': {
                  backgroundColor: theme.colors.brandWhite,
                  borderColor: theme.colors.maximaBlueLight,
                },
              },
              '&.mantine-Group-child .mantine-CheckboxGroup-label': {
                marginTop: '2px',
                paddingLeft: theme.other.spacing(1),
                color: theme.colors.brandGray,
                fontSize: '0.9rem',
                fontFamily: 'ProximaNova',
                userSelect: 'none',
                cursor: disabled ? 'default' : 'pointer',
                '&>div:nth-child(2)': {
                  marginTop: theme.other.spacing(1),
                  marginLeft: '-2.2em',
                },
              },
            }),
          },
          ...(isUpdate && featureFlags.birthdayOffersEnabled
            ? [
                {
                  value: 'agreeToReceiveBirthdayOffers',
                  label: (
                    <CheckboxLabelWithTooltip
                      label={t('registerCard.saveProfileData.agreeToReceiveBirthdayOffers')}
                      tooltipLabel={t('registerCard.saveProfileData.agreeToReceiveBirthdayOffersTooltip')}
                    />
                  ),
                  disabled: disabled || !form.values.personalCode,
                },
              ]
            : []),
        ]}
      />
    </Box>
  );
};

export default PersonalOffersAgreementsGroup;
