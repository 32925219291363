import { useCallback } from 'react';
import { useAppDispatch } from './store';
import { setCardNumber } from '../store/cardSlice';
import { setIsLoggedIn, setPersonalData, setToken } from '../store/userSlice';

const useAppLogout = () => {
  const dispatch = useAppDispatch();
  const handleLogout = useCallback(async () => {
    dispatch(setIsLoggedIn(false));
    dispatch(setToken(null));
    dispatch(setPersonalData(null));
    dispatch(setCardNumber(''));
  }, [dispatch]);

  return [handleLogout];
};

export default useAppLogout;
