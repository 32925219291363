import { toast } from 'react-toastify';
import { useCallback, useEffect } from 'react';
import * as Yup from 'yup';
import { Box } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { useTranslation } from 'react-i18next';
import TextInput from '../../../components/form/TextInput';
import Button from '../../../components/Button';
import { useValidatePasswordMutation } from '../../../store/api/authApi';

interface EnterPasswordFormProps {
  submitButtonLabel?: string;
  onSubmit: (newPassword: string) => Promise<void> | void;
  loading?: boolean;
  disabled?: boolean;
}

const EnterPasswordForm = (props: EnterPasswordFormProps) => {
  const { t, i18n } = useTranslation();

  const [validatePassword, validatePasswordQueryState] = useValidatePasswordMutation();

  const form = useForm({
    schema: yupResolver(
      Yup.object().shape({
        newPassword: Yup.string().required(t('forgotPassword.changePassword.passwordRequiredError')),
        repeatPassword: Yup.string()
          .required(t('forgotPassword.changePassword.passwordRequiredError'))
          .oneOf([Yup.ref('newPassword')], t('forgotPassword.changePassword.passwordsDoNotMatchError')),
      }),
    ),
    initialValues: {
      newPassword: '',
      repeatPassword: '',
    },
  });

  // eslint-disable-next-line
  const submit = useCallback(props.onSubmit, []);

  // eslint-disable-next-line
  const setFieldError = useCallback(form.setFieldError, []);

  useEffect(() => {
    if (
      validatePasswordQueryState.isSuccess &&
      form.values.newPassword === validatePasswordQueryState.originalArgs?.password
    ) {
      if (validatePasswordQueryState.data.isValid) {
        submit(form.values.newPassword);
      } else {
        const errorMessage = validatePasswordQueryState.data.messages.find(
          (message) => message.languageCode === i18n.language,
        );
        if (errorMessage) {
          setFieldError('newPassword', errorMessage.message);
        }
      }
    }
  }, [
    validatePasswordQueryState.data,
    validatePasswordQueryState.isSuccess,
    validatePasswordQueryState.originalArgs?.password,
    setFieldError,
    submit,
    form.values.newPassword,
    i18n.language,
  ]);

  useEffect(() => {
    if (validatePasswordQueryState.isError) {
      toast(t('api.unknownError'), { type: 'error' });
    }
  }, [validatePasswordQueryState.isError, t]);

  const handleFormSubmit = (values: typeof form.values) => {
    validatePassword({
      password: values.newPassword,
    });
  };

  return (
    <Box
      sx={(theme) => ({
        maxWidth: '500px',
        width: '100%',
        '&, & form': {
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          [theme.fn.largerThan(500)]: {
            alignItems: 'center',
          },
        },
        '& .mantine-TextInput-root': {
          marginBottom: theme.other.spacing(1),
          width: '100%',
        },
        '& .mantine-Button-root': {
          marginTop: theme.other.spacing(2.5),
          marginBottom: theme.other.spacing(2),
        },
      })}
    >
      <form noValidate onSubmit={form.onSubmit(handleFormSubmit)}>
        <TextInput
          name="newPassword"
          type="password"
          autoComplete="new-password"
          label={t('forgotPassword.changePassword.newPassword')}
          required
          disabled={props.loading || props.disabled}
          {...form.getInputProps('newPassword')}
        />
        <TextInput
          name="repeatPassword"
          type="password"
          autoComplete="new-password"
          label={t('forgotPassword.changePassword.repeatPassword')}
          required
          disabled={props.loading || props.disabled}
          {...form.getInputProps('repeatPassword')}
        />
        <Button type="submit" loading={props.loading} disabled={props.disabled}>
          {props.submitButtonLabel || t('forgotPassword.changePassword.save')}
        </Button>
      </form>
    </Box>
  );
};

export default EnterPasswordForm;
