import { Box } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import RegisterCardSubHeader from './SubHeader';
import SaveProfileDataForm from './SaveProfileDataForm';
import { useAppSelector } from '../../hooks/store';

const RegisterCard = () => {
  const { t } = useTranslation();

  const cardNumber = useAppSelector((state) => state.card.cardNumber);

  return (
    <Box className="container">
      <RegisterCardSubHeader title={t('registerCard.subHeaderTitle')} />
      <Box
        className="content"
        sx={(theme) => ({
          paddingTop: theme.other.spacing(4),
          paddingBottom: theme.other.spacing(6),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: '55vh',
          [theme.other.smallerThanHeight(900)]: {
            paddingTop: theme.other.spacing(6),
          },
          [theme.other.largerThanHeight(1100)]: {
            paddingTop: theme.other.spacing(15),
          },
          [theme.fn.smallerThan(1000)]: {
            paddingTop: theme.other.spacing(6),
          },
        })}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: '960px',
          }}
        >
          <SaveProfileDataForm cardNumber={cardNumber} />
        </Box>
      </Box>
    </Box>
  );
};

export default RegisterCard;
