import { useState } from 'react';
import { Box, Loader } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridCellParams, GridColDef, MuiEvent } from '@mui/x-data-grid';
import useHistoryTableFields from './columnFieldsAndTitles';
import Pagination from './Pagination';
import ReceiptModal from '../Receipt';
import { useAppSelector } from '../../../hooks/store';
import useFormatDate from '../../../utility/hooks/useFormatDate';
import useConvertCentsToEur from '../../../utility/hooks/useConvertCentsToEur';
import {
  GetPurchaseHistoryResponsePayload,
  useGetPurchaseReceiptMutation,
} from '../../../store/api/purchaseHistoryApi';

interface PurchaseHistoryProps {
  purchaseData?: GetPurchaseHistoryResponsePayload;
  handlePageChange: (number: number) => void;
  isLoading: boolean;
  isError?: boolean;
  page: number;
}

const PurchaseHistoryTable = ({ purchaseData, handlePageChange, isLoading, isError, page }: PurchaseHistoryProps) => {
  const [opened, setOpened] = useState(false);
  const { t } = useTranslation();

  const formatDate = useFormatDate();
  const convertCentsToEur = useConvertCentsToEur();
  const [triggerGetReceipt, { data: receiptData, isLoading: receiptLoading }] = useGetPurchaseReceiptMutation();
  const accountId = useAppSelector((state) => state.user.personalData?.accountId);

  const columns: GridColDef[] = useHistoryTableFields().map((field) => {
    return {
      ...field,
      headerClassName: 'maxima__table-header',
      cellClassName: 'maxima__table-cell',
      flex: 1,
      minWidth: field.field === 'caption' ? 350 : 180,
      sortable: false,
    };
  });

  const renderRows = () => {
    return purchaseData?.payments.map(
      ({ hasEReceipt, paymentDate, caption, discountCents, maximaMoneyCents, sumCents, transactionId }, i) => {
        return {
          hasEReceipt,
          transactionId,
          paymentDate: formatDate(paymentDate, {
            dateStyle: 'short',
            timeStyle: 'short',
          }),
          caption: caption.et || caption.en,
          discountCents: convertCentsToEur(discountCents),
          maximaMoneyCents: convertCentsToEur(maximaMoneyCents),
          sumCents: convertCentsToEur(sumCents),
          id: i,
        };
      },
    );
  };

  const handleOpenModal = () => {
    setOpened(true);
  };

  const handleCloseModal = () => {
    setOpened(false);
  };

  return (
    <Box
      sx={(theme) => ({
        marginBottom: '1em',
        width: '100%',
        '& .MuiDataGrid-root': { color: theme.colors.maximaNavyBlueDefault, border: 'none', fontFamily: 'ProximaNova' },
      })}
    >
      {isLoading ? (
        <Loader sx={{ marginLeft: 'auto', marginRight: 'auto', width: '100%', marginTop: '2rem' }} size={56} />
      ) : (
        <DataGrid
          rows={renderRows() || []}
          rowsPerPageOptions={[]}
          pageSize={25}
          loading={isLoading}
          columns={columns}
          paginationMode="server"
          rowCount={purchaseData?.totalRecords || 25}
          onPageChange={handlePageChange}
          page={page}
          localeText={{
            noResultsOverlayLabel: isError ? t('purchaseHistory.fetchDataError') : t('purchaseHistory.noRowsLabel'),
          }}
          autoHeight
          disableColumnMenu
          onCellClick={(params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
            event.defaultMuiPrevented = true;
            const { transactionId, hasEReceipt } = params.row;
            if (!accountId || !hasEReceipt) return;
            triggerGetReceipt({ accountId, transactionId });
            handleOpenModal();
          }}
          components={{
            Pagination,
            LoadingOverlay: () => (
              <Loader sx={{ marginLeft: 'auto', marginRight: 'auto', width: '100%', marginTop: '2rem' }} size={56} />
            ),
          }}
          componentsProps={{
            pagination: { purchaseData, page },
          }}
          getRowClassName={(params) => (params.row.hasEReceipt ? '' : 'no-receipt')}
          sx={() => ({
            '.MuiDataGrid-row:hover': {
              backgroundColor: '#e5edf4',
            },
            '.no-receipt': {
              cursor: 'default',
            },
          })}
        />
      )}
      <ReceiptModal
        opened={opened}
        handleCloseModal={handleCloseModal}
        data={receiptData?.Receipt}
        isLoading={receiptLoading}
      />
    </Box>
  );
};

export default PurchaseHistoryTable;
