import { Box, Text, Title } from '@mantine/core';

interface SubHeaderProps {
  title: string;
  subTitle?: string;
}

const SubHeader = (props: SubHeaderProps) => {
  return (
    <Box
      className="subheader"
      sx={(theme) => ({
        paddingTop: theme.other.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: '110px',
        [theme.fn.smallerThan(600)]: {
          paddingTop: theme.other.spacing(4),
          paddingBottom: theme.other.spacing(3),
          justifyContent: 'center',
        },
      })}
    >
      <Box
        sx={(theme) => ({
          paddingBottom: theme.other.spacing(1),
        })}
      >
        <Title
          sx={(theme) => ({
            color: theme.colors.brandWhite,
            fontWeight: 700,
            fontSize: '3rem',
            fontFamily: 'ProximaNova',
            letterSpacing: '0.01em',
            [theme.fn.smallerThan(800)]: {
              fontSize: '2.2rem',
            },
            [theme.fn.smallerThan(600)]: {
              fontSize: '1.8rem',
            },
          })}
        >
          {props.title}
        </Title>
        {props.subTitle && (
          <Text
            sx={(theme) => ({
              color: theme.colors.brandWhite,
              letterSpacing: '0.02em',
            })}
          >
            {props.subTitle}
          </Text>
        )}
      </Box>
    </Box>
  );
};

export default SubHeader;
