import { Anchor, Grid } from '@mantine/core';
import { Trans, useTranslation } from 'react-i18next';
import { UseFormReturnType } from '@mantine/form/lib/use-form';
import CheckboxGroup from '../../../../components/form/Checkbox/CheckboxGroup';
import { ProfileDataFormValues } from '../../../../utility/saveProfileUtils';
import Marketing5RewardNotice from '../../../../components/Rewards/Marketing5';
import { useAppSelector } from '../../../../hooks/store';

const CustomerPersonalOffersAgreementsRow = (props: { form: UseFormReturnType<ProfileDataFormValues> }) => {
  const { t } = useTranslation();
  const accountId = useAppSelector((state) => state.user.personalData?.accountId);
  const handlePersonalOffersChange = (value: string[]) => {
    const newValue = value.includes('agreeToReceiveBirthdayOffers')
      ? ['agreeToGetMarketingInformation', 'agreeToReceiveBirthdayOffers']
      : [];
    return props.form.setFieldValue('agreementsPersonalOffers', newValue);
  };

  return (
    <>
      <Grid
        gutter="lg"
        sx={(theme) => ({
          marginTop: theme.other.spacing(2),
          alignItems: 'flex-start',
        })}
      >
        <Grid.Col sm={12}>
          <CheckboxGroup
            orientation="vertical"
            onChange={handlePersonalOffersChange}
            value={props.form.values.agreementsPersonalOffers}
            required
            error={props.form.errors.agreementGeneral}
            label={t('birthdayOffers.profileData.agreementsGroupHeader')}
            options={[
              {
                value: 'agreeToReceiveBirthdayOffers',
                label: (
                  <Trans i18nKey="birthdayOffers.agreeToReceiveBirthdayOffers.label">
                    I agree to receive birthday offers. Read more at birthday
                    <Anchor
                      href={t('birthdayOffers.agreeToReceiveBirthdayOffers.TCUrl')}
                      target="_blank"
                      sx={() => ({
                        '&': {
                          fontSize: '0.9rem',
                        },
                      })}
                    >
                      terms and conditions
                    </Anchor>
                    .
                  </Trans>
                ),
              },
            ]}
          />
          <Marketing5RewardNotice accountId={accountId} />
        </Grid.Col>
      </Grid>
    </>
  );
};

export default CustomerPersonalOffersAgreementsRow;
