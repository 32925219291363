import { Grid } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import TextInput from '../../../../components/form/TextInput';

const CustomerPersonalCodeRow = (props: { code?: string }) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid
        gutter="lg"
        sx={(theme) => ({
          marginTop: theme.other.spacing(2),
          alignItems: 'flex-start',
        })}
      >
        <Grid.Col lg={12}>
          <TextInput value={props.code || ''} label={t('birthdayOffers.profileData.personalCode')} disabled={true} />
        </Grid.Col>
      </Grid>
    </>
  );
};

export default CustomerPersonalCodeRow;
