import { Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import LoginForm from './LoginForm';
import RegisterCardForm from './RegisterCardForm';

const CredentialsLoginForm = (props: { cancel: () => void }) => {
  const { t } = useTranslation();

  return (
    <>
      <Title
        order={2}
        sx={(theme) => ({
          fontSize: '1.5rem',
          fontFamily: 'ProximaNova',
          letterSpacing: '0.02em',
          color: theme.colors.maximaNavyBlueDefault,
          marginTop: theme.other.spacing(1),
          marginBottom: theme.other.spacing(1),
        })}
      >
        {t('landing.formCard.login')}
      </Title>
      <LoginForm cancel={props.cancel} />
      <Title
        order={3}
        sx={(theme) => ({
          fontSize: theme.spacing.md,
          color: theme.colors.maximaNavyBlueDefault,
          letterSpacing: '0.02em',
          marginTop: theme.other.spacing(5),
          marginBottom: theme.other.spacing(2),
          fontFamily: 'ProximaNova',
        })}
      >
        {t('landing.formCard.register')}
      </Title>
      <RegisterCardForm />
    </>
  );
};

export default CredentialsLoginForm;
