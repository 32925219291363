import { Box, Button, createStyles, Image } from '@mantine/core';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CheckWhite from '../../assets/svg/check_white.svg';
import { StrongAuthMode } from '../../utility';

const useStyles = createStyles((theme) => ({
  authOptionButton: {
    flexGrow: 1,
    minWidth: 0,
    width: 'auto !important',
  },
  firstVisible: {
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
  },
  lastVisibleMobile: {
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
    '@media (pointer:none), (pointer:coarse)': {
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
    },
  },
  lastVisibleDesktop: {
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
    '@media (pointer:none), (pointer:coarse)': {
      display: 'none',
    },
  },
  selected: {
    borderWidth: 0,
    borderColor: theme.colors.maximaBlueDefault,
    color: theme.colors.brandWhite,
    backgroundColor: theme.colors.maximaBlueDefault,
    '&:hover': {
      backgroundColor: theme.colors.maximaBlueLight,
    },
  },
  unselected: {
    backgroundColor: theme.colors.brandWhite,
  },
  button: {
    width: '4em',
  },
  desktopOnly: {
    '@media (pointer:none), (pointer:coarse)': {
      display: 'none',
    },
  },
}));

const StrongAuthSwitch = (props: { modeChanged: (newMode: StrongAuthMode) => void }) => {
  const { t } = useTranslation();
  const [authMode, setAuthMode] = useState(StrongAuthMode.SmartId);
  const { classes } = useStyles();

  const selectSmartId = useCallback(() => {
    setAuthMode(StrongAuthMode.SmartId);
    props.modeChanged(StrongAuthMode.SmartId);
  }, [props]);

  const selectMobileId = useCallback(() => {
    setAuthMode(StrongAuthMode.MobileId);
    props.modeChanged(StrongAuthMode.MobileId);
  }, [props]);

  const selectIdCard = useCallback(() => {
    setAuthMode(StrongAuthMode.IdCard);
    props.modeChanged(StrongAuthMode.IdCard);
  }, [props]);

  return (
    <Box
      sx={() => ({
        display: 'flex',
        flexDirection: 'row',
        gap: 0,
      })}
    >
      <Button
        className={`${authMode == StrongAuthMode.SmartId ? classes.selected : classes.unselected} ${
          classes.authOptionButton
        } ${classes.firstVisible}`}
        variant={authMode == StrongAuthMode.SmartId ? 'default' : 'outline'}
        onClick={selectSmartId}
        leftIcon={authMode == StrongAuthMode.SmartId ? <Image src={CheckWhite}></Image> : null}
      >
        {t('landing.strongAuthLoginForm.smartId')}
      </Button>
      <Button
        className={`${authMode == StrongAuthMode.MobileId ? classes.selected : classes.unselected} ${
          classes.authOptionButton
        } ${classes.lastVisibleMobile}`}
        variant={authMode == StrongAuthMode.MobileId ? 'default' : 'outline'}
        onClick={selectMobileId}
        leftIcon={authMode == StrongAuthMode.MobileId ? <Image src={CheckWhite}></Image> : null}
      >
        {t('landing.strongAuthLoginForm.mobileId')}
      </Button>
      <Button
        className={`${authMode == StrongAuthMode.IdCard ? classes.selected : classes.unselected} ${
          classes.desktopOnly
        } ${classes.authOptionButton} ${classes.lastVisibleDesktop}`}
        variant={authMode == StrongAuthMode.IdCard ? 'default' : 'outline'}
        onClick={selectIdCard}
        leftIcon={authMode == StrongAuthMode.IdCard ? <Image src={CheckWhite}></Image> : null}
      >
        {t('landing.strongAuthLoginForm.idCard')}
      </Button>
    </Box>
  );
};

export default StrongAuthSwitch;
