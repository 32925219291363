import { Modal as MantineModal, ModalProps as MantineModalProps, Box, Title } from '@mantine/core';
import InfoIcon from '@mui/icons-material/Info';

const Modal = (props: MantineModalProps) => {
  return (
    <MantineModal
      sx={(theme) => ({
        '& .mantine-Modal-header': {
          alignItems: 'flex-start',
        },
        '& .mantine-Title-root': {
          marginTop: theme.other.spacing(0.5),
        },
        '& .mantine-Modal-close': {
          borderRadius: '50%',
          padding: theme.other.spacing(0.5),
          height: '36px',
          minHeight: '36px',
          width: '36px',
          minWidth: '36px',
          '&:not(:disabled):active': {
            transform: 'translateY(0)',
          },
          '& svg': {
            width: '20px',
            height: '20px',
          },
        },
      })}
      {...props}
      centered
      title={
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'flex-start',
            gap: theme.other.spacing(2),
            '& .MuiSvgIcon-root': {
              color: theme.colors.maximaBlueDefault,
            },
          })}
        >
          <Box
            sx={(theme) => ({
              borderRadius: '50%',
              padding: theme.other.spacing(1),
              backgroundColor: theme.fn.rgba(theme.colors.maximaBlueDefault[0], 0.1),
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              [theme.fn.smallerThan(450)]: {
                display: 'none',
              },
            })}
          >
            <InfoIcon />
          </Box>
          <Title
            order={3}
            sx={(theme) => ({
              fontFamily: 'ProximaNova',
              marginTop: theme.other.spacing(0.25),
            })}
          >
            {props.title}
          </Title>
        </Box>
      }
      radius={5}
      opened={props.opened}
      onClose={props.onClose}
    >
      {props.children}
    </MantineModal>
  );
};

export default Modal;
