import { Box, Image, Text, Title } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch } from 'react-redux';
import languages from '../../../components/LanguageChanger/languages';
import Loader from '../../../components/loader';
import { useAppSelector } from '../../../hooks/store';
import RecaptchaAction from '../../../models/RecaptchaAction';
import { useProfileLandingSaveLanguageMutation } from '../../../store/api/profileLandingApi';
import { setLanguage } from '../../../store/profileLandingSlice';

const LanguageSelection = (props: { completeSelection: () => void }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const [previousLanguage, setPreviousLanguage] = useState(i18n.language);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [saveLanguage, saveLanguageQueryState] = useProfileLandingSaveLanguageMutation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const tempToken = useAppSelector((state) => state.profileLanding.tempToken) || '';
  const navigate = useNavigate();

  const handleLanguageChange = async (language: string) => {
    setPreviousLanguage(i18n.language);
    setSelectedLanguage(language);
    const recaptchaToken = await executeRecaptcha?.(RecaptchaAction.ProfileLandingSaveLanguage);
    saveLanguage({ language: language, tempToken: tempToken, recaptchaToken: recaptchaToken });
  };

  useEffect(() => {
    if (saveLanguageQueryState.isSuccess) {
      saveLanguageQueryState.reset();
      i18n.changeLanguage(selectedLanguage);
      dispatch(setLanguage(selectedLanguage));
      if (previousLanguage != selectedLanguage) {
        let nextPath = location.pathname.replace(`/${previousLanguage}/`, `/${selectedLanguage}/`);
        nextPath = `${nextPath}${location.search}`;
        navigate(nextPath);
      }
      props.completeSelection();
    }
  }, [
    saveLanguageQueryState.isSuccess,
    props.completeSelection,
    saveLanguageQueryState,
    selectedLanguage,
    previousLanguage,
    i18n,
    props,
    navigate,
    dispatch,
  ]);

  return (
    <Box
      sx={(theme) => ({
        width: '60% !important',
        [theme.fn.smallerThan(960)]: {
          width: '100% !important',
        },
      })}
    >
      <Title
        sx={(theme) => ({
          color: theme.colors.brandGray,
          fontFamily: 'ProximaNovaBold',
          fontSize: '1.25rem',
          fontWeight: 700,
          letterSpacing: '0.01em',
          [theme.fn.smallerThan(800)]: {
            fontSize: '2.2rem',
          },
          [theme.fn.smallerThan(600)]: {
            fontSize: '1.6rem',
          },
        })}
      >
        {t('profileLanding.languageSelectionStep.title')}
      </Title>
      <Text
        sx={(theme) => ({
          '&': {
            marginTop: theme.other.spacing(2),
            color: '#828282',
          },
        })}
      >
        <Text>{t('profileLanding.languageSelectionStep.description')}</Text>
      </Text>
      <Box
        sx={() => ({
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          gap: '25px',
          marginTop: '1em',
          '& img': {
            border: '1px solid #DDD',
            cursor: 'pointer',
          },
        })}
      >
        {saveLanguageQueryState.isLoading ? (
          <Box
            sx={() => ({
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            })}
          >
            <Loader></Loader>
          </Box>
        ) : (
          languages.map((language) => (
            <Box key={language.value} onClick={() => handleLanguageChange(language.value)}>
              <Image width={62} src={language.flag} alt={language.label} />
            </Box>
          ))
        )}
      </Box>
    </Box>
  );
};

export default LanguageSelection;
