import { Checkbox as MantineCheckbox, CheckboxProps } from '@mantine/core';

const Checkbox = (props: CheckboxProps) => {
  return (
    <MantineCheckbox
      {...props}
      sx={(theme) => ({
        marginTop: theme.other.spacing(1),
        marginBottom: theme.other.spacing(1),
        '& .mantine-Checkbox-inner': {
          color: theme.colors.grayDark,
        },
        '& .mantine-Checkbox-input': {
          borderRadius: '4px',
          borderWidth: '2px',
          backgroundColor: theme.colors.maximaBlueDefault,
          borderColor: theme.colors.maximaBlueDefault,
          cursor: 'pointer',
          '&:not(:checked)': {
            backgroundColor: theme.colors.brandWhite,
            borderColor: theme.colors.maximaBlueLight,
          },
        },
        '& .mantine-Checkbox-label': {
          paddingLeft: theme.other.spacing(1),
          color: theme.colors.brandGray,
          fontSize: '0.9rem',
          lineHeight: 1.1,
          cursor: 'pointer',
          userSelect: 'none',
        },
        ...(props.required
          ? {
              '& .mantine-Checkbox-label::after': {
                color: '#f03e3e',
                content: '" *"',
              },
            }
          : {}),
      })}
    />
  );
};

export default Checkbox;
