import { Box } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form/lib/use-form';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxGroup from '../../../components/form/Checkbox/CheckboxGroup';
import CheckboxLabelWithTooltip from '../../../components/form/Checkbox/CheckboxLabelWithTooltip';
import { ProfileDataFormValues } from '../../../utility/saveProfileUtils';

interface EReceiptsAgreementsGroupProps {
  form: UseFormReturnType<ProfileDataFormValues>;
  disabled?: boolean;
}

const EReceiptsAgreementsGroup = ({ form, disabled }: EReceiptsAgreementsGroupProps) => {
  const { t } = useTranslation();
  const formValues = form.values;

  // eslint-disable-next-line
  const setFieldValue = useCallback(form.setFieldValue, []);

  useEffect(() => {
    if (!formValues.email && formValues.agreementsEReceipts.includes('agreeToReceiveTheERecieptViaEmail')) {
      const filteredConsents = formValues.agreementsEReceipts.filter(
        (consent) => consent !== 'agreeToReceiveTheERecieptViaEmail',
      );
      setFieldValue('agreementsEReceipts', filteredConsents);
    }
  }, [formValues, setFieldValue]);

  const handleEReceiptsChange = (value: string[]) => {
    const agreeToReceiveTheERecieptViaEmail = value.includes('agreeToReceiveTheERecieptViaEmail');
    const agreeToReceiveTheEReciept = value.includes('agreeToReceiveTheEReciept');
    const agreements = agreeToReceiveTheERecieptViaEmail && !agreeToReceiveTheEReciept ? [] : value;
    form.setFieldValue('agreementsEReceipts', agreements);
  };

  return (
    <Box
      sx={(theme) => ({
        marginTop: theme.other.spacing(6),
      })}
    >
      <CheckboxGroup
        orientation="vertical"
        value={form.values.agreementsEReceipts}
        onChange={handleEReceiptsChange}
        label={t('registerCard.saveProfileData.agreementsEReceiptsTitle')}
        options={[
          {
            value: 'agreeToReceiveTheEReciept',
            label: (
              <CheckboxLabelWithTooltip
                label={t('registerCard.saveProfileData.agreeToReceiveTheEReciept')}
                tooltipLabel={t('registerCard.saveProfileData.agreeToReceiveTheERecieptTooltip')}
              />
            ),
            disabled,
          },
          {
            value: 'agreeToReceiveTheERecieptViaEmail',
            label: t('registerCard.saveProfileData.agreeToReceiveTheERecieptViaEmail'),
            disabled:
              !form.values.email || !form.values.agreementsEReceipts.includes('agreeToReceiveTheEReciept') || disabled,
          },
        ]}
      />
    </Box>
  );
};

export default EReceiptsAgreementsGroup;
