import { Box, Text, Title } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { ChangeEvent, useCallback, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Button from '../../../components/Button';
import TextInput from '../../../components/form/TextInput';
import { ApiError, ErrorCode } from '../../../models/ApiError';
import RecaptchaAction from '../../../models/RecaptchaAction';
import { useStrongAuthRegisterSaveEmailMutation } from '../../../store/api/strongAuthRegisterApi';
import { decodeTempToken, emailEntered } from '../../../store/strongAuthRegisterSlice';
import { checkIfHasEmail, isValidEstonianMobilePhoneOrEmail } from '../../../utility';
import { useAppDispatch, useAppSelector } from '../../../hooks/store';

interface EmailEntryFormValues {
  email: string;
}

const EmailEntry = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const tempToken = useAppSelector((state) => state.strongAuthRegister.tempToken)!;
  const tokenData = decodeTempToken(tempToken);
  const [saveEmail, saveEmailQueryState] = useStrongAuthRegisterSaveEmailMutation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const form = useForm<EmailEntryFormValues>({
    schema: yupResolver(
      Yup.object().shape({
        email: Yup.string()
          .ensure()
          .test('empty-field', t('strongAuthRegister.emailStep.emailMissing'), checkIfHasEmail)
          .test('email-valid', t('strongAuthRegister.emailStep.emailInvalid'), isValidEstonianMobilePhoneOrEmail),
      }),
    ),
    initialValues: {
      email: tokenData?.email ?? '',
    },
  });

  const getErrorMessage = useCallback(
    (error: ApiError) => {
      const errorCode = error.data?.message;
      if (error.status === 403) {
        return t('api.sessionExpiredError');
      }
      if (errorCode === ErrorCode.SendingCodeFailed) {
        return t('createAccount.sendCode.sendingCodeFailed');
      }
      if (errorCode === ErrorCode.EmailOrPhoneCannotBeUsed) {
        return t('strongAuthRegister.emailStep.emailCannotBeUsed');
      }
      if (errorCode === ErrorCode.RecaptchaError) {
        return t('api.recaptchaError');
      }

      return t('api.unknownError');
    },
    [t],
  );

  useEffect(() => {
    if (saveEmailQueryState.isError && saveEmailQueryState.error) {
      const errorMessage = getErrorMessage(saveEmailQueryState.error as ApiError);
      toast(errorMessage, { type: 'error' });
    }
  }, [saveEmailQueryState.isError, saveEmailQueryState.error, getErrorMessage, t]);

  useEffect(() => {
    if (saveEmailQueryState.isSuccess && saveEmailQueryState.data.tempToken) {
      dispatch(emailEntered(saveEmailQueryState.data.tempToken));
    }
  }, [saveEmailQueryState.isSuccess, dispatch, form.values.email, saveEmailQueryState.data?.tempToken]);

  const handleFormSubmit = useCallback(
    async (values: EmailEntryFormValues) => {
      if (form.validate().hasErrors) return;

      const recaptchaToken = await executeRecaptcha?.(RecaptchaAction.StrongAuthRegisterSaveEmail);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      saveEmail({ email: values.email, tempToken: tempToken!, recaptchaToken: recaptchaToken });
    },
    [form, executeRecaptcha, saveEmail, tempToken],
  );

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    form.setFieldValue('email', value);
    if (form.errors.email) {
      form.clearErrors();
    }
  };

  return (
    <Box
      sx={(theme) => ({
        width: '60% !important',
        [theme.fn.smallerThan(960)]: {
          width: '100% !important',
        },
      })}
    >
      <Title
        sx={(theme) => ({
          color: theme.colors.brandGray,
          fontFamily: 'ProximaNovaBold',
          fontSize: '1.25rem',
          fontWeight: 700,
          letterSpacing: '0.01em',
          [theme.fn.smallerThan(800)]: {
            fontSize: '2.2rem',
          },
          [theme.fn.smallerThan(600)]: {
            fontSize: '1.6rem',
          },
        })}
      >
        {t('strongAuthRegister.emailStep.entryTitle')}
      </Title>
      <Text
        sx={(theme) => ({
          '&': {
            marginTop: theme.other.spacing(2),
            color: '#828282',
          },
        })}
      >
        <Text>{t('strongAuthRegister.emailStep.entryDescription')}</Text>
      </Text>
      <form onSubmit={form.onSubmit(handleFormSubmit)}>
        <TextInput
          name="email"
          type="email"
          label={t('strongAuthRegister.emailStep.email')}
          required
          onChange={handleEmailChange}
          error={form.errors.email}
          value={form.values.email}
        />
        <Button type="submit">{t('strongAuthRegister.continue')}</Button>
      </form>
    </Box>
  );
};

export default EmailEntry;
