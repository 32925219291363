import { Menu, Box, Text } from '@mantine/core';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useTranslation } from 'react-i18next';
import UserMenuFields from './UserMenuFields';
import { useAppSelector } from '../../../hooks/store';

const UserMenu = () => {
  const firstName = useAppSelector((state) => state.user.personalData?.profileData.firstName);
  const accountId = useAppSelector((state) => state.user.personalData?.accountId);
  const { t } = useTranslation();

  const title = typeof accountId !== 'number' ? '' : firstName || t('header.menu');

  return (
    <Menu
      sx={{
        fontFamily: 'ProximaNova',
        fontSize: '1rem',
      }}
      control={
        <Box sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
          <AccountCircleIcon />
          <Text sx={(theme) => ({ marginLeft: theme.other.spacing(0.5) })}>{title}</Text>
        </Box>
      }
      placement="end"
    >
      <UserMenuFields />
    </Menu>
  );
};

export default UserMenu;
