import { Box, Image, Loader, Title } from '@mantine/core';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import successImage from '../../../assets/svg/success_big.svg';
import { useAuthenticateMutation } from '../../../store/api/dokobitApi';

const IntermediarySuccessPartial = (props: { nextStep: () => void }) => {
  const { t } = useTranslation();
  const [, response] = useAuthenticateMutation({ fixedCacheKey: 'dokobit-authentication' });

  useEffect(() => {
    if (response.isSuccess) {
      setTimeout(() => props.nextStep(), 1000);
    }
  }, [props, props.nextStep, response.isSuccess]);

  return (
    <>
      <Box
        sx={() => ({
          '&': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            flexGrow: 1,
            alignItems: 'center',
            height: '500px',
          },
        })}
      >
        <Box
          sx={() => ({
            '&': {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            },
          })}
        >
          {response.isLoading ? (
            <Loader
              size={'xl'}
              sx={(theme) => ({
                '&': {
                  height: '180px',
                  width: '180px',
                  marginTop: theme.other.spacing(4),
                  marginBottom: theme.other.spacing(4),
                },
              })}
            />
          ) : (
            <Image
              src={successImage}
              alt="Success checkmark"
              sx={(theme) => ({
                '&': {
                  width: '180px',
                  aspectRatio: '1',
                  paddingTop: theme.other.spacing(4),
                  paddingBottom: theme.other.spacing(4),
                },
              })}
            />
          )}
        </Box>
        <Box
          sx={(theme) => ({
            '&': {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              paddingBottom: theme.other.spacing(4),
            },
          })}
        >
          <Title
            sx={() => ({
              '&': {
                textAlign: 'center',
                fontWeight: 700,
                fontSize: '24px',
                color: '#363D40',
              },
            })}
          >
            {t('birthdayOffers.modalAuthSuccess')}
          </Title>
        </Box>
      </Box>
    </>
  );
};

export default IntermediarySuccessPartial;
