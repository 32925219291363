import { useCallback, useState } from 'react';
import ChangeProfile from './ChangeProfile';
import ChangePassword from './ChangePassword';
import useScrollToTopOnStateChange from '../../utility/hooks/useScrollToTopOnStateChange';

export type ProfileView = 'profileData' | 'changePassword';

const Profile = () => {
  const [profileView, setProfileView] = useState<ProfileView>('profileData');

  useScrollToTopOnStateChange(profileView);

  const handleChangeView = useCallback((view: ProfileView) => {
    setProfileView(view);
  }, []);

  if (profileView === 'profileData') {
    return <ChangeProfile onChangeView={handleChangeView} />;
  }

  if (profileView === 'changePassword') {
    return <ChangePassword onChangeView={handleChangeView} />;
  }

  return <ChangeProfile onChangeView={handleChangeView} />;
};

export default Profile;
