import { Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { DataLine } from '../../../store/api/purchaseHistoryApi';
import useFormatCurrency from '../../../utility/hooks/useFormatCurrency';

interface CardLinesProps {
  data: DataLine;
}

const CardLines = ({ data }: CardLinesProps) => {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();
  const filteredKeys = Object.keys(data).filter((key) => {
    if (key == 'ExpWarning') return false;

    return true;
  });

  const rows = filteredKeys.map((key, i) => {
    let { name } = data[key];
    const { amount } = data[key];

    if (key == 'GetForRcp') name = t('purchaseHistory.card.moneyEarned');
    if (key == 'Balance') name = t('purchaseHistory.card.moneyBalance');

    return (
      <View style={{ flexDirection: 'row' }} key={i}>
        <Text style={{ width: '80%', textAlign: 'right' }}>{name}</Text>
        {amount && (
          <Text style={{ width: '20%', textAlign: 'right', fontWeight: 'bold' }}>{formatCurrency(amount)}</Text>
        )}
      </View>
    );
  });

  return (
    <View
      style={{
        marginTop: 10,
      }}
    >
      {rows}
    </View>
  );
};

export default CardLines;
