import { api, QueryParamKey, RecaptchaProtected } from '.';

export interface AccountVerificationResponsePayload {
  verificationToken: string;
}

interface SendConfirmationCodeQueryPayload extends RecaptchaProtected {
  verificationToken: string;
}

interface VerifyContactQueryPayload extends RecaptchaProtected {
  email?: string;
  phoneNumber?: string;
  cardNumber: string;
}

interface VerifyConfirmationCodeQueryPayload extends RecaptchaProtected {
  code: string;
  verificationToken: string;
}

const verificationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    sendConfirmationCode: builder.mutation<AccountVerificationResponsePayload, SendConfirmationCodeQueryPayload>({
      query: ({ verificationToken, recaptchaToken }) => ({
        url: `/contact/send-code`,
        method: 'POST',
        params: {
          [QueryParamKey.recaptchaToken]: recaptchaToken,
        },
        body: {
          verificationToken,
        },
      }),
      async onQueryStarted(
        // eslint-disable-next-line
        arg,
        // eslint-disable-next-line
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry },
      ) {
        console.log('Calling old send code endpoint');
      },
    }),
    verifyContactAndSendCode: builder.mutation<AccountVerificationResponsePayload, VerifyContactQueryPayload>({
      query: ({ email, phoneNumber, cardNumber, recaptchaToken }) => ({
        url: `/contact/verify`,
        method: 'POST',
        params: {
          [QueryParamKey.recaptchaToken]: recaptchaToken,
        },
        body: {
          email,
          phoneNumber,
          cardNumber,
        },
      }),
    }),
    verifyConfirmationCode: builder.mutation<AccountVerificationResponsePayload, VerifyConfirmationCodeQueryPayload>({
      query: ({ code, verificationToken, recaptchaToken }) => ({
        url: `/contact/confirm`,
        method: 'POST',
        params: {
          [QueryParamKey.recaptchaToken]: recaptchaToken,
          [QueryParamKey.code]: code,
        },
        body: {
          verificationToken,
        },
      }),
    }),
  }),
});

export const {
  useSendConfirmationCodeMutation,
  useVerifyContactAndSendCodeMutation,
  useVerifyConfirmationCodeMutation,
} = verificationApi;

export default verificationApi.reducer;
