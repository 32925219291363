import { api } from '.';

export interface LoyaltyExitResponse {
  message: string;
}

const loyaltyExitApi = api.injectEndpoints({
  endpoints: (builder) => ({
    exitLoyalty: builder.mutation<LoyaltyExitResponse, number>({
      query: (accountId) => ({
        url: `/loyalty-exit/${accountId}`,
        method: 'PUT',
      }),
    }),
  }),
});

export const { useExitLoyaltyMutation } = loyaltyExitApi;

export default loyaltyExitApi.reducer;
