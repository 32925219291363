import { Box, Grid, ScrollArea, Title, Button } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useForm, yupResolver } from '@mantine/form';
import * as Yup from 'yup';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import CustomerNameRow from './ProfileDataRows/CustomerNameRow';
import CustomerBirthDateRow from './ProfileDataRows/CustomerBirthDateRow';
import CustomerPersonalCodeRow from './ProfileDataRows/CustomerPersonalCodeRow';
import CustomerPhoneRow from './ProfileDataRows/CustomerPhoneRow';
import CustomerPreferedLanguageRow from './ProfileDataRows/CustomerPreferedLanguageRow';
import CustomerEmailRow from './ProfileDataRows/CustomerEmailRow';
import CustomerPersonalOffersAgreementsRow from './ProfileDataRows/CustomerPersonalOffersAgreementsRow';
import CustomerOfferMethodsAgreementsRow from './ProfileDataRows/CustomerOfferMethodsAgreementsRow';
import { Language, getDateOfBirthObjectFromPersonalCode, isValidEstonianMobilePhoneOrEmail } from '../../../utility';
import {
  getProfileDataFromForm,
  getProfileFormValues,
  getUserConsentsFromForm,
  ProfileDataFormValues,
} from '../../../utility/saveProfileUtils';
import { useAppSelector } from '../../../hooks/store';
import { useAuthenticateMutation, useUpdateProfileMutation } from '../../../store/api/dokobitApi';
import { PromptContact, setPersonalData } from '../../../store/userSlice';
import { ApiError, ErrorCode } from '../../../models/ApiError';
import Checkbox from '../../../components/form/Checkbox/Checkbox';
import { useUpdateProfileDataMutation } from '../../../store/api/profileApi';

const ProfileDataPartial = (props: { nextStep: () => void; setPromptContact: (data: PromptContact) => void }) => {
  const { t, i18n } = useTranslation();
  const [updateProfile, updateProfileResponse] = useUpdateProfileMutation();
  const [updateProfileData, updateProfileDataResponse] = useUpdateProfileDataMutation();
  const [, authenticationResponse] = useAuthenticateMutation({ fixedCacheKey: 'dokobit-authentication' });
  const dispatch = useDispatch();
  const [dataIsCorrect, setDataIsCorrect] = useState(false);

  const personalData = useAppSelector((state) => state.user.personalData);
  const cardNumber = useAppSelector((state) => state.card.cardNumber);
  const existingProfileData = useAppSelector((state) => state.user.personalData?.profileData);
  const personalCode = useAppSelector((state) => state.user.personalData?.profileData.personalCode);
  const birthDateObject = personalCode ? getDateOfBirthObjectFromPersonalCode(personalCode) : null;

  const getSaveProfileErrorMessage = useCallback(
    (error: ApiError) => {
      const errorCode = error.data?.message;
      if (error.status === 403) {
        return t('api.sessionExpiredError');
      }
      if (errorCode === ErrorCode.CardBirthdateVerificationFailed) {
        return t('registerCard.saveProfileData.dateOfBirthIncorrectError');
      }
      if (errorCode === ErrorCode.EmailOrPhoneCannotBeUsed) {
        return t('myProfile.profileDataForm.emailOrPhoneCannotBeUsedError');
      }
      if (errorCode === ErrorCode.CardNotFoundOrInInvalidState) {
        return t('registerCard.saveProfileData.cardNotFoundOrInInvalidStateError');
      }
      if (errorCode === ErrorCode.CardBirthdateVerificationFailed) {
        return t('registerCard.saveProfileData.dateOfBirthIncorrectError');
      }
      if (errorCode === ErrorCode.CreatingOrUpdatingProfileFailed) {
        return t('registerCard.saveProfileData.creatingProfileFailed');
      }
      if (errorCode === ErrorCode.BirthDateChangeExhausted) {
        return t('myProfile.profileDataForm.birthDateExhaustedError');
      }
      if (errorCode === ErrorCode.RecaptchaError) {
        return t('api.recaptchaError');
      }
      if (errorCode === ErrorCode.PersonalCodeAlreadyExists) {
        return t('birthdayOffers.personalCodeAlreadyExists');
      }
      return t('api.unknownError');
    },
    [t],
  );

  const handleUpdateProfileFormSubmit = (values: ProfileDataFormValues) => {
    if (updateProfileResponse.isLoading || updateProfileDataResponse.isLoading) {
      return;
    }

    const profileData = getProfileDataFromForm(values, { update: true });
    const consents = getUserConsentsFromForm(values);

    if (!authenticationResponse?.data?.personalDataVerificationToken) {
      // Previously strongly authenticated
      updateProfileData({
        cardNumber: cardNumber,
        consents: consents,
        profileData: profileData,
      });
    } else {
      // Previously not-strongly authenticated (strong auth performed during offers' modal)
      const personalDataVerificationToken = authenticationResponse.data.personalDataVerificationToken;

      updateProfile({
        personalDataVerificationToken,
        cardNumber,
        profileData,
        consents,
      });
    }
  };

  // Dokobit based for previously non-strong auth saving
  useEffect(() => {
    if (updateProfileResponse.isError) {
      const error = updateProfileResponse.error as ApiError;
      const errorMessage = getSaveProfileErrorMessage(error);
      toast(errorMessage, { type: 'error' });
    }
  }, [getSaveProfileErrorMessage, updateProfileResponse.error, updateProfileResponse.isError]);

  useEffect(() => {
    if (updateProfileResponse.isSuccess && updateProfileResponse.data) {
      props.nextStep();
      dispatch(setPersonalData(updateProfileResponse.data));
    }
  }, [dispatch, props, updateProfileResponse.data, updateProfileResponse.isSuccess]);

  // Existing strong auth saving
  useEffect(() => {
    if (updateProfileDataResponse.isError) {
      const error = updateProfileDataResponse.error as ApiError;
      const errorMessage = getSaveProfileErrorMessage(error);
      toast(errorMessage, { type: 'error' });
    }
  }, [getSaveProfileErrorMessage, updateProfileDataResponse.error, updateProfileDataResponse.isError]);

  useEffect(() => {
    if (updateProfileDataResponse.isSuccess && updateProfileDataResponse.data) {
      props.nextStep();
      dispatch(setPersonalData(updateProfileDataResponse.data));
    }
  }, [dispatch, props, updateProfileDataResponse.data, updateProfileDataResponse.isSuccess]);

  const getProfileFormValuesWithDefaultLang = () => {
    const profileData = getProfileFormValues(personalData);
    if (profileData.language) return { ...profileData };
    if (i18n.language.toUpperCase() == 'ET') return { ...profileData, language: Language.ESTONIAN.toString() };
    if (i18n.language.toUpperCase() == 'EN') return { ...profileData, language: Language.ENGLISH.toString() };
    if (i18n.language.toUpperCase() == 'RU') return { ...profileData, language: Language.RUSSIAN.toString() };
    return { ...profileData, language: Language.ESTONIAN.toString() };
  };
  const form = useForm<ProfileDataFormValues>({
    schema: yupResolver(
      Yup.object().shape({
        gender: Yup.string(),
        email: Yup.string()
          .email(t('landing.loginForm.emailInvalidError'))
          .required(t('registerCard.saveProfileData.emailRequiredError')),
        phoneNumber: Yup.string().test(
          'phone-min-length',
          t('landing.loginForm.mobileInvalidError'),
          isValidEstonianMobilePhoneOrEmail,
        ),
        address: Yup.string(),
        language: Yup.string(),
        agreementsPersonalOffers: Yup.array(),
        agreementsOfferMethods: Yup.array(),
      }),
    ),
    initialValues: getProfileFormValuesWithDefaultLang(),
  });
  // eslint-disable-next-line
  const setFieldValue = useCallback(form.setFieldValue, []);

  const handleDataIsCorrectChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDataIsCorrect(e.target.checked);
  };

  useEffect(() => {
    if (!form.values.email && form.values.agreementsOfferMethods.includes('agreeToGetEmail')) {
      const filteredConsents = form.values.agreementsOfferMethods.filter((consent) => consent !== 'agreeToGetEmail');
      setFieldValue('agreementsOfferMethods', filteredConsents);
    }
  }, [form.values.agreementsOfferMethods, form.values.email, setFieldValue]);

  return (
    <>
      <form onSubmit={form.onSubmit(handleUpdateProfileFormSubmit)}>
        <ScrollArea
          sx={(theme) => ({
            '&': {
              height: '680px',
            },
            [theme.fn.largerThan(841)]: {
              '&': {
                width: '580px',
              },
            },
            [theme.fn.smallerThan(841)]: {
              [theme.fn.largerThan(473)]: {
                '&': {
                  width: '400px',
                },
              },
            },
            [theme.fn.smallerThan(473)]: {
              '&': {
                maxWidth: '400px',
              },
            },
          })}
          type={'always'}
          offsetScrollbars={true}
        >
          <Box
            sx={(theme) => ({
              '&': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                paddingBottom: theme.other.spacing(4),
              },
            })}
          >
            <Title
              sx={(theme) => ({
                '&': {
                  textAlign: 'center',
                  fontWeight: 700,
                  fontSize: '36px',
                  color: '#195096',
                },
                [theme.fn.smallerThan(841)]: {
                  '&': {
                    fontSize: '30px',
                  },
                },
                [theme.fn.smallerThan(473)]: {
                  '&': {
                    fontSize: '26px',
                  },
                },
              })}
            >
              {t('birthdayOffers.title')}
            </Title>
            <Title
              sx={(theme) => ({
                '&': {
                  textAlign: 'center',
                  fontWeight: 700,
                  fontSize: '24px',
                  color: '#363D40',
                },
                [theme.fn.smallerThan(841)]: {
                  '&': {
                    fontSize: '21px',
                  },
                },
                [theme.fn.smallerThan(473)]: {
                  '&': {
                    fontSize: '16px',
                  },
                },
              })}
            >
              {t('birthdayOffers.profileDataTitle')}
            </Title>
          </Box>
          <Box>
            <CustomerNameRow
              firstName={personalCode ? existingProfileData?.firstName : authenticationResponse?.data?.payload?.name}
              surname={personalCode ? existingProfileData?.lastName : authenticationResponse?.data?.payload?.surname}
            />
            <CustomerBirthDateRow
              day={birthDateObject?.day ?? authenticationResponse?.data?.payload?.dateOfBirth.day}
              month={birthDateObject?.month ?? authenticationResponse?.data?.payload?.dateOfBirth.month}
              year={birthDateObject?.year ?? authenticationResponse?.data?.payload?.dateOfBirth.year}
            />
            <CustomerPersonalCodeRow code={personalCode ?? authenticationResponse?.data?.payload?.personalCode} />
            <CustomerEmailRow form={form} setPromptContact={props.setPromptContact} />
            <CustomerPhoneRow form={form} setPromptContact={props.setPromptContact} />
            <CustomerPreferedLanguageRow form={form} />
            <Checkbox
              onChange={handleDataIsCorrectChange}
              checked={dataIsCorrect}
              label={t('birthdayOffers.dataIsCorrect')}
            ></Checkbox>
            <CustomerPersonalOffersAgreementsRow form={form} />
            <CustomerOfferMethodsAgreementsRow form={form} />
            <Grid
              gutter="lg"
              sx={(theme) => ({
                marginTop: theme.other.spacing(2),
                alignItems: 'flex-start',
              })}
            >
              <Grid.Col sm={12}>
                <Button
                  sx={() => ({
                    width: '100%',
                  })}
                  type="submit"
                  loading={updateProfileResponse.isLoading}
                  disabled={
                    !(form.values.agreementsPersonalOffers.includes('agreeToReceiveBirthdayOffers') && dataIsCorrect) //NAND
                  }
                >
                  {t('registerCard.saveProfileData.saveProfile')}
                </Button>
              </Grid.Col>
            </Grid>
          </Box>
        </ScrollArea>
      </form>
    </>
  );
};

export default ProfileDataPartial;
