import { Box, Text, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import useAppLogin from '../../../hooks/useAppLogin';
import useRoutes from '../../../i18n/useRoutes';
import StrongAuth from '../../../components/Auth/StrongAuth';
import { strongAuthCompleted } from '../../../store/strongAuthRegisterSlice';
import PersonalData from '../../../models/PersonalData';
import Button from '../../../components/Button';

const StrongAuthLoginForm = (props: { noPersonalCodeClick: () => void }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const routes = useRoutes();
  const { t } = useTranslation();
  const [login] = useAppLogin();
  const tagManagerLogin = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'login',
    });
  };

  const handleFullAuth = (token: string, personalData: PersonalData) => {
    login({
      jwt: token,
      personalData: personalData,
    });
    tagManagerLogin();
  };

  const handlePartialAuth = (tempToken: string) => {
    dispatch(strongAuthCompleted(tempToken));
    navigate(routes.strongAuthRegister);
  };

  return (
    <StrongAuth
      partialAuth={handlePartialAuth}
      fullAuth={handleFullAuth}
      titleContent={
        <Box
          sx={() => ({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '8px',
            alignSelf: 'stretch',
          })}
        >
          <Title
            order={2}
            sx={(theme) => ({
              alignSelf: 'stretch',
              fontSize: '1.5rem',
              fontFamily: 'ProximaNova',
              fontWeight: 700,
              textAlign: 'center',
              letterSpacing: '0.02em',
              color: theme.colors.maximaNavyBlueDefault,
            })}
          >
            {t('landing.strongAuthLoginForm.loginFormTitle')}
          </Title>
          <Text
            sx={() => ({
              color: 'var(--maxima-gray-03, #828282)',
              textAlign: 'center',
              fontFamily: 'ProximaNova',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '22px' /* 137.5% */,
            })}
          >
            {t('landing.strongAuthLoginForm.loginFormDescription')}
          </Text>
        </Box>
      }
      footerContent={
        <Button
          type="button"
          onClick={props.noPersonalCodeClick}
          variant="outline"
          sx={(theme) => ({
            marginTop: theme.other.spacing(1.5),
            marginBottom: theme.other.spacing(1.5),
          })}
        >
          {t('landing.strongAuthLoginForm.noPersonalCode')}
        </Button>
      }
    ></StrongAuth>
  );
};

export default StrongAuthLoginForm;
