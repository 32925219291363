import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '@mantine/hooks';
import { Box, Text } from '@mantine/core';
import { toast } from 'react-toastify';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { setCardState } from '../../../store/cardSlice';
import CardState from '../../../models/CardState';
import { useActivateCardMutation, useBlockCardMutation } from '../../../store/api/cardApi';

interface BlockCardModalRenderProps {
  opened: boolean;
  onOpen: () => void;
}

interface BlockCardModalProps {
  children: (props: BlockCardModalRenderProps) => ReactNode;
  cardState: number;
}

const BlockCardModal = (props: BlockCardModalProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const cardNumber = useAppSelector((state) => state.card.cardNumber);

  const [blockCard, blockCardQueryState] = useBlockCardMutation();
  const [activateCard, activateCardQueryState] = useActivateCardMutation();

  const [opened, { open, close }] = useDisclosure(false);
  const loading = blockCardQueryState.isLoading || activateCardQueryState.isLoading;

  useEffect(() => {
    if (blockCardQueryState.isSuccess) {
      dispatch(setCardState(blockCardQueryState.data.state));
      toast(t('cardSettings.cardBlockSuccess'), { type: 'success' });
    }
  }, [blockCardQueryState.isSuccess, blockCardQueryState.data, dispatch, t]);

  useEffect(() => {
    if (activateCardQueryState.isSuccess) {
      dispatch(setCardState(activateCardQueryState.data.state));
      toast(t('cardSettings.cardActivateSuccess'), { type: 'success' });
    }
  }, [activateCardQueryState.isSuccess, activateCardQueryState.data, dispatch, t]);

  useEffect(() => {
    if (blockCardQueryState.isError) {
      toast(t('cardSettings.cardBlockError'), { type: 'error' });
    }
  }, [blockCardQueryState.isError, t]);

  useEffect(() => {
    if (activateCardQueryState.isError) {
      toast(t('cardSettings.cardActivateError'), { type: 'error' });
    }
  }, [activateCardQueryState.isError, t]);

  useEffect(() => {
    if (activateCardQueryState.isError || activateCardQueryState.isSuccess) {
      activateCardQueryState.reset();
      close();
    }
    if (blockCardQueryState.isError || blockCardQueryState.isSuccess) {
      blockCardQueryState.reset();
      close();
    }
    // eslint-disable-next-line
  }, [
    activateCardQueryState.isError,
    activateCardQueryState.isSuccess,
    blockCardQueryState.isError,
    blockCardQueryState.isSuccess,
  ]);

  const handleSubmit = () => {
    if (props.cardState === CardState.Activated) {
      blockCard({ cardNumber });
    }
    if (props.cardState === CardState.TemporaryBlocked) {
      activateCard({ cardNumber });
    }
  };

  const getModalTitle = () => {
    if (props.cardState === CardState.TemporaryBlocked) {
      return t('cardSettings.activateCard');
    }
    return t('cardSettings.blockCard');
  };

  const getModalText = () => {
    if (props.cardState === CardState.TemporaryBlocked) {
      return t('cardSettings.cardActivateModalText');
    }
    return t('cardSettings.cardBlockModalText');
  };

  const getModalQuestionText = () => {
    if (props.cardState === CardState.TemporaryBlocked) {
      return t('cardSettings.cardActivateModalQuestion');
    }
    return t('cardSettings.cardBlockModalQuestion');
  };

  const getSubmitButtonText = () => {
    if (props.cardState === CardState.TemporaryBlocked) {
      return t('cardSettings.activate');
    }
    return t('cardSettings.block');
  };

  return (
    <>
      {props.children({ opened, onOpen: open })}
      <Modal
        opened={opened}
        withCloseButton={!loading}
        closeOnClickOutside={!loading}
        closeOnEscape={!loading}
        onClose={close}
        title={getModalTitle()}
      >
        <Box
          sx={(theme) => ({
            marginBottom: theme.other.spacing(1),
          })}
        >
          <Text>{getModalText()}</Text>
        </Box>
        <Box
          sx={(theme) => ({
            marginBottom: theme.other.spacing(2),
          })}
        >
          <Text
            sx={{
              fontFamily: 'ProximaNovaSemibold',
            }}
          >
            {getModalQuestionText()}
          </Text>
        </Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'flex-end',
            gap: theme.other.spacing(1),
            [theme.fn.smallerThan(450)]: {
              flexDirection: 'column-reverse',
              gap: theme.other.spacing(2),
            },
          })}
        >
          <Button variant="outline" onClick={close} disabled={loading}>
            {t('cardSettings.cancel')}
          </Button>
          <Button onClick={handleSubmit} loading={loading}>
            {getSubmitButtonText()}
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default BlockCardModal;
