import PersonalData from '../../models/PersonalData';
import { AccountContactType } from '../../models/ProfileData';
import { api, QueryParamKey, RecaptchaProtected } from '.';

interface ForgotPasswordSendCodeQueryPayload extends RecaptchaProtected {
  emailOrPhoneNumber: string;
}

interface ForgotPasswordVerificationResponsePayload {
  verificationToken: string;
}

interface ForgotPasswordConfirmCodeQueryPayload extends RecaptchaProtected {
  code: string;
  verificationToken: string;
}

interface ForgotPasswordChangePasswordQueryPayload extends RecaptchaProtected {
  newPassword: string;
  verificationToken: string;
}

interface ForgotPasswordChangePasswordResponsePayload {
  jwt: string;
  personalData: PersonalData;
  unverifiedContact?: AccountContactType;
}

const forgotPasswordApi = api.injectEndpoints({
  endpoints: (builder) => ({
    forgotPasswordSendCode: builder.mutation<
      ForgotPasswordVerificationResponsePayload,
      ForgotPasswordSendCodeQueryPayload
    >({
      query: ({ emailOrPhoneNumber, recaptchaToken }) => ({
        url: `/forgot-password`,
        params: {
          [QueryParamKey.recaptchaToken]: recaptchaToken,
        },
        method: 'POST',
        body: {
          emailOrPhoneNumber,
        },
      }),
    }),
    forgotPasswordConfirmCode: builder.mutation<
      ForgotPasswordVerificationResponsePayload,
      ForgotPasswordConfirmCodeQueryPayload
    >({
      query: ({ code, verificationToken, recaptchaToken }) => ({
        url: `/forgot-password/confirm`,
        method: 'POST',
        params: {
          [QueryParamKey.recaptchaToken]: recaptchaToken,
          [QueryParamKey.code]: code,
        },
        body: {
          verificationToken,
        },
      }),
    }),
    forgotPasswordChangePassword: builder.mutation<
      ForgotPasswordChangePasswordResponsePayload,
      ForgotPasswordChangePasswordQueryPayload
    >({
      query: ({ newPassword, verificationToken, recaptchaToken }) => ({
        url: `/forgot-password/change`,
        method: 'POST',
        params: {
          [QueryParamKey.recaptchaToken]: recaptchaToken,
        },
        body: {
          newPassword,
          verificationToken,
        },
      }),
    }),
  }),
});

export const {
  useForgotPasswordSendCodeMutation,
  useForgotPasswordConfirmCodeMutation,
  useForgotPasswordChangePasswordMutation,
} = forgotPasswordApi;

export default forgotPasswordApi.reducer;
