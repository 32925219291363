import { Menu, Divider, Box } from '@mantine/core';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { useTranslation } from 'react-i18next';
import UserMenuItem from './UserMenuItem';
import useAppLogout from '../../../hooks/useAppLogout';
import useRoutes from '../../../i18n/useRoutes';

const UserMenuFields = () => {
  const [logout] = useAppLogout();
  const routes = useRoutes();
  const { t } = useTranslation();

  return (
    <Box
      sx={(theme) => ({
        [theme.fn.smallerThan(theme.breakpoints.md)]: {
          margin: `0 ${theme.other.spacing(1.5)}`,
        },
      })}
    >
      <UserMenuItem icon={<AccountCircleIcon />} title={t('myProfile.subHeaderTitle')} route={routes.profile} />
      <UserMenuItem icon={<CreditCardIcon />} title={t('cardSettings.subHeaderTitle')} route={routes.cardSettings} />
      <UserMenuItem
        icon={<DateRangeIcon />}
        title={t('purchaseHistory.subHeaderTitle')}
        route={routes.purchaseHistory}
      />
      <Divider />
      <Menu.Item
        onClick={logout}
        sx={(theme) => ({
          color: theme.colors.maximaBlueDefault,
          fontSize: '1rem',
          '& .MuiSvgIcon-root': {
            color: theme.colors.maximaBlueDefault,
          },
        })}
        icon={<ExitToAppIcon />}
      >
        {t('profile.logout')}
      </Menu.Item>
    </Box>
  );
};

export default UserMenuFields;
