import { Loader } from '@mantine/core';

import Modal from '../../../../components/Modal';

interface ReceiptLoaderModalProps {
  opened: boolean;
  handleCloseModal: () => void;
}

const ReceiptLoaderModal = ({ opened, handleCloseModal }: ReceiptLoaderModalProps) => {
  return (
    <Modal
      sx={() => ({
        '& .mantine-Modal-header': {
          alignItems: 'flex-start',
        },
      })}
      opened={opened}
      onClose={handleCloseModal}
    >
      <Loader size={56} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }} />
    </Modal>
  );
};

export default ReceiptLoaderModal;
