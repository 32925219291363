import { useTranslation } from 'react-i18next';
import facebook from '../../assets/svg/social/facebook.svg';
import instagram from '../../assets/svg/social/instagram.svg';
import youtube from '../../assets/svg/social/youtube.svg';

const useFooterLinks = () => {
  const { t } = useTranslation();
  return {
    socialMediaLinks: [
      { title: t('footer.links.social.facebook.label'), href: t('footer.links.social.facebook.url'), icon: facebook },
      {
        title: t('footer.links.social.youtube.label'),
        href: t('footer.links.social.youtube.url'),
        icon: youtube,
      },
      {
        title: t('footer.links.social.instagram.label'),
        href: t('footer.links.social.instagram.url'),
        icon: instagram,
      },
    ],
    links: [
      {
        title: t('footer.links.group.1.title'),
        items: [
          { label: t('footer.links.group.1.item.1.label'), href: t('footer.links.group.1.item.1.url') },
          { label: t('footer.links.group.1.item.2.label'), href: t('footer.links.group.1.item.2.url') },
          { label: t('footer.links.group.1.item.3.label'), href: t('footer.links.group.1.item.3.url') },
          { label: t('footer.links.group.1.item.4.label'), href: t('footer.links.group.1.item.4.url') },
        ],
      },
      {
        title: t('footer.links.group.2.title'),
        items: [
          { label: t('footer.links.group.2.item.1.label'), href: t('footer.links.group.2.item.1.url') },
          { label: t('footer.links.group.2.item.2.label'), href: t('footer.links.group.2.item.2.url') },
          { label: t('footer.links.group.2.item.3.label'), href: t('footer.links.group.2.item.3.url') },
          { label: t('footer.links.group.2.item.4.label'), href: t('footer.links.group.2.item.4.url') },
        ],
      },
      {
        title: t('footer.links.group.3.title'),
        items: [
          { label: t('footer.links.group.3.item.1.label'), href: t('footer.links.group.3.item.1.url') },
          {
            label: t('footer.links.group.3.item.2.label'),
            href: t('footer.links.group.3.item.2.url'),
          },
          { label: t('footer.links.group.3.item.3.label'), href: t('footer.links.group.3.item.3.url') },
          { label: t('footer.links.group.3.item.4.label'), href: t('footer.links.group.3.item.4.url') },
        ],
      },
      {
        title: t('footer.links.group.4.title'),
        items: [
          {
            label: t('footer.links.group.4.item.1.label'),
            href: t('footer.links.group.4.item.1.url'),
          },
          { label: t('footer.links.group.4.item.2.label'), href: t('footer.links.group.4.item.2.url') },
        ],
      },
    ],
  };
};

export default useFooterLinks;
