import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { RootState } from '..';
import { setCardNumber } from '../cardSlice';
import { setIsLoggedIn, setPersonalData, setToken } from '../userSlice';

export enum QueryParamKey {
  recaptchaToken = 'recaptchaToken',
  code = 'code',
  birthdate = 'birthdate',
  dateFrom = 'dateFrom',
  dateTo = 'dateTo',
  pageSize = 'pageSize',
  page = 'page',
}

export interface RecaptchaProtected {
  recaptchaToken: string;
}

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_SERVER,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).user.token;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithLogout: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 403) {
    api.dispatch(setIsLoggedIn(false));
    api.dispatch(setToken(null));
    api.dispatch(setPersonalData(null));
    api.dispatch(setCardNumber(''));
  }
  return result;
};

export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithLogout,
  endpoints: () => ({}),
});

export default api.reducer;
