import { Box } from '@mantine/core';
import MobileHeader from './MobileHeader';
import TopBar from './TopBar';
import NavBar from './NavBar';

const Header = () => {
  return (
    <Box>
      <MobileHeader />
      <TopBar />
      <NavBar />
    </Box>
  );
};

export default Header;
