import { AccountContactType } from '../../models/ProfileData';
import PersonalData from '../../models/PersonalData';
import { api, QueryParamKey, RecaptchaProtected } from '.';

interface AuthenticateUserQueryPayload extends RecaptchaProtected {
  emailOrPhone: string;
  password: string;
}

interface AuthenticateUserResponsePayload {
  jwt: string;
  personalData: PersonalData;
  unverifiedContact?: AccountContactType;
}

interface CreateAccountResponsePayload {
  jwt: string;
  personalData: PersonalData;
  unverifiedContact?: AccountContactType;
}

interface CreateAccountQueryPayload extends RecaptchaProtected {
  password: string;
  verificationToken: string;
}

interface ChangePasswordResponsePayload {
  jwt: string;
}

interface ChangePasswordQueryPayload {
  currentPassword: string;
  newPassword: string;
  emailOrPhoneNo: string;
}

interface ValidatePasswordResponsePayload {
  isValid: boolean;
  messages: ValidationMessage[];
}

interface ValidationMessage {
  languageCode: string;
  message: string;
}

interface ValidatePasswordQueryPayload {
  password: string;
}

interface LogoutQueryPayload {
  cardNo: string;
  accountId: number;
}

interface LogoutResponsePayload {
  message: string;
}

const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    authenticateUser: builder.mutation<AuthenticateUserResponsePayload, AuthenticateUserQueryPayload>({
      query: ({ emailOrPhone, password, recaptchaToken }) => ({
        url: `/authentication/login`,
        method: 'POST',
        params: {
          [QueryParamKey.recaptchaToken]: recaptchaToken,
        },
        body: {
          emailOrPhoneNo: emailOrPhone,
          password,
        },
      }),
    }),
    createUserAccount: builder.mutation<CreateAccountResponsePayload, CreateAccountQueryPayload>({
      query: ({ password, verificationToken, recaptchaToken }) => ({
        url: `/authentication/create`,
        method: 'POST',
        params: {
          [QueryParamKey.recaptchaToken]: recaptchaToken,
        },
        body: {
          password,
          verificationToken,
        },
      }),
    }),
    changePassword: builder.mutation<ChangePasswordResponsePayload, ChangePasswordQueryPayload>({
      query: ({ currentPassword, newPassword, emailOrPhoneNo }) => ({
        url: `/authentication/password/change`,
        method: 'POST',
        body: {
          currentPassword,
          newPassword,
          emailOrPhoneNo,
        },
      }),
    }),
    validatePassword: builder.mutation<ValidatePasswordResponsePayload, ValidatePasswordQueryPayload>({
      query: ({ password }) => ({
        url: `/authentication/password/validate`,
        method: 'POST',
        body: {
          password,
        },
      }),
    }),
    logout: builder.mutation<LogoutResponsePayload, LogoutQueryPayload>({
      query: ({ cardNo, accountId }) => ({
        url: `/authentication/token/invalidate`,
        method: 'POST',
        body: {
          cardNo,
          accountId,
        },
      }),
    }),
  }),
});

export const {
  useAuthenticateUserMutation,
  useCreateUserAccountMutation,
  useChangePasswordMutation,
  useValidatePasswordMutation,
  useLogoutMutation,
} = authApi;

export default authApi.reducer;
