import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ForgotPasswordStage = 'sendCode' | 'confirmCode' | 'changePassword';

export interface ForgotPasswordState {
  stage: ForgotPasswordStage;
  verificationToken: string;
}

const initialState: ForgotPasswordState = {
  stage: 'sendCode',
  verificationToken: '',
};

export const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    setStage: (state: ForgotPasswordState, action: PayloadAction<ForgotPasswordStage>) => {
      state.stage = action.payload;
    },
    setVerificationToken: (state: ForgotPasswordState, action: PayloadAction<string>) => {
      state.verificationToken = action.payload;
    },
  },
});

export const { setStage, setVerificationToken } = forgotPasswordSlice.actions;
export default forgotPasswordSlice.reducer;
