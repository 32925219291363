import { Box } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { Tax } from '../../../store/api/purchaseHistoryApi';
import useFormatCurrency from '../../../utility/hooks/useFormatCurrency';

const defaultTaxLines = {
  vat: 20,
  noVat: 0,
  withVat: 0,
  amount: 0,
};

interface TaxLinesProps {
  data: Tax[];
}

const TaxLines = ({ data }: TaxLinesProps) => {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();

  const total = data.reduce(
    (acc, curr) => {
      acc.amount = acc.amount += Number(curr.amount);
      acc.noVat = acc.noVat += Number(curr.noVat);
      acc.withVat = acc.withVat += Number(curr.withVat);
      return acc;
    },
    { amount: 0, noVat: 0, withVat: 0 },
  );

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderBottom: '1px dashed #aeaeae',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ width: '25%' }}>{t('purchaseHistory.taxLine.VATPercent')}</Box>
          <Box sx={{ width: '25%', textAlign: 'left' }}>{t('purchaseHistory.taxLine.withoutVAT')}</Box>
          <Box sx={{ width: '25%', textAlign: 'center' }}>{t('purchaseHistory.taxLine.VAT')}</Box>
          <Box sx={{ width: '25%', textAlign: 'right' }}>{t('purchaseHistory.taxLine.withVAT')}</Box>
        </Box>
        {data.map((taxLine, i) => {
          const { vat, noVat, withVat, amount } = taxLine || defaultTaxLines;
          return (
            <Box
              key={i}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                '&:last-child': {
                  paddingBottom: '1em',
                },
              }}
            >
              <Box sx={{ width: '25%' }}>{vat}</Box>
              <Box sx={{ width: '25%', textAlign: 'left' }}>{formatCurrency(noVat)}</Box>
              <Box sx={{ width: '25%', textAlign: 'center' }}>{formatCurrency(amount)}</Box>
              <Box sx={{ fontFamily: 'ProximaNovaBold', width: '25%', textAlign: 'right' }}>
                {formatCurrency(withVat)}
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'flex-end',
          padding: `${theme.other.spacing(1.5)} 0`,
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginRight: theme.other.spacing(5),
            textAlign: 'right',
          })}
        >
          <Box>{t('purchaseHistory.taxLine.VAT')}</Box>
          <Box>{t('purchaseHistory.taxLine.withoutVAT')}</Box>
          <Box>{t('purchaseHistory.taxLine.total')}</Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            fontFamily: 'ProximaNovaBold',
            textAlign: 'right',
          }}
        >
          <Box>{formatCurrency(total.amount)}</Box>
          <Box>{formatCurrency(total.noVat)}</Box>
          <Box>{formatCurrency(total.withVat)}</Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TaxLines;
