import * as Yup from 'yup';
import { Box, Text } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { useRef, RefObject, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import TextInput from '../../../components/form/TextInput';
import Link from '../../../components/Link';
import { ONLY_NUMERICS_REGEX } from '../../../utility';

interface ConfirmCodeFormProps {
  onSubmitCode: (code: string) => Promise<void> | void;
  onResendCode: () => Promise<void> | void;
  onCancel?: () => Promise<void> | void;
  disabled?: boolean;
  loading?: boolean;
  resendCodeLoading?: boolean;
  error?: string;
  inModalMode?: boolean;
}

const ConfirmCodeForm = (props: ConfirmCodeFormProps) => {
  const { t } = useTranslation();

  const digitTwoInputRef = useRef<HTMLInputElement>(null);
  const digitThreeInputRef = useRef<HTMLInputElement>(null);
  const digitFourInputRef = useRef<HTMLInputElement>(null);
  const digitFiveInputRef = useRef<HTMLInputElement>(null);
  const digitSixInputRef = useRef<HTMLInputElement>(null);

  const form = useForm({
    schema: yupResolver(
      Yup.object().shape({
        digitOne: Yup.string().required(),
        digitTwo: Yup.string().required(),
        digitThree: Yup.string().required(),
        digitFour: Yup.string().required(),
        digitFive: Yup.string().required(),
        digitSix: Yup.string().required(),
      }),
    ),
    initialValues: {
      digitOne: '',
      digitTwo: '',
      digitThree: '',
      digitFour: '',
      digitFive: '',
      digitSix: '',
    },
  });

  // Used for looping in a way where input order is fully determined as object property order cannot be relied on
  const digitInputNamesInOrder: (keyof typeof form.values)[] = [
    'digitOne',
    'digitTwo',
    'digitThree',
    'digitFour',
    'digitFive',
    'digitSix',
  ];

  const digitInputRefMap: { [field in keyof typeof form.values]: RefObject<HTMLInputElement> | undefined } = {
    digitOne: undefined,
    digitTwo: digitTwoInputRef,
    digitThree: digitThreeInputRef,
    digitFour: digitFourInputRef,
    digitFive: digitFiveInputRef,
    digitSix: digitSixInputRef,
  };

  const focusNextElement = (field: keyof typeof form.values) => {
    const fieldIndex = digitInputNamesInOrder.findIndex((inputName) => inputName === field);
    const nextInputName = digitInputNamesInOrder[fieldIndex + 1];
    digitInputRefMap[nextInputName]?.current?.focus();
  };

  const handleDigitInputChange = (value: string, field: keyof typeof form.values) => {
    if (value.length <= 1 && (!value || ONLY_NUMERICS_REGEX.test(value))) {
      form.setFieldValue(field, value);
    }
    if (value.length === 1 && ONLY_NUMERICS_REGEX.test(value)) {
      focusNextElement(field);
    }
  };

  const handleFormSubmit = (values: typeof form.values, e: FormEvent<Element>) => {
    e.stopPropagation();
    const confirmationCode = digitInputNamesInOrder.map((inputName) => values[inputName]).join('');
    props.onSubmitCode(confirmationCode);
  };

  return (
    <Box
      sx={(theme) => ({
        maxWidth: '500px',
        width: '100%',
        '&, & form': {
          width: props.inModalMode ? '100%' : undefined,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: !props.inModalMode ? '224px' : undefined,
        },
        '& .mantine-TextInput-root': {
          width: '32px',
          '& .mantine-TextInput-input': {
            paddingLeft: theme.other.spacing(1),
            paddingRight: theme.other.spacing(1),
            textAlign: 'center',
          },
          '& .mantine-TextInput-error': {
            display: 'none',
          },
        },
        '& .mantine-Button-root': {
          marginTop: theme.other.spacing(2),
        },
        '& .mantine-Anchor-root': {
          marginTop: props.inModalMode ? 0 : theme.other.spacing(2.5),
          marginBottom: props.inModalMode ? theme.other.spacing(1) : 0,
        },
      })}
    >
      <form noValidate onSubmit={form.onSubmit(handleFormSubmit)}>
        <Box
          sx={(theme) => ({
            marginBottom: theme.other.spacing(1.5),
            display: 'flex',
            justifyContent: 'center',
            gap: theme.other.spacing(0.5),
            '& > *:nth-of-type(3)': {
              marginRight: theme.other.spacing(1.5),
            },
          })}
        >
          {digitInputNamesInOrder.map((inputName) => (
            <TextInput
              key={inputName}
              ref={digitInputRefMap[inputName]}
              name={inputName}
              value={form.values[inputName]}
              error={form.errors[inputName]}
              onChange={(e) => handleDigitInputChange(e.target.value, inputName)}
              required
              disabled={props.disabled || props.loading || props.resendCodeLoading}
            />
          ))}
        </Box>
        {props.error && (
          <Box>
            <Text
              sx={(theme) => ({
                fontFamily: 'ProximaNova',
                fontSize: '0.9rem',
                color: theme.colors.errorLight,
                textAlign: 'center',
              })}
            >
              {props.error}
            </Text>
          </Box>
        )}
        {props.inModalMode && (
          <Link
            align="center"
            component="p"
            onClick={!(props.resendCodeLoading || props.loading) ? props.onResendCode : undefined}
          >
            {t('forgotPassword.confirmCode.resendCode')}
          </Link>
        )}
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignSelf: props.inModalMode ? 'flex-end' : undefined,
            gap: theme.other.spacing(1),
            width: !props.inModalMode ? '100%' : undefined,
            justifyContent: !props.inModalMode ? 'center' : undefined,

            '&': props.inModalMode
              ? {
                  '& > button': {
                    flex: '1 0 auto',
                    [theme.fn.smallerThan(450)]: {
                      '&:first-of-type': {
                        margin: 0,
                      },
                    },
                  },
                  [theme.fn.smallerThan(450)]: {
                    width: '100%',
                    flex: 'auto',
                    flexDirection: 'column-reverse',
                    gap: theme.other.spacing(2),
                  },
                }
              : undefined,
          })}
        >
          {props.inModalMode && props.onCancel && (
            <Button
              variant="outline"
              onClick={props.onCancel}
              disabled={props.disabled || props.loading || props.resendCodeLoading}
            >
              {t('cardSettings.cancel')}
            </Button>
          )}
          <Button type="submit" loading={props.loading} disabled={props.disabled || props.resendCodeLoading}>
            {t('forgotPassword.confirmCode.confirmCode')}
          </Button>
        </Box>
      </form>
      {!props.inModalMode && (
        <Link
          align="center"
          component="p"
          onClick={!(props.resendCodeLoading || props.loading) ? props.onResendCode : undefined}
        >
          {t('forgotPassword.confirmCode.resendCode')}
        </Link>
      )}
    </Box>
  );
};

export default ConfirmCodeForm;
