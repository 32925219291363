import { useTranslation } from 'react-i18next';
import { GridAlignment } from '@mui/x-data-grid';
import DownloadButton from './DownloadButton';

const useHistoryTableFields = () => {
  const { t } = useTranslation();
  return [
    {
      field: 'paymentDate',
      headerName: t('purchaseHistory.table.paymentDate'),
    },
    {
      field: 'caption',
      headerName: t('purchaseHistory.table.caption'),
    },
    {
      field: 'sumCents',
      headerName: t('purchaseHistory.table.sumCents'),
    },
    {
      field: 'discountCents',
      headerName: t('purchaseHistory.table.discountCents'),
    },
    {
      field: 'maximaMoneyCents',
      headerName: t('purchaseHistory.table.maximaMoneyCents'),
    },
    {
      field: 'receiptDownload',
      headerName: t('purchaseHistory.table.receipt'),
      headerAlign: 'center' as GridAlignment,
      align: 'center' as GridAlignment,
      renderCell: (params: { row: { transactionId: number; hasEReceipt: boolean } }) => (
        <DownloadButton row={params.row} />
      ),
    },
  ];
};

export default useHistoryTableFields;
