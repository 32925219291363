import { useTranslation } from 'react-i18next';
import { MATCH_ROOT_ROUTE_PATH } from '../utility';

const routes = {
  purchaseHistory: '/',
  profile: '/profile',
  login: '/login',
  forgotPassword: '/forgot-password',
  conditions: '/conditions',
  cardSettings: '/card-settings',
  rules: '/rules',
  privacyPolicy: '/privacy-policy',
  faq: '/faq',
  registerCard: '/register-card',
  createAccount: '/create-account',
  strongAuthRegister: '/strong-auth-register',
  profileLanding: '/profile-landing',
  profileLandingLink: '/l/:voucher',
};

const useRoutes = (): typeof routes => {
  const {
    i18n: { language },
  } = useTranslation();

  const prefixedRoutes: { [key: string]: string } = {};

  Object.entries(routes).forEach(([key, value]) => {
    const path = value.replace(MATCH_ROOT_ROUTE_PATH, '');
    prefixedRoutes[key] = `/${language}${path}`;
  });

  return prefixedRoutes as typeof routes;
};

export default useRoutes;
