import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import PersonalData from '../models/PersonalData';
import { AccountContactType } from '../models/ProfileData';
import { getMaximaAuthTokenFromStorage } from '../utility';

export type AccountContactVerificationStage = 'sendCode' | 'verifyCode';
export type AccountContactTypeAction = 'add' | 'change' | 'verify';

export interface PromptContact {
  type: AccountContactType;
  action: AccountContactTypeAction;
}

export interface UserState {
  isLoggedIn: boolean;
  token: string | null;
  personalData: PersonalData | null;
  contactVerificationStage: AccountContactVerificationStage;
  promptContact: PromptContact | null;
  isBirthdayOffersModalOpen: boolean;
}

const initialState: UserState = {
  isLoggedIn: Boolean(getMaximaAuthTokenFromStorage()),
  token: getMaximaAuthTokenFromStorage(),
  personalData: null,
  promptContact: null,
  contactVerificationStage: 'sendCode',
  isBirthdayOffersModalOpen: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsLoggedIn: (state: UserState, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
    setToken: (state: UserState, action: PayloadAction<string | null>) => {
      state.token = action.payload;
    },
    setPersonalData: (state: UserState, action: PayloadAction<PersonalData | null>) => {
      state.personalData = action.payload;
    },
    setContactVerificationStage: (state: UserState, action: PayloadAction<AccountContactVerificationStage>) => {
      state.contactVerificationStage = action.payload;
    },
    setPromptContact: (state: UserState, action: PayloadAction<PromptContact | null>) => {
      state.promptContact = action.payload;
    },
    setIsBirthdayOffersModalOpen: (state: UserState, action: PayloadAction<boolean>) => {
      state.isBirthdayOffersModalOpen = action.payload;
    },
  },
});

export const {
  setIsLoggedIn,
  setToken,
  setPersonalData,
  setContactVerificationStage,
  setPromptContact,
  setIsBirthdayOffersModalOpen,
} = userSlice.actions;
export default userSlice.reducer;
