import { Box } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import Connector from './connector';
import Step, { StepState } from './step';

interface ProgressProps {
  currentStep: number;
}

const getState = function (step: number, currentStep: number): StepState {
  if (step == currentStep) return StepState.Active;
  if (step < currentStep) return StepState.Complete;

  return StepState.Inactive;
};

const Progress = (props: ProgressProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          [theme.fn.smallerThan(960)]: {
            display: 'none',
          },
        })}
      >
        <Connector isComplete={props.currentStep >= 1} isHorizontal={false}></Connector>
        <Connector isComplete={props.currentStep >= 1} isHorizontal={false}></Connector>
        <Step
          title={t('strongAuthRegister.progress.first.stepName')}
          number={1}
          state={getState(1, props.currentStep)}
        />
        <Connector isComplete={props.currentStep >= 2} isHorizontal={false}></Connector>
        <Step
          title={t('strongAuthRegister.progress.second.stepName')}
          number={2}
          state={getState(2, props.currentStep)}
        />
        <Connector isComplete={props.currentStep > 2} isHorizontal={false}></Connector>
        <Step
          title={t('strongAuthRegister.progress.third.stepName')}
          number={3}
          state={getState(3, props.currentStep)}
        />
        <Connector isComplete={props.currentStep > 3} isHorizontal={false}></Connector>
        <Step
          title={t('strongAuthRegister.progress.fourth.stepName')}
          number={4}
          state={getState(4, props.currentStep)}
        />
        <Connector isComplete={props.currentStep >= 4} isHorizontal={false}></Connector>
        <Connector isComplete={props.currentStep >= 4} isHorizontal={false}></Connector>
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
          width: '100%',
          height: '50px',
          [theme.fn.largerThan(960)]: {
            display: 'none',
          },
        })}
      >
        <Step title="" number={1} state={getState(1, props.currentStep)} />
        {/* <HorizontalConnector isComplete={props.currentStep >= 2} isHorizontal={true}></HorizontalConnector> */}
        <Step title="" number={2} state={getState(2, props.currentStep)} />
        {/* <HorizontalConnector isComplete={props.currentStep > 2} isHorizontal={true}></HorizontalConnector> */}
        <Step title="" number={3} state={getState(3, props.currentStep)} />
        {/* <HorizontalConnector isComplete={props.currentStep > 3} isHorizontal={true}></HorizontalConnector> */}
        <Step title="" number={4} state={getState(4, props.currentStep)} />
      </Box>
    </>
  );
};

export default Progress;
