import { Box, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import Button from '../../../components/Button';
import LoyaltyExitModal from '../modals/LoyaltyExitModal';

const LoyaltyExit = () => {
  const { t } = useTranslation();

  return (
    <LoyaltyExitModal>
      {({ onOpen }) => (
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            gap: theme.other.spacing(2),
            '& .mantine-Button-root': {
              backgroundColor: theme.colors.errorDefault,
              '&:hover': {
                backgroundColor: theme.colors.errorDark,
              },
            },
            cursor: 'pointer',
          })}
          onClick={onOpen}
        >
          <Button>
            <CloseIcon />
          </Button>
          <Text>{t('cardSettings.loyaltyExit')}</Text>
        </Box>
      )}
    </LoyaltyExitModal>
  );
};

export default LoyaltyExit;
