import { useCallback, useEffect } from 'react';
import * as Yup from 'yup';
import { Box } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TextInput from '../../../components/form/TextInput';
import Button from '../../../components/Button';
import Link from '../../../components/Link';
import { isValidEstonianMobilePhoneOrEmail } from '../../../utility';

interface ForgotPasswordSendCodeFormValues {
  emailOrPhone: string;
}

interface SendCodeFormProps {
  onSubmit: (emailOrPhoneNumber: string) => Promise<void> | void;
  loading?: boolean;
  disabled?: boolean;
  error?: string;
}

const SendCodeForm = (props: SendCodeFormProps) => {
  const { t } = useTranslation();

  const form = useForm<ForgotPasswordSendCodeFormValues>({
    schema: yupResolver(
      Yup.object().shape({
        emailOrPhone: Yup.string()
          .required(t('forgotPassword.sendCode.emailOrPhoneError'))
          .test('isPhoneOrEmail', t('forgotPassword.sendCode.invalidPhoneOrEmail'), isValidEstonianMobilePhoneOrEmail),
      }),
    ),
    initialValues: {
      emailOrPhone: '',
    },
  });

  // eslint-disable-next-line
  const setFieldError = useCallback(form.setFieldError, []);

  useEffect(() => {
    if (props.error) {
      setFieldError('emailOrPhone', props.error);
    }
  }, [props.error, setFieldError]);

  const handleFormSubmit = (values: ForgotPasswordSendCodeFormValues) => {
    if (values.emailOrPhone) {
      props.onSubmit(values.emailOrPhone);
    }
  };

  return (
    <Box
      sx={(theme) => ({
        maxWidth: '500px',
        width: '100%',
        '&, & form': {
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          [theme.fn.largerThan(500)]: {
            alignItems: 'center',
          },
        },
        '& .mantine-TextInput-root': {
          marginBottom: theme.other.spacing(1.5),
          width: '100%',
        },
        '& .mantine-Button-root': {
          marginTop: theme.other.spacing(1),
          marginBottom: theme.other.spacing(2),
        },
      })}
    >
      <form noValidate onSubmit={form.onSubmit(handleFormSubmit)}>
        <TextInput
          name="emailOrPhone"
          label={t('forgotPassword.sendCode.emailOrPhone')}
          required
          {...form.getInputProps('emailOrPhone')}
          disabled={props.loading || props.disabled}
        />
        <Button type="submit" loading={props.loading}>
          {t('forgotPassword.sendCode.sendCode')}
        </Button>
      </form>
      <Link
        align="center"
        component={RouterLink}
        to="/"
        sx={(theme) => ({
          marginTop: theme.other.spacing(1.5),
          marginBottom: theme.other.spacing(1.5),
        })}
      >
        {t('forgotPassword.sendCode.backToLogin')}
      </Link>
    </Box>
  );
};

export default SendCodeForm;
