import { Grid } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import TextInput from '../../../../components/form/TextInput';

const CustomerNameRow = (props: { firstName?: string; surname?: string }) => {
  const { t } = useTranslation();
  return (
    <Grid
      gutter="lg"
      sx={(theme) => ({
        marginTop: theme.other.spacing(2),
        alignItems: 'flex-start',
      })}
    >
      <Grid.Col sm={12} md={6}>
        <TextInput value={props.firstName || ''} label={t('registerCard.saveProfileData.firstName')} disabled={true} />
      </Grid.Col>
      <Grid.Col sm={12} md={6}>
        <TextInput value={props.surname || ''} label={t('registerCard.saveProfileData.lastName')} disabled={true} />
      </Grid.Col>
    </Grid>
  );
};

export default CustomerNameRow;
