import { UpdateProfileQueryPayload } from './profileApi';
import PersonalData from '../../models/PersonalData';
import { api } from '.';

interface DokobitApiCreateResponse {
  sessionToken: string;
}

interface DokobitUpdateProfilePayload extends UpdateProfileQueryPayload {
  personalDataVerificationToken: string;
}

export interface DokobitAuthenticateResponse {
  personalDataVerificationToken: string;
  payload: {
    name: string;
    surname: string;
    personalCode: string;
    dateOfBirth: {
      day: string;
      month: string;
      year: string;
    };
  };
}

const dokobitApi = api.injectEndpoints({
  endpoints: (builder) => {
    let authenticate;
    if (process.env.REACT_APP_STAGE === 'development' || process.env.REACT_APP_STAGE === 'test') {
      authenticate = builder.mutation<
        DokobitAuthenticateResponse,
        { returnToken: string; mockPersonalCode?: string | null | undefined }
      >({
        query: ({ returnToken, mockPersonalCode }) => ({
          url: '/dokobit/authenticate',
          method: 'post',
          params: {
            returnToken: returnToken,
            mockPersonalCode: mockPersonalCode,
          },
        }),
      });
    } else {
      authenticate = builder.mutation<DokobitAuthenticateResponse, { returnToken: string }>({
        query: ({ returnToken }) => ({
          url: '/dokobit/authenticate',
          method: 'post',
          params: {
            returnToken: returnToken,
          },
        }),
      });
    }

    return {
      dokobitSessionCreate: builder.mutation<DokobitApiCreateResponse, unknown>({
        query: () => ({
          url: `/dokobit/create`,
        }),
      }),
      authenticate: authenticate,
      updateProfile: builder.mutation<PersonalData, DokobitUpdateProfilePayload>({
        query: ({ personalDataVerificationToken, profileData, consents }) => ({
          url: `/dokobit/updateProfile`,
          method: 'put',
          body: {
            personalDataVerificationToken,
            profileData,
            consents,
          },
        }),
      }),
    };
  },
});

export const { useDokobitSessionCreateMutation, useAuthenticateMutation, useUpdateProfileMutation } = dokobitApi;

export default dokobitApi.reducer;
