import { api, QueryParamKey } from '.';

export interface GetPurchaseHistoryPayload {
  accountId: number;
  dateFrom: Date;
  dateTo: Date;
  pageSize: number;
  page: number;
}

export interface GetPurchaseHistoryResponsePayload {
  payments: Payment[];
  pageNo: number;
  pageSize: number;
  totalRecords: number;
}

interface GetPurchaseReceiptPayload {
  accountId: number;
  transactionId: number;
}

export interface GetPurchaseReceiptResponsePayload {
  Receipt: {
    Discounts: Discount;
    Mp: DataLine;
    Payments: Payments;
    Taxes: Taxes;
    PdiList: PdiList;
    Texts: Texts;
    ReceiptTotal: string;
    Footer: string;
    Header: string;
    Lines: { Line: Line[] };
    vatId: string;
    nr: string;
    invnr: string;
    fiscalNo: string;
    date: string;
    cashier: string;
    cashNo: string;
    TotalReceiptDiscount: number;
  };
}

interface Texts {
  AgeVerificationText: string;
  GiftTexts: string;
}

export type DataLine = Record<string, { name: string; amount: number }>;

export interface PdiList {
  Pdi: Discount[];
}

interface Discount {
  Coupon: string;
  Discount: number;
  DiscountTitle: string;
  DiscountType: string;
  IsDiscountByLoyaltyCard: string;
  IsGroupDiscount: boolean;
  IsPercentDiscount: boolean;
  LineNr: string;
  SchemeId: string;
}

export interface Payments {
  payment: {
    amount: number;
    id: string;
    type: string;
    bcnum?: string;
    info?: {
      amount: number;
      CardNo: string;
    };
  }[];
  total: string;
  SumCash: string;
  SumNonCash: string;
  CashChange: number;
}

export interface Taxes {
  Tax: Tax[];
}

export interface Tax {
  amount: number;
  noVat: number;
  vat: number;
  vatLetter: string;
  withVat: number;
}

export interface Line {
  vatLetter?: string;
  no: string;
  name: string;
  quantity?: string;
  measure?: string;
  price: number;
  Disc?: Discount;
  petName: string;
  petBottleCount: number;
  petBottlePrice: number;
}

interface Discount {
  id: string;
  discount: number;
  name: string;
  vatLetter: string;
}

interface Payment {
  transactionId: number;
  paymentType: number;
  caption: { en: string; lt: string; et: string; ru: string };
  posId: number;
  paymentDate: string;
  cardNo: string;
  sumCents: number;
  discountCents: number;
  maximaMoneyCents: number;
  hasEReceipt: boolean;
}

const purchaseHistoryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPurchaseHistory: builder.mutation<GetPurchaseHistoryResponsePayload, GetPurchaseHistoryPayload>({
      query: ({ accountId, dateFrom, dateTo, pageSize, page }) => ({
        url: `/purchase-history/${accountId}`,
        params: {
          [QueryParamKey.dateFrom]: dateFrom?.toISOString(),
          [QueryParamKey.dateTo]: dateTo?.toISOString(),
          [QueryParamKey.pageSize]: pageSize,
          [QueryParamKey.page]: page,
        },
      }),
    }),
    getPurchaseReceipt: builder.mutation<GetPurchaseReceiptResponsePayload, GetPurchaseReceiptPayload>({
      query: ({ accountId, transactionId }) => ({
        url: `/purchase-history/${accountId}/receipt/${transactionId}`,
      }),
    }),
  }),
});

export const { useGetPurchaseHistoryMutation, useGetPurchaseReceiptMutation } = purchaseHistoryApi;

export default purchaseHistoryApi.reducer;
