import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

export default () => {
  const { voucher } = useParams();
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/${i18n.language}/profile-landing?voucher=${voucher}`);
  }, [voucher, navigate, i18n]);

  return null;
};
