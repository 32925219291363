import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ['et', 'en', 'ru'],
    fallbackLng: 'et',
    preload: ['et', 'en', 'ru'],
    backend: {
      loadPath: '/locales/{{lng}}.json',
    },
    detection: {
      order: ['path', 'localStorage'],
      lookupFromPathIndex: 0,
    },
    interpolation: { escapeValue: false },
    joinArrays: '\n',
  });

i18n.on('languageChanged', (lang) => {
  const cookiebot = document.getElementById('CookieBot');
  if (cookiebot && window?.CookieControl?.Cookie) {
    cookiebot.setAttribute('data-culture', lang.toUpperCase());
    window.CookieControl.Cookie();
  }
});

declare global {
  interface Window {
    CookieControl: { Cookie: () => unknown };
  }
}

export default i18n;
