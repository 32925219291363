import { Grid, Col, Title, Image, Anchor, Box } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import useFooterLinks from './useFooterLinks';

const Footer = () => {
  const { t } = useTranslation();
  const { links, socialMediaLinks } = useFooterLinks();

  return (
    <Box sx={{ marginTop: 'auto', flexShrink: 0 }}>
      <Grid
        sx={(theme) => ({
          backgroundColor: theme.colors.bgBlueDark,
          color: theme.colors.brandWhite,
          padding: '24px 8%',
          justifyContent: 'space-between',
          margin: 0,
        })}
      >
        {links.map(({ title, items }) => {
          return (
            <Col
              key={title}
              lg={2}
              md={4}
              sm={6}
              xs={12}
              sx={{
                display: 'flex',
                fontSize: '1rem',
                fontWeight: 'lighter',
                flexDirection: 'column',
              }}
            >
              <Title
                order={5}
                sx={{
                  fontWeight: 700,
                }}
              >
                {title}
              </Title>
              {items.map(({ href, label }) => (
                <Anchor
                  key={label}
                  href={href}
                  title={label}
                  target="_blank"
                  rel="noreferrer"
                  color="dimmed"
                  underline={false}
                  sx={(theme) => ({
                    color: theme.colors.brandWhite,
                    fontSize: '1rem',
                    fontWeight: 'normal',
                    '&:hover': {
                      color: theme.colors.grayLightest,
                    },
                  })}
                >
                  {label}
                </Anchor>
              ))}
            </Col>
          );
        })}
        <Col
          lg={2}
          md={4}
          sm={6}
          xs={12}
          sx={{
            display: 'flex',
            fontSize: '1rem',
            fontWeight: 'lighter',
            flexDirection: 'column',
          }}
        >
          <Title order={5}>{t('footer.links.social.title')}</Title>
          <Grid
            sx={{
              marginTop: 0,
            }}
          >
            {socialMediaLinks.map(({ href, title, icon }) => (
              <Anchor
                key={title}
                href={href}
                target="_blank"
                rel="noreferrer"
                color="dimmed"
                underline={false}
                sx={(theme) => ({
                  color: theme.colors.brandWhite,
                  '&:hover': {
                    color: theme.colors.grayLightest,
                  },
                  margin: '10px',
                })}
              >
                <Image height={38} width={38} src={icon} alt={title} />
              </Anchor>
            ))}
          </Grid>
        </Col>
      </Grid>
      <Grid
        sx={(theme) => ({
          backgroundColor: theme.colors.bgBlueDark,
          color: theme.colors.brandWhite,
          padding: '24px 8%',
          fontSize: '1rem',
          fontWeight: 'lighter',
          margin: 0,
        })}
      >
        <Col
          sx={{
            justifyContent: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
          }}
          xs={12}
          sm={6}
        >
          <Box sx={{ fontFamily: 'ProximaNova' }}> {t('footer.clientSupport')}</Box>
          <Box sx={{ fontFamily: 'ProximaNovaSemibold' }}> {t('footer.clientSupportContacts')}</Box>
        </Col>
        <Col
          sx={(theme) => ({
            justifyContent: 'flex-end',
            display: 'flex',
            alignItems: 'flex-end',
            fontFamily: 'ProximaNovaSemibold',
            [theme.fn.smallerThan(theme.breakpoints.sm)]: {
              justifyContent: 'flex-start',
            },
          })}
          xs={12}
          sm={6}
        >
          {t('footer.allRightsReserved', { year: new Date().getFullYear().toString() })}
        </Col>
      </Grid>
    </Box>
  );
};

export default Footer;
