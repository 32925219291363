import { Box, Image, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import ProductLines from './ProductLines';
import TaxLines from './TaxLines';
import PaymentLines from './PaymentLines';
import DiscountLines from './DiscountLines';
import CardLines from './CardLines';
import ReceiptFooter from './ReceiptFooter';
import ReceiptLoaderModal from './modals/ReceiptLoaderModal';
import ReceiptErrorModal from './modals/ReceiptErrorModal';
import Modal from '../../../components/Modal';
import receiptLogo from '../../../assets/svg/logos/receipt_logo.svg';
import { GetPurchaseReceiptResponsePayload } from '../../../store/api/purchaseHistoryApi';
interface ReceiptModalProps {
  opened: boolean;
  handleCloseModal: () => void;
  data?: GetPurchaseReceiptResponsePayload['Receipt'];
  isLoading: boolean;
}

const ReceiptModal = ({ data, opened, handleCloseModal, isLoading }: ReceiptModalProps) => {
  const { t } = useTranslation();

  if (isLoading) {
    return <ReceiptLoaderModal opened={opened} handleCloseModal={handleCloseModal} />;
  }

  if (!data) {
    return <ReceiptErrorModal opened={opened} handleCloseModal={handleCloseModal} />;
  }

  const { Lines, Mp, PdiList, Payments, Taxes, invnr = '', TotalReceiptDiscount, Texts, Header } = data;
  const headerParts = Header.split('  ');
  const address = headerParts[headerParts.length - 1].trim();

  return (
    <Modal
      sx={(theme) => ({
        '& .mantine-Modal-title div > div': {
          display: 'none',
        },
        '& .mantine-Modal-header': {
          justifyContent: 'space-between',
          marginLeft: '25%',
          [theme.fn.smallerThan(theme.breakpoints.xs)]: {
            marginLeft: '0',
          },
        },
        '& .mantine-Modal-modal': {
          width: '500px',
        },
        '& .mantine-Modal-body': {
          display: 'flex',
          justifyContent: 'center',
        },
        '& .mantine-Modal-close': {
          borderRadius: '50%',
          padding: theme.other.spacing(0.5),
          height: '36px',
          minHeight: '36px',
          width: '36px',
          minWidth: '36px',
          '&:not(:disabled):active': {
            transform: 'translateY(0)',
          },
          '& svg': {
            width: '20px',
            height: '20px',
          },
        },
      })}
      opened={opened}
      onClose={handleCloseModal}
      title={<Image width={180} src={receiptLogo} alt="Maxima" />}
    >
      <Box
        sx={{
          flex: '1',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Text
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box>
              <span>Maxima Eesti OÜ </span>
              <span>Reg. Nr. 10765896</span>
            </Box>
            <span>{address}</span>
          </Text>
        </Box>
        <Box
          sx={{
            marginTop: '3em',
            alignItems: 'left',
          }}
        >
          <Text>{t('purchaseHistory.receiptNr', { invnr })}</Text>
        </Box>
        {Lines && <ProductLines data={Lines.Line} />}
        {Taxes && <TaxLines data={Taxes.Tax} />}
        {Payments && <PaymentLines data={Payments} />}
        {Mp && <CardLines data={Mp} />}
        {PdiList && <DiscountLines data={PdiList} totalDiscounts={TotalReceiptDiscount} />}
        <ReceiptFooter data={data} />
        <Box sx={{ borderTop: '1px dashed #aeaeae', padding: '1em 0', whiteSpace: 'break-spaces' }}>
          {Texts && Texts.GiftTexts}
        </Box>
      </Box>
    </Modal>
  );
};

export default ReceiptModal;
