import { TablePagination } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { GetPurchaseHistoryResponsePayload } from '../../../store/api/purchaseHistoryApi';

interface PaginationProps {
  purchaseData: GetPurchaseHistoryResponsePayload;
  page: number;
}

const Pagination = ({ purchaseData, page }: PaginationProps) => {
  const apiRef = useGridApiContext();
  const { t } = useTranslation();

  return (
    <TablePagination
      component="div"
      sx={{ border: 'none' }}
      count={purchaseData?.totalRecords || 25}
      rowsPerPage={25}
      page={page}
      onPageChange={(event, value) => apiRef.current.setPage(value)}
      labelDisplayedRows={({ from, to, count }) => {
        return t('purchaseHistory.table.footer', { from, to, count }) || '';
      }}
      labelRowsPerPage=""
      rowsPerPageOptions={[]}
      getItemAriaLabel={(type) => t(`purchaseHistory.pagination.tooltip.${type}`) || ''}
    />
  );
};

export default Pagination;
