import { Box } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../hooks/store';
import SubHeaderWhenAuthenticated from '../../../components/SubHeader/SubHeaderWhenAuthenticated';
import SaveProfileDataForm from '../../RegisterCard/SaveProfileDataForm';
import { ProfileView } from '..';
import PromptContactModal from '../PromptContactModal';
import BirthdayOffersBanner from '../../BirthdayOffer/BirthdayOffersBanner';
import featureFlags from '../../../features';

interface ChangeProfileProps {
  onChangeView: (view: ProfileView) => void;
}

const ChangeProfile = (props: ChangeProfileProps) => {
  const { t } = useTranslation();

  const personalData = useAppSelector((state) => state.user.personalData);
  const cardNumber = useAppSelector((state) => state.card.cardNumber);

  const handleChangeToPasswordView = () => {
    props.onChangeView('changePassword');
  };

  return (
    <Box className="container">
      <SubHeaderWhenAuthenticated title={t('myProfile.subHeaderTitle')} />
      <Box
        className="content"
        sx={(theme) => ({
          paddingTop: theme.other.spacing(4),
          paddingBottom: theme.other.spacing(6),
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'flex-start',
          flexWrap: 'wrap',
          minHeight: '55vh',
          [theme.other.smallerThanHeight(900)]: {
            paddingTop: theme.other.spacing(6),
          },
          [theme.other.largerThanHeight(1100)]: {
            paddingTop: theme.other.spacing(15),
          },
          [theme.fn.smallerThan(1000)]: {
            paddingTop: theme.other.spacing(6),
          },
          [theme.fn.smallerThan(1600)]: {
            justifyContent: 'center',
          },
        })}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: '960px',
          }}
        >
          <SaveProfileDataForm
            personalData={personalData}
            cardNumber={cardNumber}
            onChangeToPasswordView={handleChangeToPasswordView}
          />
          <PromptContactModal profileData={personalData?.profileData} />
        </Box>
        {featureFlags.birthdayOffersEnabled ? (
          <Box
            sx={(theme) => ({
              '&': {
                [theme.fn.largerThan(1600)]: {
                  width: '320px',
                  marginLeft: '62px',
                },
                [theme.fn.smallerThan(1600)]: {
                  marginTop: '62px',
                  width: '100%',
                  maxWidth: '960px',
                },
              },
            })}
          >
            <BirthdayOffersBanner />
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default ChangeProfile;
