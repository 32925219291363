import { Grid } from '@mantine/core';
import TopBarLink from './TopBarLink';
import UserMenu from '../UserMenu';
import useHeaderLinks from '../useHeaderLinks';
import { useAppSelector } from '../../../hooks/store';
import LanguageChanger from '../../LanguageChanger';

const TopBar = () => {
  const { topBarLinks } = useHeaderLinks();
  const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn);

  return (
    <Grid
      align="center"
      sx={(theme) => ({
        backgroundColor: theme.colors.bgBlueDark,
        margin: 0,
        color: theme.colors.brandWhite,
        padding: '8px 8%',
        fontSize: '1rem',
        justifyContent: 'flex-end',
        fontWeight: 'bolder',
        [theme.fn.smallerThan(theme.breakpoints.md)]: {
          display: 'none',
        },
      })}
    >
      {topBarLinks.map(({ href, title }) => (
        <TopBarLink key={href} href={href} title={title} />
      ))}
      <LanguageChanger />
      {isLoggedIn && <UserMenu />}
    </Grid>
  );
};

export default TopBar;
