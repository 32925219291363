import { Box } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { Line } from '../../../store/api/purchaseHistoryApi';
import useFormatCurrency from '../../../utility/hooks/useFormatCurrency';

interface ProductLinesProps {
  data: Line[];
}

const ProductLines = ({ data }: ProductLinesProps) => {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();
  const receiptLines = data.map(
    ({ name, quantity = '', measure = '', price, petName, Disc, petBottleCount, petBottlePrice }) => {
      return (
        <Box
          key={name}
          sx={{
            display: 'flex',
            flexDirection: 'column',

            '&:not(:last-child)': {
              borderBottom: '1px dashed #aeaeae',
              marginBottom: '4px',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                width: '60%',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {name}
            </Box>
            <Box
              sx={{
                width: '20%',
                textAlign: 'right',
              }}
            >{`${quantity} ${measure} `}</Box>
            <Box sx={{ fontFamily: 'ProximaNovaBold', width: '20%', textAlign: 'right', wordBreak: 'initial' }}>
              {formatCurrency(price)}
            </Box>
          </Box>
          <Box>
            {Disc && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  textAlign: 'right',
                }}
              >
                <Box sx={{ opacity: '0.6' }}>{Disc.name || t('purchaseHistory.discount.default')}</Box>
                <Box
                  sx={() => ({
                    fontFamily: 'ProximaNovaBold',
                    wordBreak: 'initial',
                    opacity: '0.6',
                  })}
                >
                  {`-${formatCurrency(Disc.discount)}`}
                </Box>
              </Box>
            )}
            {petName !== undefined && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Box>{`${petName}`}</Box>
                <Box sx={{ fontFamily: 'ProximaNovaBold' }}>{`${petBottleCount} ${t(
                  'purchaseHistory.productLines.pcs',
                )}`}</Box>
                <Box sx={{ fontFamily: 'ProximaNovaBold' }}>{formatCurrency(petBottlePrice)}</Box>
              </Box>
            )}
          </Box>
        </Box>
      );
    },
  );

  return (
    <Box
      sx={{
        padding: '1em 0',
        borderBottom: '1px dashed #aeaeae',
        margin: '0em 0 1em 0',
      }}
    >
      {receiptLines}
    </Box>
  );
};

export default ProductLines;
