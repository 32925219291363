/* eslint-disable react/prop-types */
import { Document, Page, Text, View, Image, Font } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import TaxLines from './TaxLines';
import PaymentLines from './PaymentLines';
import CardLines from './CardLines';
import ReceiptFooter from './ReceiptFooter';
import ProductLines from './ProductLines';
import DiscountLines from './DiscountLines';
import { GetPurchaseReceiptResponsePayload } from '../../../store/api/purchaseHistoryApi';
import logo from '../../../assets/svg/logos/receipt_logo.png';
import proximaRegular from '../../../assets/fonts/Proxima_Nova_Regular.otf';
import proximaBold from '../../../assets/fonts/Proxima_NovaBold.otf';

Font.register({
  family: 'ProximaNova',
  fonts: [{ src: proximaRegular }, { src: proximaBold, fontWeight: 'bold' }],
});

const ReceiptPDF = (props: { data: GetPurchaseReceiptResponsePayload['Receipt'] }) => {
  const { Lines, Mp, PdiList, Payments, Taxes, invnr = '', TotalReceiptDiscount, Texts, Header } = props.data;
  const headerParts = Header.split('  ');
  const address = headerParts[headerParts.length - 1].trim();
  const { t } = useTranslation();
  return (
    <Document>
      <Page style={{ paddingTop: 30, fontSize: 10, fontFamily: 'ProximaNova' }}>
        <View style={{ width: '50%', marginLeft: 'auto', marginRight: 'auto' }}>
          <View>
            <Image style={{ width: 170, height: 39, marginLeft: 'auto', marginRight: 'auto' }} src={logo} />
            <View style={{ marginTop: 10, width: '60%', marginLeft: 'auto', marginRight: 'auto' }}>
              <Text>Maxima Eesti OÜ Reg. Nr. 10765896</Text>
              <Text>{address}</Text>
            </View>
          </View>
          <Text style={{ paddingTop: 30, paddingBottom: 10, fontSize: 10 }}>
            {t('purchaseHistory.receiptNr', { invnr })}
          </Text>

          {Lines && <ProductLines data={Lines.Line} />}
          {Taxes && <TaxLines data={Taxes.Tax} />}
          {Payments && <PaymentLines data={Payments} />}
          {Mp && <CardLines data={Mp} />}
          {PdiList && <DiscountLines data={PdiList} totalDiscounts={TotalReceiptDiscount} />}
          <ReceiptFooter data={props.data} />

          <View
            style={{
              marginTop: 10,
              marginBottom: 10,
              width: '100%',
              borderTop: '1px dashed #aeaeae',
            }}
          >
            <Text>{Texts && Texts.GiftTexts}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default ReceiptPDF;
