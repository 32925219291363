import { Box, Title, Image } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Button from '../components/Button';
import notFoundVariantOne from '../assets/svg/404/not_found_1.svg';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <Box
      className="content centered"
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={(theme) => ({
          maxWidth: '1200px',
          height: '100%',
          width: '100%',
          display: 'flex',
          gap: theme.other.spacing(4),
          [theme.fn.smallerThan(600)]: {
            flexDirection: 'column-reverse',
            alignItems: 'center',
            paddingBottom: theme.other.spacing(5),
            gap: theme.other.spacing(2),
          },
        })}
      >
        <Box
          sx={(theme) => ({
            flex: '1 0 auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: theme.other.spacing(3),
            [theme.fn.smallerThan(960)]: {
              gap: theme.other.spacing(1),
            },
            [theme.fn.smallerThan(600)]: {
              width: '100%',
            },
          })}
        >
          <Title
            order={2}
            sx={(theme) => ({
              color: theme.colors.brandOrange,
              fontSize: '3rem',
              fontFamily: 'ProximaNovaBold',
              letterSpacing: '0.02em',
              [theme.fn.smallerThan(960)]: {
                fontSize: '2.8rem',
              },
            })}
          >
            404
          </Title>
          <Title
            order={3}
            sx={(theme) => ({
              marginBottom: theme.other.spacing(1.75),
              fontSize: '1.4rem',
              fontFamily: 'ProximaNova',
              letterSpacing: '0.02em',
              [theme.fn.smallerThan(960)]: {
                fontSize: '1.2rem',
              },
              [theme.fn.smallerThan(768)]: {
                fontSize: '1rem',
              },
            })}
          >
            {t('notfound.title')}
          </Title>
          <Button fullWidth component={Link} to="/">
            {t('notfound.backToMainPage')}
          </Button>
        </Box>
        <Box
          sx={(theme) => ({
            width: '50%',
            flex: '2 0 auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: theme.other.spacing(4),
            paddingBottom: theme.other.spacing(4),
            [theme.fn.smallerThan(600)]: {
              width: '100%',
              paddingTop: theme.other.spacing(2),
              paddingBottom: theme.other.spacing(2),
            },
          })}
        >
          <Image width="100%" src={notFoundVariantOne} alt={t('notfound.title')} />
        </Box>
      </Box>
    </Box>
  );
};
export default NotFound;
