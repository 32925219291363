import { Anchor, Box, Image, Text, Title } from '@mantine/core';
import { useTranslation, Trans } from 'react-i18next';
import offersImage from '../../../assets/svg/loyalty_offers.svg';
import Button from '../../../components/Button';

const IntroductionPartial = (props: { handleOffersDeclined: () => void; nextStep: () => void }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box
        sx={() => ({
          '&': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          },
        })}
      >
        <Image
          src={offersImage}
          alt="Offers image"
          sx={(theme) => ({
            '&': {
              width: '180px',
              aspectRatio: '1',
              [theme.fn.largerThan(840)]: {
                width: '370px',
                paddingTop: theme.other.spacing(4),
                paddingBottom: theme.other.spacing(4),
              },
            },
          })}
        />
      </Box>
      <Box
        sx={(theme) => ({
          '&': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            [theme.fn.largerThan(840)]: {
              paddingLeft: theme.other.spacing(3),
              paddingRight: theme.other.spacing(3),
            },
          },
        })}
      >
        <Title
          sx={(theme) => ({
            '&': {
              textAlign: 'center',
              fontWeight: 700,
              [theme.fn.smallerThan(840)]: {
                fontSize: '24px',
              },
            },
          })}
        >
          {t('birthdayOffers.modalQuestion')}
        </Title>
      </Box>
      <Box
        sx={(theme) => ({
          '&': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            paddingLeft: theme.other.spacing(3),
            paddingRight: theme.other.spacing(3),
          },
        })}
      >
        <Text
          sx={(theme) => ({
            '&': {
              marginTop: theme.other.spacing(2),
              textAlign: 'center',
              color: '#636E72',
            },
          })}
        >
          <Trans i18nKey="birthdayOffers.modalDescription">
            Your special day is soon here, and we&apos;d love to make personalised offers for you three days before and
            after your birthday. Read more <Anchor href={t('birthdayOffers.modalAdditionalInfoUrl')}>here</Anchor>.
          </Trans>
        </Text>
      </Box>
      <Box
        sx={(theme) => ({
          '&': {
            marginTop: theme.other.spacing(5),
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            gap: theme.other.spacing(2),
            [theme.fn.smallerThan(840)]: {
              flexDirection: 'column-reverse',
              gap: theme.other.spacing(1),
            },
          },
          '& button': {
            width: '100%',
            [theme.fn.largerThan(840)]: {
              width: '50%',
            },
          },
        })}
      >
        <Button variant="outline" onClick={props.handleOffersDeclined}>
          {t('birthdayOffers.modalDeclineButton')}
        </Button>
        <Button onClick={props.nextStep}>{t('birthdayOffers.modalAgreeButton')}</Button>
      </Box>
    </>
  );
};

export default IntroductionPartial;
