import { Grid } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import TextInput from '../../../components/form/TextInput';

interface CardNumberRowProps {
  cardNumber?: string;
}

const CardNumberRow = ({ cardNumber }: CardNumberRowProps) => {
  const { t } = useTranslation();

  if (cardNumber) {
    return (
      <Grid gutter="lg">
        <Grid.Col sm={6}>
          <TextInput label={t('landing.loginForm.cardNo')} value={cardNumber} disabled />
        </Grid.Col>
      </Grid>
    );
  }

  return null;
};

export default CardNumberRow;
