import { Box, Image, Skeleton } from '@mantine/core';
import { useEffect, useRef, useState } from 'react';
import { getI18n } from 'react-i18next';
import offersImage from '../../../assets/svg/loyalty_offers.svg';
import { useAuthenticateMutation, useDokobitSessionCreateMutation } from '../../../store/api/dokobitApi';

const DokobitPartial = (props: { nextStep: () => void }) => {
  const [isLoading, setIsLoading] = useState(true);
  const divRef = useRef<HTMLDivElement | null>(null);
  const [, sessionCreateResponse] = useDokobitSessionCreateMutation({ fixedCacheKey: 'dokobit-session-create' });
  const [triggerAuthenticate] = useAuthenticateMutation({ fixedCacheKey: 'dokobit-authentication' });

  useEffect(() => {
    if (sessionCreateResponse.isSuccess && sessionCreateResponse.data) {
      const options: DokobitOptions = {
        sessionToken: sessionCreateResponse.data.sessionToken,
        callback: async function (returnToken) {
          props.nextStep();
          if (process.env.REACT_APP_STAGE === 'development' || process.env.REACT_APP_STAGE === 'test')
            triggerAuthenticate({
              returnToken: returnToken,
              mockPersonalCode: prompt('Insert mock personal code'),
            });
          else triggerAuthenticate({ returnToken: returnToken });
        },
        locale: getI18n().resolvedLanguage as DokobitOptions['locale'],
        primaryColor: '#195096',
        container: '#Dokobit-identity-container',
      };
      new DokobitIdentity(options).init();
    }
  }, [sessionCreateResponse, props, triggerAuthenticate]);

  useEffect(() => {
    // Dokobit draws its content by itself so we can't hook into its changes directly
    // to hide the skeleton loader. This observes raw changes to Dokobit div
    const div = divRef.current;

    if (div) {
      const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.type === 'childList') {
            setIsLoading(false);
          }
        }
      });
      observer.observe(div, { attributes: true, childList: true, subtree: true });

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return (
    <Box
      sx={() => ({
        '&': {
          flexGrow: 1,
        },
      })}
    >
      <Box
        sx={() => ({
          '&': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          },
        })}
      >
        <Image
          src={offersImage}
          alt="Birthday cake image"
          sx={(theme) => ({
            '&': {
              width: '140px',
              aspectRatio: '1',
              [theme.fn.largerThan(840)]: {
                width: '270px',
                paddingTop: theme.other.spacing(4),
                paddingBottom: theme.other.spacing(4),
              },
            },
          })}
        />
      </Box>
      <Box
        sx={(theme) => ({
          '&': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            [theme.fn.largerThan(840)]: {
              paddingLeft: theme.other.spacing(3),
              paddingRight: theme.other.spacing(3),
            },
          },
        })}
      >
        <Box
          sx={() => ({
            '&': {
              display: 'grid',
            },
            '&>div': {
              gridColumn: '1',
              gridRow: '1',
            },
          })}
        >
          {isLoading ? (
            <Box
              sx={() => ({
                '&': {
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  paddingBottom: '8px',
                },
              })}
            >
              <Skeleton
                height={26}
                width={350}
                radius={8}
                sx={() => ({
                  '&': {
                    marginTop: '38px',
                    marginBottom: '34px',
                  },
                })}
              />
              <Skeleton height={170} width={454} radius={8} />
            </Box>
          ) : null}
          <div id="Dokobit-identity-container" ref={divRef}></div>
        </Box>
      </Box>
    </Box>
  );
};

export default DokobitPartial;
