import { ChangeEvent, useCallback, useEffect } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { toast } from 'react-toastify';
import Button from '../../../components/Button';
import TextInput from '../../../components/form/TextInput';
import Link from '../../../components/Link';
import { ONLY_NUMERICS_REGEX } from '../../../utility';
import useRoutes from '../../../i18n/useRoutes';
import { useAppDispatch } from '../../../hooks/store';
import { setCardAccountStage, setCardNumber, setObscuredEmail } from '../../../store/cardSlice';
import { ApiError, ErrorCode } from '../../../models/ApiError';
import RecaptchaAction from '../../../models/RecaptchaAction';
import { useRegisterCardAndCheckProfileDataExistenceMutation } from '../../../store/api/cardApi';

interface RegisterCardFormValues {
  cardNumber: string;
}

const RegisterCardForm = () => {
  const { t } = useTranslation();
  const routes = useRoutes();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const form = useForm<RegisterCardFormValues>({
    schema: yupResolver(
      Yup.object().shape({
        cardNumber: Yup.string().required(t('landing.loginForm.cardNoRequiredError')),
      }),
    ),
    initialValues: {
      cardNumber: '',
    },
  });

  const [registerCard, queryState] = useRegisterCardAndCheckProfileDataExistenceMutation();

  // eslint-disable-next-line
  const setFieldError = useCallback(form.setFieldError, []);

  const getCheckCardErrorMessage = useCallback(
    (error: ApiError) => {
      const errorCode = error.data?.message;
      if (error.status === 403) {
        return t('api.sessionExpiredError');
      }
      if (errorCode === ErrorCode.CardNotFoundOrInInvalidState) {
        return t('landing.loginForm.cardNotFoundError');
      }
      if (errorCode === ErrorCode.CardInvalidState) {
        return t('landing.loginForm.cardInvalidStateError');
      }
      if (errorCode === ErrorCode.AccountDoesNotExist) {
        return t('landing.loginForm.accountDoesNotExistError');
      }
      if (errorCode === ErrorCode.AccountAlreadyExists) {
        return t('landing.loginForm.accountAlreadyExists');
      }
      if (errorCode === ErrorCode.RecaptchaError) {
        return t('api.recaptchaError');
      }
      return t('api.unknownError');
    },
    [t],
  );

  useEffect(() => {
    if (queryState.isSuccess) {
      dispatch(setCardNumber(form.values.cardNumber));
      dispatch(setObscuredEmail(queryState.data?.email || ''));
      if (queryState.data?.profileDataExists) {
        dispatch(setCardAccountStage('verifyEmail'));
        navigate(routes.createAccount);
      } else {
        navigate(routes.registerCard);
      }
    }
  }, [
    queryState.isSuccess,
    queryState.data,
    form.values,
    dispatch,
    navigate,
    routes.registerCard,
    routes.createAccount,
  ]);

  useEffect(() => {
    if (queryState.isError && queryState.error) {
      const error = queryState.error as ApiError;
      const errorCode = error.data?.message;
      const errorMessage = getCheckCardErrorMessage(error);
      toast(errorMessage, { type: 'error' });
      if (errorCode === ErrorCode.CardNotFoundOrInInvalidState) {
        setFieldError('cardNumber', t('landing.loginForm.cardNotFoundError'));
      }
    }
  }, [queryState.isError, queryState.error, getCheckCardErrorMessage, setFieldError, t]);

  const handleCardNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!value || ONLY_NUMERICS_REGEX.test(value)) {
      form.setFieldValue('cardNumber', value);
    }
  };

  const handleFormSubmit = useCallback(
    async (values: RegisterCardFormValues) => {
      if (executeRecaptcha) {
        const token = await executeRecaptcha(RecaptchaAction.CheckCard);
        registerCard({ cardNumber: values.cardNumber, recaptchaToken: token });
      }
    },
    [executeRecaptcha, registerCard],
  );

  return (
    <Box
      sx={(theme) => ({
        '&, & form': {
          display: 'flex',
          flexDirection: 'column',
        },
        '& .mantine-TextInput-root': {
          marginBottom: theme.other.spacing(1.5),
        },
        '& .mantine-Checkbox-root': {
          marginTop: theme.other.spacing(1.5),
          marginBottom: theme.other.spacing(2),
        },
        '& .mantine-Button-root': {
          marginTop: theme.other.spacing(1),
          marginBottom: theme.other.spacing(2),
        },
      })}
    >
      <form noValidate onSubmit={form.onSubmit(handleFormSubmit)}>
        <TextInput
          name="cardNumber"
          label={t('landing.loginForm.cardNo')}
          placeholder={t('landing.loginForm.cardNo')}
          error={form.errors.cardNumber}
          value={form.values.cardNumber}
          onChange={handleCardNumberChange}
          required
          disabled={queryState.isLoading}
        />
        <Button type="submit" loading={queryState.isLoading}>
          {t('landing.loginForm.register')}
        </Button>
      </form>
      <Link
        align="center"
        href="https://www.maxima.ee/aitah"
        target="_blank"
        sx={(theme) => ({
          marginTop: theme.other.spacing(1.5),
          marginBottom: theme.other.spacing(1.5),
        })}
      >
        {t('landing.loginForm.terms')}
      </Link>
    </Box>
  );
};

export default RegisterCardForm;
