import { useEffect } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { Box } from '@mantine/core';
import { ToastContainer } from 'react-toastify';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { ApmRoutes } from '@elastic/apm-rum-react';
import { useAppSelector } from './hooks/store';
import useRoutes from './i18n/useRoutes';
import Login from './views/Login';
import Profile from './views/Profile';
import NotFound from './views/NotFound';
import ForgotPassword from './views/ForgotPassword';
import Header from './components/Header';
import Footer from './components/Footer';
import PurchaseHistory from './views/PurchaseHistory';
import CardSettings from './views/CardSettings';
import RegisterCard from './views/RegisterCard';
import CreateAccount from './views/CreateAccount';
import Container from './components/Container';
import LoadingOverlay from './components/LoadingOverlay';
import useFetchPersonalData from './hooks/useFetchPersonalData';
import { RECAPTCHA_KEY } from './config';
import useScrollToTopOnPathChange from './utility/hooks/useScrollToTopOnPathChange';
import BirthdayConsentModal from './views/BirthdayOffer/BirthdayConsentModal';
import StrongAuthRegister from './views/StrongAuthRegister';
import ProfileLanding from './views/profileLanding';
import ProfileLandingRedirector from './views/profileLanding/redirector';
import featureFlags from './features';

const App = () => {
  useScrollToTopOnPathChange();
  const routes = useRoutes();
  const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn);
  const cardNumber = useAppSelector((state) => state.card.cardNumber);
  const unverifiedEmail = useAppSelector((state) => state.card.unverifiedEmail);
  const obscuredEmail = useAppSelector((state) => state.card.obscuredEmail);
  const strongAuthRegisterTempToken = useAppSelector((state) => state.strongAuthRegister.tempToken);
  const { showLoading } = useFetchPersonalData();

  const {
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = process.env.REACT_APP_DOKOBIT_LIBRARY as string;
    script.async = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <>
      <LoadingOverlay visible={showLoading} />
      <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY} language={language} useRecaptchaNet>
        <Box sx={{ overflowY: 'auto', display: 'flex', flexDirection: 'column', minHeight: '100%' }}>
          <Header />
          {featureFlags.birthdayOffersEnabled ? <BirthdayConsentModal /> : <></>}
          <Container>
            <ApmRoutes>
              <Route path="/" element={<Navigate to={routes.purchaseHistory} />} />
              <Route path={routes.profile} element={!isLoggedIn ? <Navigate to={routes.login} /> : <Profile />} />
              <Route path={routes.login} element={isLoggedIn ? <Navigate to={routes.purchaseHistory} /> : <Login />} />
              <Route
                path={routes.forgotPassword}
                element={isLoggedIn ? <Navigate to={routes.profile} /> : <ForgotPassword />}
              />
              <Route
                path={routes.profileLanding}
                element={isLoggedIn ? <Navigate to={routes.profile} /> : <ProfileLanding />}
              />
              <Route path={routes.profileLandingLink} element={<ProfileLandingRedirector />} />
              <Route
                path={routes.strongAuthRegister}
                element={
                  isLoggedIn && !strongAuthRegisterTempToken ? (
                    <Navigate to={routes.profile} />
                  ) : !strongAuthRegisterTempToken ? (
                    <Navigate to={routes.login} />
                  ) : (
                    <StrongAuthRegister />
                  )
                }
              />
              <Route
                path={routes.purchaseHistory}
                element={!isLoggedIn ? <Navigate to={routes.login} /> : <PurchaseHistory />}
              />
              <Route
                path={routes.cardSettings}
                element={!isLoggedIn ? <Navigate to={routes.login} /> : <CardSettings />}
              />
              <Route
                path={routes.createAccount}
                element={!(unverifiedEmail || obscuredEmail) ? <Navigate to={routes.login} /> : <CreateAccount />}
              />
              <Route
                path={routes.registerCard}
                element={!cardNumber ? <Navigate to={routes.login} /> : <RegisterCard />}
              />
              <Route path="*" element={<NotFound />} />
            </ApmRoutes>
          </Container>
          <Footer />
          <ToastContainer position="bottom-left" hideProgressBar closeOnClick={false} autoClose={15000} />
        </Box>
      </GoogleReCaptchaProvider>
    </>
  );
};

export default App;
