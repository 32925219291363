import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Box, Title } from '@mantine/core';
import SendCodeForm from './SendCodeForm';
import SubHeader from '../../../components/SubHeader/SubHeader';
import { useAppDispatch } from '../../../hooks/store';
import { setStage, setVerificationToken } from '../../../store/forgotPasswordSlice';
import { ApiError, ErrorCode } from '../../../models/ApiError';
import RecaptchaAction from '../../../models/RecaptchaAction';
import { useForgotPasswordSendCodeMutation } from '../../../store/api/forgotPasswordApi';

const SendCode = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [serverValidationError, setServerValidationError] = useState('');
  const [sendCode, queryState] = useForgotPasswordSendCodeMutation();

  const getSendCodeErrorMessage = useCallback(
    (error: ApiError) => {
      const errorCode = error.data?.message;
      if (error.status === 403) {
        return t('api.sessionExpiredError');
      }
      if (errorCode === ErrorCode.RecaptchaError) {
        return t('api.recaptchaError');
      }
      return t('api.unknownError');
    },
    [t],
  );

  useEffect(() => {
    if (queryState.isSuccess) {
      dispatch(setVerificationToken(queryState.data.verificationToken));
      dispatch(setStage('confirmCode'));
      toast(t('forgotPassword.sendCode.passwordSendCodeSuccess'), { type: 'success' });
    }
  }, [queryState.isSuccess, queryState.data, dispatch, t]);

  useEffect(() => {
    if (queryState.isError) {
      const error = queryState.error as ApiError;
      if (error.status === 400) {
        setServerValidationError(t('landing.loginForm.emailInvalidError'));
      }
      const errorMessage = getSendCodeErrorMessage(error);
      toast(errorMessage, { type: 'error' });
    } else {
      setServerValidationError('');
    }
  }, [queryState.isError, queryState.error, getSendCodeErrorMessage, t]);

  const handleSendCode = useCallback(
    async (emailOrPhoneNumber: string) => {
      if (executeRecaptcha) {
        const recaptchaToken = await executeRecaptcha(RecaptchaAction.ForgotPasswordSendCode);
        sendCode({ emailOrPhoneNumber, recaptchaToken });
      }
    },
    [executeRecaptcha, sendCode],
  );

  return (
    <Box className="container">
      <SubHeader title={t('forgotPassword.sendCode.subHeaderTitle')} />
      <Box
        className="content"
        sx={(theme) => ({
          paddingTop: theme.other.spacing(10),
          paddingBottom: theme.other.spacing(6),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: '55vh',
          maxHeight: '600px',
          [theme.other.smallerThanHeight(900)]: {
            paddingTop: theme.other.spacing(6),
          },
          [theme.other.largerThanHeight(1100)]: {
            paddingTop: theme.other.spacing(15),
          },
        })}
      >
        <Box
          sx={(theme) => ({
            maxWidth: '800px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: theme.other.spacing(4),
          })}
        >
          <Title
            order={2}
            sx={(theme) => ({
              fontSize: '1.2rem',
              fontFamily: 'ProximaNova',
              letterSpacing: '0.02em',
              textAlign: 'center',
              color: theme.colors.brandGray,
              [theme.fn.smallerThan(960)]: {
                fontSize: '1rem',
              },
            })}
          >
            {t('forgotPassword.sendCode.formTitle')}
          </Title>
          <SendCodeForm onSubmit={handleSendCode} loading={queryState.isLoading} error={serverValidationError} />
        </Box>
      </Box>
    </Box>
  );
};

export default SendCode;
