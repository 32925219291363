import { Title, Text, Box } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { ChangeEvent, useCallback, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Button from '../../../components/Button';
import PhoneNumberInput from '../../../components/form/PhoneNumberInput';
import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { ApiError, ErrorCode } from '../../../models/ApiError';
import RecaptchaAction from '../../../models/RecaptchaAction';
import { useStrongAuthRegisterSavePhoneMutation } from '../../../store/api/strongAuthRegisterApi';
import { checkIfHasPhone, isValidEstonianMobilePhoneOrEmail } from '../../../utility';
import { decodeTempToken, phoneEntered } from '../../../store/strongAuthRegisterSlice';

interface PhoneEntryFormValues {
  phone: string | null | undefined;
}

const PhoneEntry = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [savePhone, savePhoneQueryState] = useStrongAuthRegisterSavePhoneMutation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const tempToken = useAppSelector((state) => state.strongAuthRegister.tempToken)!;
  const tokenData = decodeTempToken(tempToken);

  const form = useForm<PhoneEntryFormValues>({
    schema: yupResolver(
      Yup.object().shape({
        phone: Yup.string()
          .ensure()
          .required()
          .test('empty-field', t('strongAuthRegister.phoneEntryStep.phoneMissing'), checkIfHasPhone)
          .test('phone-valid', t('strongAuthRegister.phoneEntryStep.phoneInvalid'), isValidEstonianMobilePhoneOrEmail),
      }),
    ),
    initialValues: {
      phone: tokenData.phone ?? '',
    },
  });

  const getErrorMessage = useCallback(
    (error: ApiError) => {
      if (error) {
        const errorCode = error.data?.message;
        if (error.status === 403) {
          return t('api.sessionExpiredError');
        }
        if (errorCode === ErrorCode.SendingCodeFailed) {
          return t('createAccount.sendCode.sendingCodeFailed');
        }
        if (errorCode === ErrorCode.EmailOrPhoneCannotBeUsed) {
          return t('strongAuthRegister.phoneEntryStep.phoneCannotBeUsed');
        }
        if (errorCode === ErrorCode.RecaptchaError) {
          return t('api.recaptchaError');
        }
      }

      return t('api.unknownError');
    },
    [t],
  );

  useEffect(() => {
    if (savePhoneQueryState.isError && savePhoneQueryState.error) {
      const errorMessage = getErrorMessage(savePhoneQueryState.error as ApiError);
      toast(errorMessage, { type: 'error' });
    }
  }, [savePhoneQueryState.isError, savePhoneQueryState.error, getErrorMessage, t]);

  useEffect(() => {
    if (savePhoneQueryState.isSuccess) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dispatch(phoneEntered(savePhoneQueryState.data.tempToken!));
    }
  }, [savePhoneQueryState.isSuccess, dispatch, form.values.phone, savePhoneQueryState.data?.tempToken]);

  const handleFormSubmit = useCallback(
    async (values: PhoneEntryFormValues) => {
      if (form.validate().hasErrors) return;

      const recaptchaToken = await executeRecaptcha?.(RecaptchaAction.StrongAuthRegisterSavePhone);
      savePhone({ phone: values.phone || null, tempToken: tempToken || '', recaptchaToken: recaptchaToken });
    },
    [form, executeRecaptcha, savePhone, tempToken],
  );

  const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    form.setFieldValue('phone', value);
    if (form.errors.email) {
      form.clearErrors();
    }
  };

  return (
    <Box
      sx={(theme) => ({
        width: '60% !important',
        [theme.fn.smallerThan(960)]: {
          width: '100% !important',
        },
      })}
    >
      <Title
        sx={(theme) => ({
          color: theme.colors.brandGray,
          fontFamily: 'ProximaNovaBold',
          fontSize: '1.25rem',
          fontWeight: 700,
          letterSpacing: '0.01em',
          [theme.fn.smallerThan(800)]: {
            fontSize: '2.2rem',
          },
          [theme.fn.smallerThan(600)]: {
            fontSize: '1.6rem',
          },
        })}
      >
        {t('strongAuthRegister.phoneEntryStep.title')}
      </Title>
      <Text
        sx={(theme) => ({
          '&': {
            marginTop: theme.other.spacing(2),
            color: '#828282',
          },
        })}
      >
        <Text>{t('strongAuthRegister.phoneEntryStep.description')}</Text>
      </Text>
      <form onSubmit={form.onSubmit(handleFormSubmit)}>
        <PhoneNumberInput
          name="phone"
          type="tel"
          required
          label={t('strongAuthRegister.phoneEntryStep.phone')}
          onChange={handlePhoneChange}
          error={form.errors.phone}
          value={form.values.phone || undefined}
          // disabled={props.loading || props.disabled}
        />
        <Button type="submit">{t('strongAuthRegister.continue')}</Button>
      </form>
    </Box>
  );
};

export default PhoneEntry;
