import { Box } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';

const PurchaseHistorySubFooter = () => {
  const { t } = useTranslation();

  return (
    <Box
      className="subfooter"
      sx={(theme) => ({
        paddingTop: theme.other.spacing(2),
        paddingBottom: theme.other.spacing(2),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '110px',
        [theme.fn.smallerThan(600)]: {
          paddingTop: theme.other.spacing(4),
          paddingBottom: theme.other.spacing(4),
          justifyContent: 'center',
        },
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: theme.other.spacing(1),
          '& .mantine-Button-outline.mantine-Button-root': {
            borderColor: 'rgba(255, 255, 255, 0.5)',
            '&:not(:hover, .activeLink)': {
              color: theme.colors.brandWhite,
            },
          },
          [theme.fn.smallerThan(500)]: {
            flexDirection: 'column',
            width: '100%',
          },
        })}
      >
        <Button variant="outline" component="a" target="_blank" href={t('purchaseHistory.loyaltyCardRulesUrl')}>
          {t('purchaseHistory.loyaltyCardRules')}
        </Button>
        <Button variant="outline" component="a" target="_blank" href={t('purchaseHistory.loyaltyCardPrivacyPolicyUrl')}>
          {t('purchaseHistory.loyaltyCardPrivacyPolicy')}
        </Button>
        <Button variant="outline" component="a" target="_blank" href={t('purchaseHistory.faqUrl')}>
          {t('purchaseHistory.faq')}
        </Button>
      </Box>
    </Box>
  );
};

export default PurchaseHistorySubFooter;
