import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { MantineProvider } from '@mantine/core';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { init as initApm } from '@elastic/apm-rum';
import CookieBot from './components/CookieBot';
import { store } from './store';
import App from './App';
import maximaTheme from './theme/maxima';
import './i18n';
import './index.scss';
import 'react-toastify/dist/ReactToastify.min.css';

if (process.env.REACT_APP_APM_SERVER) {
  initApm({
    serviceName: 'Loyalty-Web-Frontend',
    serverUrl: process.env.REACT_APP_APM_SERVER,
    environment: process.env.REACT_APP_STAGE,
    distributedTracingOrigins: [process.env.REACT_APP_API_SERVER as string],
  });
}

if (process.env.REACT_APP_RUN_MOCK) {
  import('./mocks/browser').then(({ default: worker }) => {
    worker.start({ onUnhandledRequest: 'bypass' });
  });
}

const container = document.getElementById('root');
const root = createRoot(container as Element);

root.render(
  <>
    <CookieBot />
    <React.StrictMode>
      <MantineProvider withGlobalStyles withNormalizeCSS theme={maximaTheme}>
        <Provider store={store}>
          <BrowserRouter>
            <QueryParamProvider adapter={ReactRouter6Adapter}>
              <App />
            </QueryParamProvider>
          </BrowserRouter>
        </Provider>
      </MantineProvider>
    </React.StrictMode>
  </>,
);
