import { Anchor, Menu } from '@mantine/core';

interface TopBarLinkProps {
  href: string;
  title: string;
}

const TopNavLink = ({ href, title }: TopBarLinkProps) => {
  return (
    <Menu.Item key={title}>
      <Anchor
        href={href}
        target="_blank"
        rel="noreferrer"
        title={title}
        color="dimmed"
        underline={false}
        sx={(theme) => ({
          color: theme.colors.brandGray,
          margin: `0 ${theme.other.spacing(1.5)}`,
        })}
      >
        {title}
      </Anchor>
    </Menu.Item>
  );
};

export default TopNavLink;
