import { Box, Image, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import loyaltyOffersImage from '../../assets/svg/loyalty_offers.svg';
import Button from '../../components/Button';
import { setIsBirthdayOffersModalOpen } from '../../store/userSlice';
import { useAppSelector } from '../../hooks/store';

const BirthdayOffersBanner = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const openModal = useCallback(() => {
    dispatch(setIsBirthdayOffersModalOpen(true));
  }, [dispatch]);
  const personalCode = useAppSelector((state) => state.user.personalData?.profileData?.personalCode);
  const agreeToReceiveBirthdayOffers = useAppSelector(
    (state) => state.user.personalData?.consents?.agreeToReceiveBirthdayOffers,
  );

  return !personalCode && !agreeToReceiveBirthdayOffers ? (
    <Box
      sx={(theme) => ({
        '&': {
          backgroundColor: '#fff',
          width: '100%',
          display: 'flex',
          borderRadius: '24px',
          flexWrap: 'wrap',
          paddingTop: theme.other.spacing(4),
          paddingBottom: theme.other.spacing(4),
          rowGap: '22px',
        },
      })}
    >
      <Box
        sx={(theme) => ({
          '&': {
            flex: '1 1 170px',
            display: 'flex',
            justifyContent: 'center',
            [theme.fn.smallerThan(810)]: {
              width: '100%',
            },
          },
        })}
      >
        <Image
          src={loyaltyOffersImage}
          alt="Loyalty offers image"
          sx={() => ({
            '&': {
              width: '170px',
              aspectRatio: '1',
            },
          })}
        />
      </Box>

      <Box
        sx={() => ({
          '&': {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 3,
            textAlign: 'center',
            paddingLeft: '24px',
            paddingRight: '24px',
            justifyContent: 'center',
            alignItems: 'flex-start',
          },
        })}
      >
        <Text
          sx={(theme) => ({
            '&': {
              fontSize: '24px',
              fontWeight: 700,
              color: theme.colors.darkGrey,
            },
          })}
        >
          {t('birthdayOffers.banner.title')}
        </Text>
        <Text
          sx={(theme) => ({
            '&': {
              fontSize: '16px',
              fontWeight: 400,
              color: theme.colors.midGrey,
            },
          })}
        >
          {t('birthdayOffers.banner.subtitle')}
        </Text>
        <Box
          sx={() => ({
            '&': {
              marginTop: '26px',
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              width: '100%',
            },
          })}
        >
          <Box
            sx={() => ({
              '&': {
                flex: '1 1 auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              },
            })}
          >
            <Button onClick={openModal}>{t('birthdayOffers.banner.buttonText')}</Button>
          </Box>
          <Box
            sx={() => ({
              '&': {
                flex: '1000 0 180px',
              },
            })}
          ></Box>
        </Box>
      </Box>
    </Box>
  ) : null;
};

export default BirthdayOffersBanner;
