import { Title, Text, Image, Box } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import linkLoyaltyCard from '../../../assets/svg/link_loyalty_card.svg';
import { useAppDispatch } from '../../../hooks/store';
import { plasticCardTypeChosen } from '../../../store/strongAuthRegisterSlice';

const PlasticCardSelection = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <Box
      sx={(theme) => ({
        width: '60% !important',
        [theme.fn.smallerThan(960)]: {
          marginTop: '12vw',
          width: '100% !important',
        },
      })}
    >
      <Image
        src={linkLoyaltyCard}
        alt="Link loyalty card"
        sx={(theme) => ({
          position: 'absolute',
          right: '-63px',
          top: '-49px',
          height: '288px',
          width: '288px',
          [theme.fn.smallerThan(960)]: {
            right: '10px',
            top: '-20px',
            width: 'clamp(80px, 18vw, 18vw)',
          },
        })}
      />
      <>
        <Title
          sx={(theme) => ({
            color: theme.colors.brandGray,
            fontFamily: 'ProximaNovaBold',
            fontSize: '1.25rem',
            fontWeight: 700,
            letterSpacing: '0.01em',
            [theme.fn.smallerThan(800)]: {
              fontSize: '2.2rem',
            },
            [theme.fn.smallerThan(600)]: {
              fontSize: '1.6rem',
            },
          })}
        >
          {t('strongAuthRegister.cardSelectionStep.title')}
        </Title>
        <Text
          sx={(theme) => ({
            '&': {
              marginTop: theme.other.spacing(2),
              color: '#828282',
            },
          })}
        >
          <Text>{t('strongAuthRegister.cardSelectionStep.description')}</Text>
        </Text>
        <form noValidate>
          <Button type="submit" onClick={() => dispatch(plasticCardTypeChosen(true))}>
            {t('strongAuthRegister.cardSelectionStep.continue')}
          </Button>
          <Button type="submit" variant="outline" onClick={() => dispatch(plasticCardTypeChosen(false))}>
            {t('strongAuthRegister.cardSelectionStep.cancel')}
          </Button>
        </form>
      </>
    </Box>
  );
};

export default PlasticCardSelection;
