import { ChangeEvent, FormEvent, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Box } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import Button from '../../../../components/Button';
import { ESTONIAN_MOBILE_NUMBER_REGEX } from '../../../../utility';
import PhoneNumberInput from '../../../../components/form/PhoneNumberInput';

interface EnterNewPhoneNumberFormProps {
  onSubmit: (phoneNumber: string) => void;
  onCancel: () => void;
  loading?: boolean;
  disabled?: boolean;
  error?: string;
}

const EnterNewPhoneNumberForm = (props: EnterNewPhoneNumberFormProps) => {
  const { t } = useTranslation();

  const form = useForm({
    schema: yupResolver(
      Yup.object().shape({
        phone: Yup.string()
          .matches(ESTONIAN_MOBILE_NUMBER_REGEX, t('landing.loginForm.mobileInvalidError'))
          .required(t('myProfile.changeContact.phoneNumberRequiredError')),
      }),
    ),
    initialValues: {
      phone: '',
    },
  });

  // eslint-disable-next-line
  const setFieldError = useCallback(form.setFieldError, []);

  useEffect(() => {
    if (props.error) {
      setFieldError('phone', props.error);
    }
  }, [props.error, setFieldError]);

  const handlePhoneNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue('phone', e.target.value);
  };

  const handleFormSubmit = (values: typeof form.values, e: FormEvent<Element>) => {
    e.stopPropagation();
    props.onSubmit(values.phone);
  };

  return (
    <Box
      sx={(theme) => ({
        maxWidth: '500px',
        width: '100%',
        '&, & form': {
          display: 'flex',
          flexDirection: 'column',
        },
        '& .mantine-TextInput-root': {
          marginBottom: theme.other.spacing(3),
        },
      })}
    >
      <form onSubmit={form.onSubmit(handleFormSubmit)}>
        <PhoneNumberInput
          name="phone"
          label={t('myProfile.changeContact.newPhoneNumber')}
          error={form.errors.phone}
          value={form.values.phone}
          onChange={handlePhoneNumberChange}
          required
          disabled={props.loading || props.disabled}
        />
        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'flex-end',
            gap: theme.other.spacing(1),
            [theme.fn.smallerThan(450)]: {
              flexDirection: 'column-reverse',
              gap: theme.other.spacing(2),
            },
          })}
        >
          <Button variant="outline" onClick={props.onCancel} disabled={props.loading || props.disabled}>
            {t('myProfile.prompt.noThanks')}
          </Button>
          <Button type="submit" loading={props.loading} disabled={props.disabled}>
            {t('createAccount.sendCode.sendCode')}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default EnterNewPhoneNumberForm;
