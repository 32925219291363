import { Loader } from '@mantine/core';
import { IconButton } from '@mui/material';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import { Fragment } from 'react';
import { useGetPurchaseReceiptMutation } from '../../../store/api/purchaseHistoryApi';
import { useAppSelector } from '../../../hooks/store';
import { ReactComponent as PdfIcon } from '../../../assets/svg/receipt_pdf_logo.svg';
import ReceiptPDF from '../ReceiptPDF/ReceiptPDF';

const DownloadButton = (props: { row: { transactionId: number; hasEReceipt: boolean } }) => {
  const { transactionId, hasEReceipt } = props.row;
  const [triggerGetReceipt, { isLoading: receiptLoading }] = useGetPurchaseReceiptMutation();
  const accountId = useAppSelector((state) => state.user.personalData?.accountId);

  return (
    <Fragment>
      {hasEReceipt && (
        <IconButton
          disableFocusRipple
          disableRipple
          onClick={async (event) => {
            event.stopPropagation();
            if (!accountId || !hasEReceipt || receiptLoading) return;
            const receiptData = await triggerGetReceipt({ accountId, transactionId }).unwrap();
            const blob = await pdf(<ReceiptPDF data={receiptData.Receipt} />).toBlob();
            saveAs(blob, 'Receipt.pdf');
          }}
          sx={() => ({
            '#background': {
              fill: 'transparent',
            },
            '.text': {
              fill: '#004990',
            },
            '&:hover .text': {
              fill: 'white',
              transition: 'fill 0.2s',
            },
            '&:hover #background': {
              fill: '#004990',
              transition: 'fill 0.2s',
            },
          })}
        >
          {receiptLoading ? <Loader></Loader> : <PdfIcon height={25} width={20} />}
        </IconButton>
      )}
    </Fragment>
  );
};

export default DownloadButton;
