import { Box, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { NavLink, useMatch } from 'react-router-dom';
import useRoutes from '../../i18n/useRoutes';
import Button from '../Button';

interface SubHeaderWhenAuthenticatedProps {
  title: string;
}

const SubHeaderWhenAuthenticated = (props: SubHeaderWhenAuthenticatedProps) => {
  const { t } = useTranslation();
  const routes = useRoutes();
  const matchProfileUrl = useMatch(routes.profile);
  const matchCardSettingsUrl = useMatch(routes.cardSettings);
  const matchPurchaseHistoryUrl = useMatch(routes.purchaseHistory);

  return (
    <Box
      className="subheader"
      sx={(theme) => ({
        paddingTop: theme.other.spacing(2),
        paddingBottom: theme.other.spacing(2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: '110px',
        gap: theme.other.spacing(2),
        [theme.fn.smallerThan(600)]: {
          paddingTop: theme.other.spacing(4),
          paddingBottom: theme.other.spacing(4),
        },
        [theme.fn.smallerThan(500)]: {
          flexDirection: 'column',
        },
      })}
    >
      <Title
        sx={(theme) => ({
          color: theme.colors.brandWhite,
          fontWeight: 500,
          fontSize: '2.4rem',
          fontFamily: 'ProximaNova',
          letterSpacing: '0.01em',
          [theme.fn.smallerThan(800)]: {
            fontSize: '2.2rem',
          },
          [theme.fn.smallerThan(600)]: {
            fontSize: '1.8rem',
          },
        })}
      >
        {props.title}
      </Title>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'flex-end',
          gap: theme.other.spacing(1),
          '& .mantine-Button-outline.mantine-Button-root': {
            borderColor: 'rgba(255, 255, 255, 0.5)',
            '&:not(:hover, .activeLink)': {
              color: theme.colors.brandWhite,
            },
          },
          [theme.fn.smallerThan(768)]: {
            flexDirection: 'column',
          },
          [theme.fn.smallerThan(500)]: {
            width: '100%',
          },
        })}
      >
        <Button
          variant="outline"
          className={matchPurchaseHistoryUrl ? 'activeLink' : undefined}
          component={NavLink}
          to={routes.purchaseHistory}
        >
          {t('purchaseHistory.subHeaderTitle')}
        </Button>
        <Button
          variant="outline"
          className={matchCardSettingsUrl ? 'activeLink' : undefined}
          component={NavLink}
          to={routes.cardSettings}
        >
          {t('cardSettings.subHeaderTitle')}
        </Button>
        <Button
          variant="outline"
          className={matchProfileUrl ? 'activeLink' : undefined}
          component={NavLink}
          to={routes.profile}
        >
          {t('myProfile.subHeaderTitle')}
        </Button>
      </Box>
    </Box>
  );
};

export default SubHeaderWhenAuthenticated;
