import { Box, Text, Title } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { ChangeEvent, useCallback, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Button from '../../../components/Button';
import TextInput from '../../../components/form/TextInput';
import { useAppSelector } from '../../../hooks/store';
import { ApiError, ErrorCode } from '../../../models/ApiError';
import RecaptchaAction from '../../../models/RecaptchaAction';
import { useProfileLandingSavePasswordMutation } from '../../../store/api/profileLandingApi';
import { useValidatePasswordMutation } from '../../../store/api/authApi';
import { setTempToken } from '../../../store/profileLandingSlice';

interface PasswordEntryFormValues {
  password: string;
  passwordConfirmation: string;
}

const PasswordEntry = (props: { completeEntry: () => void; skip: () => void }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [savePassword, savePasswordQueryState] = useProfileLandingSavePasswordMutation();
  const [validatePassword, validatePasswordQueryState] = useValidatePasswordMutation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const tempToken = useAppSelector((state) => state.profileLanding.tempToken) || '';

  const form = useForm<PasswordEntryFormValues>({
    schema: yupResolver(
      Yup.object().shape({
        password: Yup.string()
          .ensure()
          .test('empty-field', t('profileLanding.passwordStep.passwordMissing'), (_value) => (_value ? true : false)),
        passwordConfirmation: Yup.string()
          .ensure()
          .test('empty-field', t('profileLanding.passwordStep.passwordConfirmationMissing'), (_value) =>
            _value ? true : false,
          )
          .oneOf([Yup.ref('password')], t('profileLanding.passwordStep.passwordsDoNotMatchError')),
      }),
    ),
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
  });

  const getErrorMessage = useCallback(
    (error: ApiError) => {
      const errorCode = error.data?.message;
      if (error.status === 403) {
        return t('api.sessionExpiredError');
      }
      if (errorCode === ErrorCode.RecaptchaError) {
        return t('api.recaptchaError');
      }

      return t('api.unknownError');
    },
    [t],
  );

  useEffect(() => {
    async function savePasswordAsync() {
      const recaptchaToken = await executeRecaptcha?.(RecaptchaAction.ProfileLandingSavePassword);
      savePassword({ password: form.values.password, tempToken: tempToken || '', recaptchaToken: recaptchaToken });
    }

    if (validatePasswordQueryState.isError && validatePasswordQueryState.error) {
      const errorMessage = getErrorMessage(validatePasswordQueryState.error as ApiError);
      toast(errorMessage, { type: 'error' });
      validatePasswordQueryState.reset();
    }

    if (validatePasswordQueryState.isSuccess) {
      if (!validatePasswordQueryState.data?.isValid) {
        const message = validatePasswordQueryState.data?.messages.filter((m) => m.languageCode == i18n.language)[0]
          .message;
        toast(message, { type: 'error' });
        validatePasswordQueryState.reset();
        return;
      }

      validatePasswordQueryState.reset();
      savePasswordAsync();
    }
  }, [
    validatePasswordQueryState.isSuccess,
    validatePasswordQueryState.error,
    validatePasswordQueryState.isError,
    executeRecaptcha,
    form.values.password,
    getErrorMessage,
    validatePasswordQueryState.data?.isValid,
    tempToken,
    savePassword,
    validatePasswordQueryState.data?.messages,
    validatePasswordQueryState,
    i18n.language,
  ]);

  useEffect(() => {
    if (savePasswordQueryState.isError && savePasswordQueryState.error) {
      const errorMessage = getErrorMessage(savePasswordQueryState.error as ApiError);
      toast(errorMessage, { type: 'error' });
    }
  }, [savePasswordQueryState.isError, savePasswordQueryState.error, getErrorMessage, t]);

  useEffect(() => {
    if (savePasswordQueryState.isSuccess) {
      dispatch(setTempToken(savePasswordQueryState.data.tempToken));
      savePasswordQueryState.reset();
      props.completeEntry();
    }
  }, [
    savePasswordQueryState.isSuccess,
    dispatch,
    props,
    savePasswordQueryState.data?.tempToken,
    tempToken,
    executeRecaptcha,
    savePasswordQueryState,
  ]);

  const handleFormSubmit = useCallback(
    async (values: PasswordEntryFormValues) => {
      if (form.validate().hasErrors) return;

      validatePassword({ password: values.password });
    },
    [form, validatePassword],
  );

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    form.setFieldValue('password', value);
    if (form.errors.password) {
      form.clearErrors();
    }
  };

  const handlePasswordConfirmationChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    form.setFieldValue('passwordConfirmation', value);
    if (form.errors.passwordConfirmation) {
      form.clearErrors();
    }
  };

  return (
    <Box
      sx={(theme) => ({
        width: '60% !important',
        [theme.fn.smallerThan(960)]: {
          width: '100% !important',
        },
        '& .mantine-Button-root:nth-last-child(2)': {
          marginTop: theme.other.spacing(2.5),
        },
        '& .mantine-Button-root:nth-last-child(1)': {
          marginTop: theme.other.spacing(1.5),
        },
      })}
    >
      <Title
        sx={(theme) => ({
          color: theme.colors.brandGray,
          fontFamily: 'ProximaNovaBold',
          fontSize: '1.25rem',
          fontWeight: 700,
          letterSpacing: '0.01em',
          [theme.fn.smallerThan(800)]: {
            fontSize: '2.2rem',
          },
          [theme.fn.smallerThan(600)]: {
            fontSize: '1.6rem',
          },
        })}
      >
        {t('profileLanding.passwordStep.title')}
      </Title>
      <Text
        sx={(theme) => ({
          '&': {
            marginTop: theme.other.spacing(2),
            color: '#828282',
          },
        })}
      >
        <Text>{t('profileLanding.passwordStep.description')}</Text>
      </Text>
      <form onSubmit={form.onSubmit(handleFormSubmit)}>
        <TextInput
          name="password"
          type="password"
          label={t('profileLanding.passwordStep.password')}
          required
          onChange={handlePasswordChange}
          error={form.errors.password}
          value={form.values.password}
        />
        <TextInput
          name="passwordConfirmation"
          type="password"
          label={t('profileLanding.passwordStep.passwordConfirmation')}
          required
          onChange={handlePasswordConfirmationChange}
          error={form.errors.passwordConfirmation}
          value={form.values.passwordConfirmation}
        />
        <Button type="submit">{t('profileLanding.continue')}</Button>
        <Button variant="outline" onClick={props.skip}>
          {t('profileLanding.passwordStep.skip')}
        </Button>
      </form>
    </Box>
  );
};

export default PasswordEntry;
