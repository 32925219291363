import { Box, Loader, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

const Loading = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={(theme) => ({
        height: '80%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.other.spacing(1),
      })}
    >
      <Text
        sx={(theme) => ({
          fontSize: '0.9rem',
          color: theme.colors.brandGray,
        })}
      >
        {t('api.loading')}
      </Text>
      <Loader size={56} />
    </Box>
  );
};

export default Loading;
