import { createListenerMiddleware } from '@reduxjs/toolkit';
import { STORAGE_MAXIMA_AUTH_TOKEN_KEY } from '../../utility';
import { setToken } from '../userSlice';

const jwtMiddleware = createListenerMiddleware();

jwtMiddleware.startListening({
  actionCreator: setToken,
  effect: async (action) => {
    if (action.payload) {
      localStorage.setItem(STORAGE_MAXIMA_AUTH_TOKEN_KEY, action.payload);
    } else {
      localStorage.removeItem(STORAGE_MAXIMA_AUTH_TOKEN_KEY);
    }
  },
});

export default jwtMiddleware;
