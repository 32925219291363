import { Box, Text } from '@mantine/core';
import { useCallback, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import SubHeader from '../../components/SubHeader/SubHeader';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { ApiError, ErrorCode } from '../../models/ApiError';
import RecaptchaAction from '../../models/RecaptchaAction';
import { useSendConfirmationCodeMutation, useVerifyConfirmationCodeMutation } from '../../store/api/verificationApi';
import { setCardAccountStage, setVerificationToken } from '../../store/cardSlice';
import ConfirmCodeForm from '../ForgotPassword/ConfirmCode/ConfirmCodeForm';

const VerifyCode = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const verificationToken = useAppSelector((state) => state.card.verificationToken);

  const [verifyCode, verifyCodeQueryState] = useVerifyConfirmationCodeMutation();
  const [sendCode, sendCodeQueryState] = useSendConfirmationCodeMutation();

  const getSendCodeErrorMessage = useCallback(
    (error: ApiError) => {
      const errorCode = error.data?.message;
      if (error.status === 403) {
        return t('api.sessionExpiredError');
      }
      if (errorCode === ErrorCode.SendingCodeFailed) {
        return t('createAccount.sendCode.sendingCodeFailed');
      }
      if (errorCode === ErrorCode.RecaptchaError) {
        return t('api.recaptchaError');
      }
      return t('api.unknownError');
    },
    [t],
  );

  const getVerifyCodeErrorMessage = useCallback(
    (error: ApiError) => {
      const errorCode = error.data?.message;
      if (error.status === 403) {
        return t('api.sessionExpiredError');
      }
      if (errorCode === ErrorCode.ConfirmationCodeVerificationFailed) {
        return t('createAccount.verifyCode.codeVerificationFailedError');
      }
      if (errorCode === ErrorCode.RecaptchaError) {
        return t('api.recaptchaError');
      }
      return t('api.unknownError');
    },
    [t],
  );

  useEffect(() => {
    if (verifyCodeQueryState.isSuccess) {
      dispatch(setVerificationToken(verifyCodeQueryState.data.verificationToken));
      dispatch(setCardAccountStage('addPassword'));
      toast(t('createAccount.verifyCode.codeVerificationSuccess'), { type: 'success' });
    }
  }, [verifyCodeQueryState.isSuccess, verifyCodeQueryState.data, dispatch, t]);

  useEffect(() => {
    if (sendCodeQueryState.isSuccess) {
      toast(t('createAccount.verifyCode.newCodeSent'), { type: 'success' });
    }
    if (sendCodeQueryState.isError && sendCodeQueryState.error) {
      const error = sendCodeQueryState.error as ApiError;
      const errorMessage = getSendCodeErrorMessage(error);
      toast(errorMessage, { type: 'error' });
    }
  }, [sendCodeQueryState.isSuccess, sendCodeQueryState.isError, sendCodeQueryState.error, t, getSendCodeErrorMessage]);

  useEffect(() => {
    if (verifyCodeQueryState.isError && verifyCodeQueryState.error) {
      const error = verifyCodeQueryState.error as ApiError;
      const errorMessage = getVerifyCodeErrorMessage(error);
      toast(errorMessage, { type: 'error' });
    }
  }, [verifyCodeQueryState.isError, verifyCodeQueryState.error, getVerifyCodeErrorMessage]);

  const handleVerifyCode = useCallback(
    async (code: string) => {
      if (executeRecaptcha) {
        const recaptchaToken = await executeRecaptcha(RecaptchaAction.AccountCreationConfirmCode);
        verifyCode({ code, verificationToken, recaptchaToken });
      }
    },
    [executeRecaptcha, verifyCode, verificationToken],
  );

  const handleResendCode = useCallback(async () => {
    if (executeRecaptcha) {
      const recaptchaToken = await executeRecaptcha(RecaptchaAction.AccountCreationSendCode);
      sendCode({ verificationToken, recaptchaToken });
      verifyCodeQueryState.reset();
    }
    // eslint-disable-next-line
  }, [executeRecaptcha, sendCode, verificationToken]);

  return (
    <Box className="container">
      <SubHeader title={t('createAccount.subHeaderTitle')} />
      <Box
        className="content"
        sx={(theme) => ({
          paddingTop: theme.other.spacing(10),
          paddingBottom: theme.other.spacing(12),
          flexDirection: 'column',
          alignItems: 'center',
          gap: theme.other.spacing(6),
        })}
      >
        <Text
          sx={(theme) => ({
            fontSize: '1.25rem',
            textAlign: 'center',
            color: theme.colors.brandGray,
          })}
        >
          {t('createAccount.verifyCode.enterCode')}
        </Text>
        <ConfirmCodeForm
          onSubmitCode={handleVerifyCode}
          onResendCode={handleResendCode}
          loading={verifyCodeQueryState.isLoading}
          resendCodeLoading={sendCodeQueryState.isLoading}
          error={verifyCodeQueryState.isError ? getVerifyCodeErrorMessage(verifyCodeQueryState.error as ApiError) : ''}
        />
      </Box>
    </Box>
  );
};

export default VerifyCode;
