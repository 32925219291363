import {
  CheckboxGroup as MantineCheckboxGroup,
  Checkbox as MantineCheckbox,
  CheckboxProps as MantineCheckboxProps,
  CheckboxGroupProps as MantineCheckboxGroupProps,
} from '@mantine/core';

interface CheckboxGroupProps extends Omit<MantineCheckboxGroupProps, 'children'> {
  options: MantineCheckboxProps[];
}

const CheckboxGroup = ({ options, ...props }: CheckboxGroupProps) => {
  return (
    <MantineCheckboxGroup
      sx={(theme) => ({
        '&.mantine-CheckboxGroup-root > .mantine-CheckboxGroup-label': {
          margin: 0,
          fontFamily: 'ProximaNovaSemibold',
          color: theme.colors.maximaBlueDefault,
          letterSpacing: '0.01em',
        },
        '& .mantine-Group-root': {
          gap: props.orientation === 'vertical' ? 0 : theme.other.spacing(2.5),
        },
        '& .mantine-CheckboxGroup-error': {
          color: theme.colors.errorLight,
        },
      })}
      {...props}
    >
      {options.map(({ key, ...optionProps }) => (
        <MantineCheckbox
          key={key || optionProps.value?.toString()}
          sx={(theme) => ({
            marginTop: theme.other.spacing(1),
            marginBottom: theme.other.spacing(1),
            gap: theme.other.spacing(0.5),
            '& .mantine-CheckboxGroup-inner': {
              color: theme.colors.grayDark,
              '& .mantine-CheckboxGroup-icon': {
                top: '1px',
              },
            },
            '& .mantine-CheckboxGroup-input': {
              borderRadius: '4px',
              borderWidth: '2px',
              '&:not(:disabled)': {
                backgroundColor: theme.colors.maximaBlueDefault,
                borderColor: theme.colors.maximaBlueDefault,
                cursor: 'pointer',
              },
              '&:not(:checked, :disabled)': {
                backgroundColor: theme.colors.brandWhite,
                borderColor: theme.colors.maximaBlueLight,
              },
            },
            '&.mantine-Group-child .mantine-CheckboxGroup-label': {
              marginTop: '2px',
              paddingLeft: theme.other.spacing(1),
              color: theme.colors.brandGray,
              fontSize: '0.9rem',
              fontFamily: 'ProximaNova',
              userSelect: 'none',
              cursor: optionProps.disabled ? 'default' : 'pointer',
            },
          })}
          {...optionProps}
        />
      ))}
    </MantineCheckboxGroup>
  );
};

export default CheckboxGroup;
