import { Box, Title, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useRef } from 'react';
import { useQueryParams, DateParam, NumberParam } from 'use-query-params';
import PurchaseHistoryTable from './HistoryTable';
import AccountBalance from './AccountBalance';
import PurchaseHistorySubFooter from './SubFooter';
import BirthdayOffersBanner from '../BirthdayOffer/BirthdayOffersBanner';
import SubHeaderWhenAuthenticated from '../../components/SubHeader/SubHeaderWhenAuthenticated';
import { useAppSelector } from '../../hooks/store';
import useGetLocale from '../../utility/hooks/useGetLocale';
import { useGetPurchaseHistoryMutation } from '../../store/api/purchaseHistoryApi';
import { useGetAccountBalanceMutation } from '../../store/api/accountBalanceApi';
import DateRangeInput from '../../components/form/DateRangeInput';
import useGetName from '../../utility/hooks/useGetName';
import featureFlags from '../../features';

const PurchaseHistory = () => {
  const accountBalanceFetchInitialized = useRef(false);
  const purchaseHistoryFetchInitialized = useRef(false);
  const { t } = useTranslation();
  const now = useMemo(() => new Date(), []);
  const locale = useGetLocale();
  const [
    triggerGetBalance,
    { data: accountBalanceData, isError: accountBalanceError, isSuccess: isAccountBalanceRequestSuccess },
  ] = useGetAccountBalanceMutation();
  const [triggerGetPurchaseHistory, purchaseHistoryQueryState] = useGetPurchaseHistoryMutation();

  const { data: purchaseData, isSuccess: isPurchaseHistorySuccess } = purchaseHistoryQueryState;

  const accountId = useAppSelector((state) => state.user.personalData?.accountId);
  const cardNumber = useAppSelector((state) => state.card.cardNumber);
  const email = useAppSelector((state) => state.user.personalData?.profileData.email);
  const name = useGetName();
  const [historyState, setHistoryState] = useQueryParams({
    dateFrom: DateParam,
    dateTo: DateParam,
    pageSize: NumberParam,
    page: NumberParam,
  });

  useEffect(() => {
    if (!accountId) return;
    if (!accountBalanceFetchInitialized.current) {
      accountBalanceFetchInitialized.current = true;
      triggerGetBalance(accountId);
    }
  }, [accountId, triggerGetBalance]);

  useEffect(() => {
    if (!accountId) return;
    if (!purchaseHistoryFetchInitialized.current && !purchaseData) {
      purchaseHistoryFetchInitialized.current = true;
      triggerGetPurchaseHistory({
        dateFrom: historyState.dateFrom ?? new Date(now.getFullYear(), now.getMonth(), 1),
        dateTo: historyState.dateTo ?? new Date(now.getFullYear(), now.getMonth() + 1, 0),
        pageSize: historyState.pageSize ?? 25,
        page: historyState.page ?? 0,
        accountId: accountId,
      });
    }
  }, [accountId, triggerGetPurchaseHistory, accountBalanceData, historyState, purchaseData, now]);

  useEffect(() => {
    if (isAccountBalanceRequestSuccess) {
      accountBalanceFetchInitialized.current = false;
    }
  }, [isAccountBalanceRequestSuccess]);

  useEffect(() => {
    if (isPurchaseHistorySuccess) {
      purchaseHistoryFetchInitialized.current = false;
    }
  }, [isPurchaseHistorySuccess]);

  const handleDateChange = ([dateFrom, dateTo]: [Date, Date]) => {
    setHistoryState(() => ({ dateFrom, dateTo, page: undefined }));
    if (dateFrom && dateTo && accountId) {
      if (!purchaseHistoryFetchInitialized.current) {
        purchaseHistoryFetchInitialized.current = true;
        triggerGetPurchaseHistory({
          accountId,
          dateFrom,
          dateTo,
          pageSize: historyState.pageSize ?? 25,
          page: 0,
        });
      }
    }
  };

  const handlePageChange = (page: number) => {
    if (!accountId) return;
    if (!historyState.dateFrom || !historyState.dateTo) return;
    setHistoryState({ page: page });
    if (!purchaseHistoryFetchInitialized.current) {
      purchaseHistoryFetchInitialized.current = true;
      triggerGetPurchaseHistory({
        dateFrom: historyState.dateFrom,
        dateTo: historyState.dateTo,
        pageSize: historyState.pageSize ?? 25,
        page: page,
        accountId: accountId,
      });
    }
  };

  return (
    <Box className="container" sx={{ justifyContent: 'space-between' }}>
      <SubHeaderWhenAuthenticated title={t('purchaseHistory.subHeaderTitle')} />
      <Box
        className="content"
        sx={(theme) => ({
          paddingTop: theme.other.spacing(10),
          paddingBottom: theme.other.spacing(6),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: '55vh',
          [theme.other.smallerThanHeight(900)]: {
            paddingTop: theme.other.spacing(6),
          },
          [theme.other.largerThanHeight(1100)]: {
            paddingTop: theme.other.spacing(15),
          },
          [theme.fn.smallerThan(1000)]: {
            paddingTop: theme.other.spacing(6),
          },
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            width: '100%',
            gap: theme.other.spacing(2),
            marginBottom: theme.other.spacing(3),
          })}
        >
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              gap: theme.other.spacing(2),
              [theme.fn.smallerThan(768)]: {
                width: '100%',
              },
            })}
          >
            <Title
              sx={(theme) => ({
                color: theme.colors.brandGray,
                fontFamily: 'ProximaNovaBold',
                fontSize: '1.8rem',
                letterSpacing: '0.01em',
                [theme.fn.smallerThan(800)]: {
                  fontSize: '2.2rem',
                },
                [theme.fn.smallerThan(600)]: {
                  fontSize: '1.6rem',
                },
              })}
            >
              {t('purchaseHistory.contentTitle')}
            </Title>
            <Box
              sx={() => ({
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'column',
                width: '100%',
                fontFamily: 'ProximaNova',
              })}
            >
              <Text
                sx={(theme) => ({
                  fontFamily: 'ProximaNovaSemiBold',
                  color: theme.colors.brandGray,
                  [theme.fn.smallerThan(600)]: {
                    textOverflow: 'ellipsis',
                    width: '100%',
                    overflow: 'hidden',
                  },
                })}
              >
                {name || email}
              </Text>
              <Text
                sx={(theme) => ({
                  color: theme.colors.brandGray,
                })}
              >{`${t('purchaseHistory.loyaltyCard')} ${cardNumber}`}</Text>
            </Box>
          </Box>
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              fontFamily: 'ProximaNova',
              alignItems: 'flex-end',
              [theme.fn.smallerThan(800)]: {
                alignItems: 'flex-start',
                width: '100%',
              },
            })}
          >
            <Box
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                fontFamily: 'ProximaNova',
                [theme.fn.smallerThan(theme.breakpoints.sm)]: {
                  alignItems: 'flex-start',
                  width: '100%',
                },
              })}
            >
              <Box
                sx={(theme) => ({
                  width: '100%',
                  color: theme.colors.maximaNavyBlueDefault,
                  '& .mantine-DateRangePicker-wrapper': {
                    width: '100%',
                    minWidth: '250px',
                  },
                  '& .mantine-DateRangePicker-root': {
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    '& .mantine-DateRangePicker-label': {
                      marginTop: '1px',
                      marginRight: theme.other.spacing(1),
                      marginBottom: '0',
                      color: theme.colors.brandGray,
                      fontSize: '0.9rem',
                    },
                    [theme.fn.smallerThan(500)]: {
                      '& > div': {
                        width: '100%',
                      },
                    },
                  },
                })}
              >
                <DateRangeInput
                  locale={locale}
                  label={t('purchaseHistory.datePicker.label')}
                  value={[
                    historyState.dateFrom ?? new Date(now.getFullYear(), now.getMonth(), 1),
                    historyState.dateTo ?? new Date(now.getFullYear(), now.getMonth() + 1, 0),
                  ]}
                  onChange={handleDateChange}
                  clearable={false}
                />
              </Box>
              <AccountBalance accountBalanceData={accountBalanceData} isError={accountBalanceError} />
            </Box>
          </Box>
        </Box>
        <PurchaseHistoryTable
          isLoading={
            (purchaseHistoryQueryState.isLoading || purchaseData === undefined) && !purchaseHistoryQueryState.isError
          }
          isError={purchaseHistoryQueryState.isError}
          page={historyState.page ?? 0}
          handlePageChange={handlePageChange}
          purchaseData={purchaseData}
        />
        {featureFlags.birthdayOffersEnabled ? <BirthdayOffersBanner /> : <></>}
      </Box>
      <PurchaseHistorySubFooter />
    </Box>
  );
};

export default PurchaseHistory;
