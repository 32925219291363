import { Box, Image, createStyles } from '@mantine/core';
// import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import YouTube from 'react-youtube';
import CredentialsLoginForm from './credentialsLoginForm';
import StrongAuthLoginForm from './StrongAuthLoginForm';
import loyaltyCardTilted from '../../../assets/svg/logos/blue_loyalty_card_tilted.svg';
import loyaltyCardLarge from '../../../assets/svg/logos/blue_card_simplified_false_large.svg';

enum LoginMethod {
  Strong = 1,
  Credentials = 2,
}

const useStyles = createStyles((theme) => ({
  youtubeIframe: {
    height: 'auto',
    width: '100%',
    aspectRatio: '640/360',
    marginTop: theme.other.spacing(2),
  },
}));

const FormCard = () => {
  const { i18n, t } = useTranslation();
  const { classes } = useStyles();
  const [loginMethod, setLoginMethod] = useState(LoginMethod.Strong);
  const toStrongAuth = useCallback(() => setLoginMethod(LoginMethod.Strong), []);
  const toCredentialsAuth = useCallback(() => setLoginMethod(LoginMethod.Credentials), []);

  const videoId = t('landing.formCard.tutorialVideoId').toString();

  const youtubeOptions = {
    playerVars: {
      autoplay: 0,
      hl: i18n.language,
      rel: 0,
    },
  };

  return (
    <Box
      sx={(theme) => ({
        gridArea: 'login-form',
        marginTop: theme.other.spacing(4),
        marginBottom: theme.other.spacing(4),
        position: 'relative',
        [theme.fn.smallerThan(960)]: {
          marginTop: theme.other.spacing(10),
        },
      })}
    >
      <Image
        width={140}
        src={loyaltyCardTilted}
        alt=""
        sx={(theme) => ({
          position: 'absolute',
          top: theme.other.spacing(-6.5),
          left: '50%',
          transform: 'translateX(-50%)',
          filter: 'drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.16))',
        })}
      />
      <Box
        sx={(theme) => ({
          backgroundColor: theme.colors.brandWhite,
          padding: theme.other.spacing(5),
          paddingTop: theme.other.spacing(6),
          paddingBottom: theme.other.spacing(5),
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.16)',
          borderRadius: '4px',
          overflow: 'hidden',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          [theme.fn.smallerThan(400)]: {
            paddingLeft: theme.other.spacing(2.5),
            paddingRight: theme.other.spacing(2.5),
          },
        })}
      >
        {loginMethod == LoginMethod.Credentials ? (
          <CredentialsLoginForm cancel={toStrongAuth}></CredentialsLoginForm>
        ) : (
          <StrongAuthLoginForm noPersonalCodeClick={toCredentialsAuth}></StrongAuthLoginForm>
        )}
        {!!videoId ? (
          <YouTube videoId={videoId} opts={youtubeOptions} iframeClassName={classes.youtubeIframe} />
        ) : (
          <></>
        )}
        <Image
          src={loyaltyCardLarge}
          alt=""
          sx={() => ({
            filter: 'drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.16))',
            alignSelf: 'center',
            marginTop: '3em',
          })}
        />
      </Box>
    </Box>
  );
};

export default FormCard;
