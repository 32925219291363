export enum ErrorCode {
  AccountDoesNotExist = 'AccountDoesNotExist',
  AccountNotFound = 'AccountNotFound',
  EmailDoesNotMatch = 'EmailDoesNotMatch',
  SendingCodeFailed = 'SendingCodeFailed',
  VerificationFailed = 'VerificationFailed',
  ConfirmationCodeVerificationFailed = 'ConfirmationCodeVerificationFailed',
  NoEmailOrPhone = 'NoEmailOrPhone',
  CredentialsNotVerified = 'CredentialsNotVerified',
  CreatingCredentialsFailed = 'CreatingCredentialsFailed',
  LoginFailed = 'LoginFailed',
  CardInvalidState = 'CardInvalidState',
  AccountAlreadyExists = 'AccountAlreadyExists',
  CardNotFoundOrInInvalidState = 'CardNotFoundOrInInvalidState',
  CardBirthdateVerificationFailed = 'CardBirthdateVerificationFailed',
  CreatingOrUpdatingProfileFailed = 'CreatingOrUpdatingProfileFailed',
  PhoneNumberExists = 'PhoneNumberExists',
  EmailExists = 'EmailExists',
  EmailIsNotVerified = 'EmailIsNotVerified',
  EmailVerificationFailed = 'EmailVerificationFailed',
  CardBlockingFailed = 'CardBlockingFailed',
  CardReactivationFailed = 'CardReactivationFailed',
  CardJoiningFailed = 'CardJoiningFailed',
  RemoteBackendRequestFailed = 'RemoteBackendRequestFailed',
  RecaptchaError = 'RecaptchaError',
  BirthDateChangeExhausted = 'BirthDateChangeExhausted',
  LoyaltyExitFailed = 'LoyaltyExitFailed',
  PasswordChangeFailed = 'PasswordChangeFailed',
  UnknownError = 'UnknownError',
  InvalidPassword = 'InvalidPassword',
  EmailOrPhoneCannotBeUsed = 'EmailOrPhoneCannotBeUsed',
  AddressSearchError = 'AddressSearchError',
  ServiceError = 'ServiceError',
  TokenInvalidationFailed = 'TokenInvalidationFailed',
  FetchingProfileFailed = 'FetchingProfileFailed',
  FetchingAccountBalanceFailed = 'FetchingAccountBalanceFailed',
  FetchingPurchaseInfoFailed = 'FetchingPurchaseInfoFailed',
  PersonalCodeAlreadyExists = 'PersonalCodeAlreadyExists',
  LoginAlreadyExists = 'LoginAlreadyExists',
}

export interface ApiError {
  status: number;
  data?: {
    message?: ErrorCode | string;
    error?: string;
    statusCode?: number;
  };
}

export interface AccountExistsException {
  message?: ErrorCode | string;
  existingEmail: string;
  existingPhone: string;
}
