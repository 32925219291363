import { Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { PdiList } from '../../../store/api/purchaseHistoryApi';
import { groupBy, sum } from '../../../utility';
import useFormatCurrency from '../../../utility/hooks/useFormatCurrency';

interface DiscountLinesProps {
  data: PdiList;
  totalDiscounts: number;
}

const DiscountLines = ({ data, totalDiscounts }: DiscountLinesProps) => {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();

  const discountGroups = groupBy(data.Pdi, (discount) => discount.SchemeId);
  const discounts = Object.keys(discountGroups)
    .filter((schemeId) => schemeId != '0')
    .map((schemeId) => {
      return {
        DiscountTitle: discountGroups[schemeId][0].DiscountTitle,
        Discount: sum(discountGroups[schemeId].map((discount) => Number(discount.Discount))),
      };
    });

  const discountNames = discounts.map((discount, i) => {
    const { DiscountTitle } = discount;
    return <Text key={i}>{DiscountTitle}</Text>;
  });

  const discountAmounts = discounts.map((pdi, i) => {
    const { Discount } = pdi;
    return <Text key={i}>{`-${formatCurrency(Discount)}`}</Text>;
  });

  return (
    <View style={{ flexDirection: 'row' }}>
      <View>
        <Text>{t('purchaseHistory.discount.total')}</Text>
        {discountNames}
      </View>
      <View>
        <Text>{`-${formatCurrency(totalDiscounts)}`}</Text>
        {discountAmounts}
      </View>
    </View>
  );
};

export default DiscountLines;
