import { Box } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import useFormatDate from '../../../utility/hooks/useFormatDate';

interface ReceiptFooterProps {
  data: { cashier: string; invnr: string; date: string };
}

const ReceiptFooter = ({ data }: ReceiptFooterProps) => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const { cashier = '', date = new Date().toISOString() } = data || {};

  return (
    <Box
      sx={(theme) => ({
        padding: `${theme.other.spacing(1.5)} 0`,
      })}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            textAlign: 'right',
          }}
        >
          <Box>{`${t('purchaseHistory.receiptFooter.cashier')} ${cashier}`}</Box>
          <Box>{`${t('purchaseHistory.receiptFooter.date')} ${formatDate(date, {
            dateStyle: 'short',
            timeStyle: 'short',
          })}`}</Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ReceiptFooter;
