import { Box, Title, Image } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import successImage from '../../../assets/svg/success_big.svg';
import Button from '../../../components/Button';
import useRoutes from '../../../i18n/useRoutes';

const Complete = () => {
  const navigate = useNavigate();
  const routes = useRoutes();
  const { t } = useTranslation();

  return (
    <Box
      sx={() => ({
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '40px',
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          alignSelf: 'auto',
          alignItems: 'center',
          gap: '8px',
          '& .mantine-Button-root': {
            marginTop: theme.other.spacing(2.5),
            marginBottom: theme.other.spacing(2),
            width: '100%',
          },
        })}
      >
        <Box
          sx={() => ({
            '&': {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            },
          })}
        >
          <Image
            src={successImage}
            alt="Success checkmark"
            sx={(theme) => ({
              '&': {
                width: '180px',
                aspectRatio: '1',
                paddingTop: theme.other.spacing(4),
                paddingBottom: theme.other.spacing(4),
              },
            })}
          />
        </Box>
        <Title
          sx={() => ({
            '&': {
              textAlign: 'center',
              fontWeight: 700,
              fontSize: '24px',
              color: '#363D40',
            },
          })}
        >
          {t('strongAuthRegister.completeStep.congratulations')}
        </Title>
        <Title
          sx={() => ({
            '&': {
              textAlign: 'center',
              fontWeight: 700,
              fontSize: '24px',
              color: '#363D40',
            },
          })}
        >
          {t('strongAuthRegister.completeStep.subtitle')}
        </Title>
        <Button type="submit" onClick={() => navigate(routes.purchaseHistory)}>
          {t('strongAuthRegister.completeStep.continue')}
        </Button>
      </Box>
    </Box>
  );
};

export default Complete;
