import { Box } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import Progress from './Progress';
import EmailEntryView from './Partials/emailEntry';
import PlasticCardSelectionView from './Partials/plasticCardSelection';
import PlasticCardNoneView from './Partials/plasticCardNone';
import PlasticCardEntryView from './Partials/plasticCardEntry';
import EmailConfirmationView from './Partials/emailConfirmation';
import CompleteView from './Partials/complete';
import PhoneEntryView from './Partials/phoneEntry';
import PhoneConfirmationView from './Partials/phoneConfirmation';
import ProfileInfoView from './Partials/profileInfo';
import SubHeader from '../../components/SubHeader/SubHeader';
import { RegistrationStep } from '../../store/strongAuthRegisterSlice';
import { useAppSelector } from '../../hooks/store';

const StrongAuthRegister = () => {
  const { t } = useTranslation();
  const step = useAppSelector((state) => state.strongAuthRegister.currentStep);

  const getProgressStep = (currentStep: RegistrationStep): RegistrationStep => {
    switch (currentStep) {
      case RegistrationStep.EmailEntry:
        return 1;
      case RegistrationStep.EmailConfirmation:
        return 1;
      case RegistrationStep.PlasticCardTypeSelection:
        return 2;
      case RegistrationStep.PlasticCardEntry:
        return 2;
      case RegistrationStep.PlasticCardNotProvided:
        return 2;
      case RegistrationStep.PhoneEntry:
        return 3;
      case RegistrationStep.PhoneConfirmation:
        return 3;
      case RegistrationStep.ProfileInfo:
        return 4;
      case RegistrationStep.Complete:
        return 5;
      default:
        return 1;
    }
  };

  const RenderContent = (props: { step: RegistrationStep }) => {
    switch (props.step) {
      case RegistrationStep.EmailEntry:
        return <EmailEntryView />;
      case RegistrationStep.EmailConfirmation:
        return <EmailConfirmationView />;
      case RegistrationStep.PlasticCardTypeSelection:
        return <PlasticCardSelectionView />;
      case RegistrationStep.PlasticCardNotProvided:
        return <PlasticCardNoneView />;
      case RegistrationStep.PlasticCardEntry:
        return <PlasticCardEntryView />;
      case RegistrationStep.PhoneEntry:
        return <PhoneEntryView />;
      case RegistrationStep.PhoneConfirmation:
        return <PhoneConfirmationView />;
      case RegistrationStep.ProfileInfo:
        return <ProfileInfoView />;
      case RegistrationStep.Complete:
        return <CompleteView />;
    }
  };

  return (
    <Box className="container" sx={{ justifyContent: 'space-between' }}>
      <SubHeader title={t('strongAuthRegister.subHeaderTitle')} />
      <Box
        className="content"
        sx={(theme) => ({
          paddingTop: theme.other.spacing(10),
          paddingBottom: theme.other.spacing(6),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: '55vh',
          [theme.fn.smallerThan(960)]: {
            paddingTop: 0,
          },
          [theme.other.largerThanHeight(1100)]: {
            paddingTop: theme.other.spacing(15),
          },
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'flex-start',
            gap: theme.other.spacing(3),
            alignSelf: 'center',
            [theme.fn.smallerThan(960)]: {
              flexDirection: 'column',
              justifyContent: 'center',
            },
          })}
        >
          <Box
            sx={(theme) => ({
              display: 'flex',
              width: '20rem',
              padding: '0 1.5rem',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '0.5rem',
              borderRadius: '5px',
              backgroundColor: theme.colors.brandWhite,
              position: 'relative',
              [theme.fn.smallerThan(960)]: {
                width: '100%',
              },
            })}
          >
            <Progress currentStep={getProgressStep(step)} />
            <Box
              sx={(theme) => ({
                width: '100%',
                height: '103px',
                position: 'absolute',
                right: '0px',
                top: '0px',
                background: 'linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 74.76%)',
                [theme.fn.smallerThan(960)]: {
                  display: 'none',
                },
              })}
            ></Box>
            <Box
              sx={(theme) => ({
                width: '100%',
                height: '103px',
                position: 'absolute',
                right: '0px',
                bottom: '0px',
                background: 'linear-gradient(0deg, #FFF 0%, rgba(255, 255, 255, 0.00) 74.76%)',
                [theme.fn.smallerThan(960)]: {
                  display: 'none',
                },
              })}
            ></Box>
          </Box>
          <Box
            sx={(theme) => ({
              display: 'flex',
              width: '670px',
              padding: '40px 64px',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
              gap: '8px',
              alignSelf: 'stretch',
              borderRadius: '5px',
              backgroundColor: theme.colors.brandWhite,
              position: 'relative',
              height: '100%',
              [theme.fn.smallerThan(960)]: {
                width: '100%',
                padding: '30px 20px',
              },
            })}
          >
            <Box
              sx={() => ({
                width: '100%',
              })}
            >
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  width: '100% !important',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '8px',
                  [theme.fn.smallerThan(960)]: {
                    width: '100% !important',
                  },
                  '&, & form': {
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    [theme.fn.largerThan(500)]: {
                      alignItems: 'flex-start',
                    },
                  },
                  '& .mantine-TextInput-root': {
                    marginBottom: theme.other.spacing(1),
                    width: '100%',
                  },
                  '& .mantine-Button-root': {
                    marginTop: theme.other.spacing(2.5),
                    marginBottom: theme.other.spacing(2),
                    width: '100%',
                  },
                })}
              >
                <RenderContent step={step} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default StrongAuthRegister;
