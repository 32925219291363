import { useEffect } from 'react';
import ConfirmCode from './ConfirmCode';
import SendCode from './SendCode';
import ChangePassword from './EnterPassword';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { setStage } from '../../store/forgotPasswordSlice';
import useScrollToTopOnStateChange from '../../utility/hooks/useScrollToTopOnStateChange';

const ForgotPassword = () => {
  const forgotPasswordStage = useAppSelector((state) => state.forgotPassword.stage);
  const dispatch = useAppDispatch();
  useScrollToTopOnStateChange(forgotPasswordStage);

  useEffect(() => {
    return () => {
      dispatch(setStage('sendCode'));
    };
  }, [dispatch]);

  if (forgotPasswordStage === 'sendCode') {
    return <SendCode />;
  }

  if (forgotPasswordStage === 'confirmCode') {
    return <ConfirmCode />;
  }

  if (forgotPasswordStage === 'changePassword') {
    return <ChangePassword />;
  }

  return <SendCode />;
};

export default ForgotPassword;
