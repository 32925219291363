import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface StrongAuthState {
  personalCode?: string | null | undefined;
  birthDate?: string | null | undefined;
  phone?: string | null | undefined;
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
}

const initialState: StrongAuthState = {};

export const strongAuthRegisterSlice = createSlice({
  name: 'strongAuthSlice',
  initialState,
  reducers: {
    setPersonalCode: (state: StrongAuthState, action: PayloadAction<string>) => {
      state.personalCode = action.payload;
    },
    setBirthDate: (state: StrongAuthState, action: PayloadAction<string | undefined>) => {
      state.birthDate = action.payload;
    },
    setNames: (
      state: StrongAuthState,
      action: PayloadAction<{ fistName: string | undefined; lastName: string | undefined }>,
    ) => {
      state.firstName = action.payload.fistName;
      state.lastName = action.payload.lastName;
    },
    setPhone: (state: StrongAuthState, action: PayloadAction<string | null | undefined>) => {
      state.phone = action.payload;
    },
  },
});

export const { setPersonalCode, setBirthDate, setNames, setPhone } = strongAuthRegisterSlice.actions;
export default strongAuthRegisterSlice.reducer;
