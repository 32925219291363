import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mantine/core';
import ChangePasswordForm from './ChangePasswordForm';
import SubHeaderWhenAuthenticated from '../../../components/SubHeader/SubHeaderWhenAuthenticated';
import { ProfileView } from '..';

interface ChangePasswordProps {
  onChangeView: (view: ProfileView) => void;
}

const ChangePassword = ({ onChangeView }: ChangePasswordProps) => {
  const { t } = useTranslation();

  const handleChangeToProfileView = useCallback(() => {
    onChangeView('profileData');
  }, [onChangeView]);

  return (
    <Box className="container">
      <SubHeaderWhenAuthenticated title={t('myProfile.changePassword.subHeaderTitle')} />
      <Box
        className="content"
        sx={(theme) => ({
          paddingTop: theme.other.spacing(10),
          paddingBottom: theme.other.spacing(6),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: '55vh',
          [theme.other.smallerThanHeight(900)]: {
            paddingTop: theme.other.spacing(6),
          },
          [theme.other.largerThanHeight(1100)]: {
            paddingTop: theme.other.spacing(15),
          },
          [theme.fn.smallerThan(768)]: {
            paddingTop: theme.other.spacing(6),
            minHeight: '45vh',
          },
        })}
      >
        <Box
          sx={(theme) => ({
            width: '100%',
            maxWidth: '960px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: theme.other.spacing(4),
          })}
        >
          <ChangePasswordForm onChangeToProfileView={handleChangeToProfileView} />
        </Box>
      </Box>
    </Box>
  );
};

export default ChangePassword;
