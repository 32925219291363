import { Box, Image, Text, Title } from '@mantine/core';
import { useTranslation, Trans } from 'react-i18next';
import euroapteekLogo from '../../assets/png/euroapteek_logo.png';
import fitlapLogo from '../../assets/png/fitlap_logo.png';
import saastuoptikaLogo from '../../assets/png/pereoptika_logo.png';

const Description = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={(theme) => ({
        gridArea: 'description',
        marginTop: theme.other.spacing(2),
        marginBottom: theme.other.spacing(7),
        color: theme.colors.maximaNavyBlueDefault,
      })}
    >
      <Title
        order={2}
        sx={(theme) => ({
          fontSize: '1.6rem',
          fontFamily: 'ProximaNova',
          letterSpacing: '0.02em',
          color: theme.colors.maximaNavyBlueDefault,
          marginTop: theme.other.spacing(3),
          marginBottom: theme.other.spacing(3),
        })}
      >
        {t('landing.description.title')}
      </Title>
      <Text sx={(theme) => ({ marginBottom: theme.other.spacing(2.5) })} component="p">
        {t('landing.description.main')}
      </Text>
      <Text sx={(theme) => ({ marginBottom: theme.other.spacing(2.5) })} component="p">
        <Trans i18nKey="landing.description.partnerOffers" />
      </Text>
      <Box
        sx={(theme) => ({
          marginTop: theme.other.spacing(5),
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          gap: theme.other.spacing(3),
        })}
      >
        <Image width={120} src={euroapteekLogo} alt="" />
        <Image width={120} src={fitlapLogo} alt="" />
        <Image width={120} src={saastuoptikaLogo} alt="" />
      </Box>
    </Box>
  );
};

export default Description;
