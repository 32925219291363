import { Box, Image, Text, createStyles } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { Title } from '@mantine/core';
import special_weekly_offers from '../../assets/png/special_weekly_offers.png';
import virtual_card from '../../assets/png/virtual_card.png';
import digital_receipts from '../../assets/png/digital_receipts.png';
import birthday_offer from '../../assets/png/birthday_offer.png';
import scan_and_go from '../../assets/png/scan_and_go.png';
import tare_receipts from '../../assets/png/tare_receipts.png';
import aitah_phone from '../../assets/png/Aitah_phone_800x696px.png';
import cashback from '../../assets/png/cashback_offer.png';
import age14years from '../../assets/png/14years.png';

const useStyles = createStyles((theme) => ({
  benefitVertical: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 120,
    color: theme.colors.brandWhite,
    padding: theme.other.spacing(1.2),
    gap: theme.other.spacing(0.75),
    '& .icon': {
      width: 60,
    },
    '& .text': {
      textAlign: 'center',
      fontWeight: 600,
      overflowWrap: 'break-word',
    },
  },
  appBenefitTitle: {
    color: theme.colors.brandWhite,
    fontWeight: 600,
    fontSize: '1.3rem',
  },
}));

const OffersList = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <Box
      sx={() => ({
        gridArea: 'list',
        display: 'flex',
        flexDirection: 'column',
      })}
    >
      <Title
        sx={(theme) => ({
          marginTop: theme.other.spacing(3),
          marginBottom: theme.other.spacing(3.5),
          fontWeight: 400,
          fontSize: '2.4rem',
          fontFamily: 'ProximaNova',
          letterSpacing: '0.01em',
          color: theme.colors.brandWhite,
          [theme.fn.smallerThan(960)]: {
            textAlign: 'center',
          },
        })}
      >
        {t('landing.title')}
      </Title>
      <Box
        sx={(theme) => ({
          marginBottom: theme.other.spacing(4),
          display: 'flex',
          flexDirection: 'row',
          gap: theme.other.spacing(2),
          alignItems: 'center',
          justifyContent: 'center',
          [theme.fn.smallerThan(1400)]: {
            flexDirection: 'column',
          },
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'row',
            gap: theme.other.spacing(3),
            [theme.fn.smallerThan(1300)]: {
              flexDirection: 'column-reverse',
            },
            maxWidth: '710px',
          })}
        >
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: theme.other.spacing(1),
            })}
          >
            <Box
              sx={() => ({
                display: 'flex',
                flexDirection: 'row',
              })}
            >
              <Box className={classes.benefitVertical}>
                <Image src={cashback} alt="" className="icon" />
                <Text className="text">{t('landing.benefits.moneyBack')}</Text>
              </Box>
              <Box className={classes.benefitVertical}>
                <Image src={birthday_offer} alt="" className="icon" />
                <Text className="text">{t('landing.benefits.birthdayDiscount')}</Text>
              </Box>
              <Box className={classes.benefitVertical}>
                <Image src={age14years} alt="" className="icon" />
                <Text className="text">{t('landing.benefits.joinFrom14years')}</Text>
              </Box>
            </Box>
            <Box
              sx={(theme) => ({
                marginTop: theme.other.spacing(3),
                flexDirection: 'row',
              })}
              className={classes.appBenefitTitle}
            >
              {t('landing.benefits.winMoreWithApp')}
            </Box>
            <Box
              sx={() => ({
                display: 'flex',
                flexDirection: 'row',
              })}
            >
              <Box className={classes.benefitVertical}>
                <Image src={virtual_card} alt="" className="icon" />
                <Text className="text">{t('landing.benefits.convenientDigitalCard')}</Text>
              </Box>
              <Box className={classes.benefitVertical}>
                <Image src={special_weekly_offers} alt="" className="icon" />
                <Text className="text">{t('landing.benefits.appSuperPrice')}</Text>
              </Box>
              <Box className={classes.benefitVertical}>
                <Image src={digital_receipts} alt="" className="icon" />
                <Text className="text">{t('landing.benefits.digitalReceipts')}</Text>
              </Box>
            </Box>
            <Box
              sx={() => ({
                display: 'flex',
                flexDirection: 'row',
              })}
            >
              <Box className={classes.benefitVertical}>
                <Image src={scan_and_go} alt="" className="icon" />
                <Text className="text">{t('landing.benefits.scanAndGo')}</Text>
              </Box>
              <Box className={classes.benefitVertical}>
                <Image src={tare_receipts} alt="" className="icon" />
                <Text className="text">{t('landing.benefits.tareCoupons')}</Text>
              </Box>
            </Box>
          </Box>
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: theme.other.spacing(3),
            })}
          >
            <Text
              sx={(theme) => ({
                color: theme.colors.brandWhite,
                fontWeight: 800,
                fontSize: '1.4rem',
                textAlign: 'center',
              })}
            >
              {t('landing.benefits.updateData')}
            </Text>
            <Image width={'100%'} src={aitah_phone} alt="" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OffersList;
