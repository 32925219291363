import { useCallback, useEffect, useState } from 'react';
import { Box } from '@mantine/core';
import { useDispatch } from 'react-redux';
import CongratulationsPartial from './Partials/CongratulationsPartial';
import DokobitPartial from './Partials/DokobitPartial';
import IntroductionPartial from './Partials/IntroductionPartial';
import ProfileDataPartial from './Partials/ProfileDataPartial';
import VerificationPartial from './Partials/VerificationPartial';
import IntermediarySuccessPartial from './Partials/IntermediarySuccessPartial';
import { useAppSelector } from '../../hooks/store';
import Modal from '../../components/Modal';
import { getIsBirthdayOfferDeclined, setBirthdayOfferDeclined } from '../../utility';
import { PromptContact, setIsBirthdayOffersModalOpen } from '../../store/userSlice';
import { useDokobitSessionCreateMutation } from '../../store/api/dokobitApi';

const BirthdayConsentModal = () => {
  const opened = useAppSelector((state) => state.user.isBirthdayOffersModalOpen);
  const dispatch = useDispatch();
  const setOpened = useCallback(
    (state: boolean) => {
      dispatch(setIsBirthdayOffersModalOpen(state));
    },
    [dispatch],
  );
  const [offerStep, setOfferStep] = useState(0);
  const accountId = useAppSelector((state) => state.user.personalData?.accountId);
  const personalCode = useAppSelector((state) => state.user.personalData?.profileData?.personalCode);
  const agreedToBdayOffers = useAppSelector((state) => state.user.personalData?.consents.agreeToReceiveBirthdayOffers);
  const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn);
  const [promptContact, setPromptContact] = useState<PromptContact | null>(null);
  const [triggerSessionCreate] = useDokobitSessionCreateMutation({ fixedCacheKey: 'dokobit-session-create' });

  const handleCloseModal = () => {
    setOpened(false);
  };

  const handleOffersDeclined = () => {
    handleCloseModal();
    setBirthdayOfferDeclined(accountId);
  };

  useEffect(() => {
    if (isLoggedIn && !agreedToBdayOffers && accountId && !getIsBirthdayOfferDeclined(accountId)) {
      setOpened(true);
    }
  }, [accountId, agreedToBdayOffers, isLoggedIn, setOpened]);

  useEffect(() => {
    if (opened) {
      setOfferStep(0);
    }
  }, [opened]);

  const RenderContent = (props: { promptContact: PromptContact | null; offerStep: number }) => {
    const setOfferStep2 = useCallback(() => setOfferStep(2), []);
    const setOfferStep3 = useCallback(() => setOfferStep(3), []);
    const setOfferStep4 = useCallback(() => setOfferStep(4), []);
    const agreeOffers = useCallback(() => {
      if (!!personalCode) {
        setOfferStep3();
      } else {
        triggerSessionCreate({});
        setOfferStep(1);
      }
    }, [setOfferStep3]);

    if (props.promptContact)
      return <VerificationPartial promptContact={props.promptContact} setPromptContact={setPromptContact} />;

    switch (props.offerStep) {
      case 0:
        return <IntroductionPartial nextStep={agreeOffers} handleOffersDeclined={handleOffersDeclined} />;
      case 1:
        return <DokobitPartial nextStep={setOfferStep2} />;
      case 2:
        return <IntermediarySuccessPartial nextStep={setOfferStep3} />;
      case 3:
        return <ProfileDataPartial nextStep={setOfferStep4} setPromptContact={setPromptContact} />;
      case 4:
        return <CongratulationsPartial />;
    }
    return <Box />;
  };

  return (
    <Modal
      sx={(theme) => ({
        '& .mantine-Modal-title div > div': {
          display: 'none',
        },
        '& .mantine-Modal-body': {
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          [theme.fn.largerThan(840)]: {
            paddingLeft: '90px',
            paddingRight: '90px',
            paddingBottom: '28px',
          },
        },
        '& .mantine-Modal-close': {
          borderRadius: '50%',
          padding: theme.other.spacing(0.5),
          height: '36px',
          minHeight: '36px',
          width: '36px',
          minWidth: '36px',
          '&:not(:disabled):active': {
            transform: 'translateY(0)',
          },
          '& svg': {
            width: '20px',
            height: '20px',
          },
        },
        '& .mantine-Modal-modal': {
          borderRadius: '24px',
          [theme.fn.largerThan(840)]: {
            width: '800px',
            aspectRatio: '1',
          },
        },
      })}
      opened={opened}
      onClose={handleCloseModal}
    >
      <RenderContent promptContact={promptContact} offerStep={offerStep} />
    </Modal>
  );
};

export default BirthdayConsentModal;
