import { Box, Image, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import successImage from '../../../assets/svg/success_big.svg';

const CongratulationsPartial = () => {
  const { t } = useTranslation();

  return (
    <>
      <Box
        sx={() => ({
          '&': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            flexGrow: 1,
            alignItems: 'center',
          },
        })}
      >
        <Box
          sx={() => ({
            '&': {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            },
          })}
        >
          <Image
            src={successImage}
            alt="Success checkmark"
            sx={(theme) => ({
              '&': {
                width: '180px',
                aspectRatio: '1',
                paddingTop: theme.other.spacing(4),
                paddingBottom: theme.other.spacing(4),
              },
            })}
          />
        </Box>
        <Box
          sx={(theme) => ({
            '&': {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              paddingBottom: theme.other.spacing(4),
            },
          })}
        >
          <Title
            sx={() => ({
              '&': {
                textAlign: 'center',
                fontWeight: 700,
                fontSize: '24px',
                color: '#363D40',
              },
            })}
          >
            {t('birthdayOffers.modalCongratulations')}
          </Title>
          <Title
            sx={() => ({
              '&': {
                textAlign: 'center',
                fontWeight: 700,
                fontSize: '24px',
                color: '#363D40',
              },
            })}
          >
            {t('birthdayOffers.modalCongratulationsSubtitle')}
          </Title>
        </Box>
      </Box>
    </>
  );
};

export default CongratulationsPartial;
