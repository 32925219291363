import { api } from '.';

export interface GetAccountBalanceResponsePayload {
  amount: number;
  hold: number;
  total: number;
  period: string;
  disc1: number;
  disc2: number;
  disc3: number;
  termBalances: [
    {
      startDate: string;
      endDate: string;
      moneyValidUntil: string;
      balance: number;
    },
  ];
}

const accountBalanceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAccountBalance: builder.mutation<GetAccountBalanceResponsePayload, number>({
      query: (accountId) => ({
        url: `/account-balance/${accountId}`,
      }),
    }),
  }),
});

export const { useGetAccountBalanceMutation } = accountBalanceApi;

export default accountBalanceApi.reducer;
