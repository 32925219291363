import { Box } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';

interface SendVerificationCodeProps {
  onClose: () => void;
  onSubmit: () => Promise<void> | void;
  loading?: boolean;
}

const SendVerificationCode = (props: SendVerificationCodeProps) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={(theme) => ({
        marginTop: theme.other.spacing(2),
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.other.spacing(1),
        [theme.fn.smallerThan(450)]: {
          flexDirection: 'column-reverse',
          gap: theme.other.spacing(2),
        },
      })}
    >
      <Button variant="outline" onClick={props.onClose} disabled={props.loading}>
        {t('myProfile.prompt.noThanks')}
      </Button>
      <Button onClick={props.onSubmit} loading={props.loading}>
        {t('createAccount.sendCode.sendCode')}
      </Button>
    </Box>
  );
};

export default SendVerificationCode;
