import { Button as MantineButton, ButtonProps as MantineButtonProps } from '@mantine/core';

function Button<C = 'button'>(props: MantineButtonProps<C>) {
  return (
    <MantineButton
      {...props}
      component={props.component as 'symbol' | undefined}
      sx={(theme) => ({
        borderRadius: '5px',
        fontWeight: 400,
        fontSize: '0.9rem',
        letterSpacing: '0.02em',
        height: 'auto',
        minHeight: '20px',
        paddingTop: theme.other.spacing(1),
        paddingBottom: theme.other.spacing(1),
        backgroundColor: theme.colors.maximaBlueDefault,
        transition: 'all 0.1s',
        '&:not(:disabled):active': {
          transform: 'translateY(0)',
        },
        '&:hover': {
          backgroundColor: theme.colors.maximaBlueLight,
        },
        '&.mantine-Button-outline': {
          backgroundColor: 'transparent',
          borderColor: '#80A4C7',
          color: theme.colors.maximaBlueDefault,
          '&:not(:disabled)': {
            '&:hover, &.activeLink': {
              backgroundColor: '#F2F6F9',
            },
          },
        },
        '& .mantine-Button-label': {
          whiteSpace: 'normal',
          minHeight: '20px',
          textAlign: 'center',
        },
      })}
    />
  );
}

export default Button;
