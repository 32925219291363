import { FormEvent, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Box } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import TextInput from '../../../../components/form/TextInput';
import Button from '../../../../components/Button';

interface EnterNewEmailFormProps {
  onSubmit: (email: string) => void;
  onCancel: () => void;
  loading?: boolean;
  disabled?: boolean;
  error?: string;
}

const EnterNewEmailForm = (props: EnterNewEmailFormProps) => {
  const { t } = useTranslation();

  const form = useForm({
    schema: yupResolver(
      Yup.object().shape({
        email: Yup.string()
          .email(t('landing.loginForm.emailInvalidError'))
          .required(t('registerCard.saveProfileData.emailRequiredError')),
      }),
    ),
    initialValues: {
      email: '',
    },
  });

  // eslint-disable-next-line
  const setFieldError = useCallback(form.setFieldError, []);

  useEffect(() => {
    if (props.error) {
      setFieldError('email', props.error);
    }
  }, [props.error, setFieldError]);

  const handleFormSubmit = (values: typeof form.values, e: FormEvent<Element>) => {
    e.stopPropagation();
    props.onSubmit(values.email);
  };

  return (
    <Box
      sx={(theme) => ({
        maxWidth: '500px',
        width: '100%',
        '&, & form': {
          display: 'flex',
          flexDirection: 'column',
        },
        '& .mantine-TextInput-root': {
          marginBottom: theme.other.spacing(3),
        },
      })}
    >
      <form noValidate onSubmit={form.onSubmit(handleFormSubmit)}>
        <TextInput
          name="email"
          label={t('myProfile.changeContact.newEmail')}
          required
          {...form.getInputProps('email')}
          disabled={props.loading || props.disabled}
        />
        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'flex-end',
            gap: theme.other.spacing(1),
            [theme.fn.smallerThan(450)]: {
              flexDirection: 'column-reverse',
              gap: theme.other.spacing(2),
            },
          })}
        >
          <Button variant="outline" onClick={props.onCancel} disabled={props.loading || props.disabled}>
            {t('myProfile.prompt.noThanks')}
          </Button>
          <Button type="submit" loading={props.loading} disabled={props.disabled}>
            {t('createAccount.sendCode.sendCode')}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default EnterNewEmailForm;
