import { Box, Text } from '@mantine/core';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import VerifyContactForm from './VerifyContact/VerifyContactForm';
import Button from '../../components/Button';
import HelpTooltip from '../../components/HelpTooltip';
import SubHeader from '../../components/SubHeader/SubHeader';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import useRoutes from '../../i18n/useRoutes';
import { ApiError, ErrorCode } from '../../models/ApiError';
import { setCardAccountStage, setVerificationToken } from '../../store/cardSlice';
import RecaptchaAction from '../../models/RecaptchaAction';
import { useSendConfirmationCodeMutation, useVerifyContactAndSendCodeMutation } from '../../store/api/verificationApi';

const SendCode = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const routes = useRoutes();
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const cardNumber = useAppSelector((state) => state.card.cardNumber);
  const unverifiedEmail = useAppSelector((state) => state.card.unverifiedEmail);
  const obscuredEmail = useAppSelector((state) => state.card.obscuredEmail);
  const verificationToken = useAppSelector((state) => state.card.verificationToken);

  const [sendCode, sendCodeQueryState] = useSendConfirmationCodeMutation();
  const [verifyContact, verifyContactQueryState] = useVerifyContactAndSendCodeMutation();

  const getSendCodeErrorMessage = useCallback(
    (error: ApiError) => {
      const errorCode = error.data?.message;
      if (error.status === 403) {
        return t('api.sessionExpiredError');
      }
      if (errorCode === ErrorCode.SendingCodeFailed) {
        return t('createAccount.sendCode.sendingCodeFailed');
      }
      if (errorCode === ErrorCode.RecaptchaError) {
        return t('api.recaptchaError');
      }
      return t('api.unknownError');
    },
    [t],
  );

  const getVerifyContactErrorMessage = useCallback(
    (error: ApiError) => {
      const errorCode = error.data?.message;
      if (error.status === 403) {
        return t('api.sessionExpiredError');
      }
      if (errorCode === ErrorCode.AccountDoesNotExist) {
        return t('createAccount.sendCode.accountDoesNotExistError');
      }
      if (errorCode === ErrorCode.EmailDoesNotMatch) {
        return t('createAccount.sendCode.emailDoesNotMatchError');
      }
      if (errorCode === ErrorCode.SendingCodeFailed) {
        return t('createAccount.sendCode.sendingCodeFailed');
      }
      if (errorCode === ErrorCode.RecaptchaError) {
        return t('api.recaptchaError');
      }
      return t('api.unknownError');
    },
    [t],
  );

  useEffect(() => {
    if (sendCodeQueryState.isSuccess) {
      dispatch(setVerificationToken(sendCodeQueryState.data.verificationToken));
      dispatch(setCardAccountStage('verifyCode'));
      toast(t('createAccount.sendCode.sendCodeSuccess'), { type: 'success' });
    }
  }, [sendCodeQueryState.isSuccess, sendCodeQueryState.data, dispatch, t]);

  useEffect(() => {
    if (sendCodeQueryState.isError && sendCodeQueryState.error) {
      const error = sendCodeQueryState.error as ApiError;
      const errorMessage = getSendCodeErrorMessage(error);
      toast(errorMessage, { type: 'error' });
    }
  }, [sendCodeQueryState.isError, sendCodeQueryState.error, getSendCodeErrorMessage]);

  useEffect(() => {
    if (verifyContactQueryState.isSuccess) {
      dispatch(setVerificationToken(verifyContactQueryState.data.verificationToken));
      dispatch(setCardAccountStage('verifyCode'));
    }
  }, [verifyContactQueryState.isSuccess, verifyContactQueryState.data, dispatch]);

  useEffect(() => {
    if (verifyContactQueryState.isError && verifyContactQueryState.error) {
      const error = verifyContactQueryState.error as ApiError;
      const errorMessage = getVerifyContactErrorMessage(error);
      toast(errorMessage, { type: 'error' });
    }
  }, [verifyContactQueryState.isError, verifyContactQueryState.error, getVerifyContactErrorMessage]);

  const handleNavigateToLogin = () => {
    navigate(routes.login);
  };

  const handleSendCode = useCallback(async () => {
    if (executeRecaptcha) {
      const recaptchaToken = await executeRecaptcha(RecaptchaAction.AccountCreationSendCode);
      sendCode({ verificationToken, recaptchaToken });
    }
  }, [executeRecaptcha, sendCode, verificationToken]);

  const handleVerifyContact = useCallback(
    async (email: string) => {
      if (executeRecaptcha) {
        const recaptchaToken = await executeRecaptcha(RecaptchaAction.AccountCreationVerifyEmailAndSendCode);
        verifyContact({ email, cardNumber, recaptchaToken });
      }
    },
    [executeRecaptcha, verifyContact, cardNumber],
  );

  const getEmailDescriptionText = () => {
    if (obscuredEmail) {
      return `${t('createAccount.sendCode.sendCodeToEmail')} ${obscuredEmail}`;
    }
    if (unverifiedEmail) {
      return `${t('createAccount.sendCode.verifyProfileEmail')} ${unverifiedEmail}`;
    }
    return '';
  };

  const getHelpDescriptionText = () => {
    if (unverifiedEmail) {
      return t('createAccount.sendCode.incorrectEmail');
    }
    if (obscuredEmail) {
      return t('createAccount.sendCode.emailNotReceived');
    }
    return '';
  };

  const getHelpDescriptionTooltipText = () => {
    if (unverifiedEmail) {
      return t('createAccount.sendCode.incorrectEmailTooltip');
    }
    if (obscuredEmail) {
      return t('createAccount.sendCode.emailNotReceivedTooltip');
    }
    return '';
  };

  return (
    <Box className="container">
      <SubHeader title={t('createAccount.subHeaderTitle')} subTitle={t('createAccount.sendCode.subHeaderSubTitle')} />
      <Box
        className="content"
        sx={(theme) => ({
          paddingTop: theme.other.spacing(10),
          paddingBottom: theme.other.spacing(12),
          flexDirection: 'column',
          alignItems: 'center',
          gap: theme.other.spacing(5),
        })}
      >
        <Text
          sx={(theme) => ({
            fontSize: '1.25rem',
            textAlign: 'center',
            color: theme.colors.brandGray,
          })}
        >
          {t('createAccount.sendCode.emailVerification')}
        </Text>
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.other.spacing(2),
          })}
        >
          <Text
            sx={(theme) => ({
              textAlign: 'center',
              wordBreak: 'break-word',
              color: theme.colors.brandGray,
            })}
          >
            {getEmailDescriptionText()}
          </Text>
          {obscuredEmail && (
            <VerifyContactForm
              onSubmit={handleVerifyContact}
              loading={verifyContactQueryState.isLoading}
              error={(verifyContactQueryState.error as ApiError)?.data?.message}
            />
          )}
        </Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.other.spacing(2),
          })}
        >
          {!obscuredEmail && (
            <Button onClick={handleSendCode} loading={sendCodeQueryState.isLoading}>
              {t('createAccount.sendCode.sendCode')}
            </Button>
          )}
          <Box
            sx={(theme) => ({
              marginTop: theme.other.spacing(1),
              display: 'flex',
              alignItems: 'center',
              gap: theme.other.spacing(0.5),
              [theme.fn.smallerThan(500)]: {
                marginTop: theme.other.spacing(3),
              },
            })}
          >
            <Text sx={(theme) => ({ textAlign: 'center', color: theme.colors.brandGray })}>
              {getHelpDescriptionText()}
            </Text>
            <HelpTooltip label={getHelpDescriptionTooltipText()} position="bottom" />
          </Box>
        </Box>
        <Button
          variant="outline"
          onClick={handleNavigateToLogin}
          disabled={sendCodeQueryState.isLoading || verifyContactQueryState.isLoading}
        >
          {t('createAccount.sendCode.doNotCreateAccount')}
        </Button>
      </Box>
    </Box>
  );
};

export default SendCode;
