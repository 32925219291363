import { default as OriginalCookieBot } from 'react-cookiebot';
import { useTranslation } from 'react-i18next';
import { COOKIEBOT_DOMAIN_GROUP_ID } from '../config';

const CookieBot = () => {
  const { i18n } = useTranslation();
  const hasCookieBot = document.getElementById('CookieBot');
  if (hasCookieBot) return null;
  return <OriginalCookieBot domainGroupId={COOKIEBOT_DOMAIN_GROUP_ID} language={i18n.language.toUpperCase()} />;
};

export default CookieBot;
