import { useCallback, useEffect } from 'react';
import { Box, Title } from '@mantine/core';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import EnterPasswordForm from './EnterPasswordForm';
import SubHeader from '../../../components/SubHeader/SubHeader';
import { useForgotPasswordChangePasswordMutation } from '../../../store/api/forgotPasswordApi';
import RecaptchaAction from '../../../models/RecaptchaAction';
import { useAppSelector } from '../../../hooks/store';
import useAppLogin from '../../../hooks/useAppLogin';
import { ApiError, ErrorCode } from '../../../models/ApiError';

const ChangePassword = () => {
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [login] = useAppLogin();

  const verificationToken = useAppSelector((state) => state.forgotPassword.verificationToken);

  const [changePassword, changePasswordQueryState] = useForgotPasswordChangePasswordMutation();

  const handleSubmitPassword = useCallback(
    async (newPassword: string) => {
      if (executeRecaptcha) {
        const recaptchaToken = await executeRecaptcha(RecaptchaAction.ForgotPasswordChangePassword);
        changePassword({ verificationToken, newPassword, recaptchaToken });
      }
    },
    [executeRecaptcha, changePassword, verificationToken],
  );

  const getChangePasswordErrorMessage = useCallback(
    (error: ApiError) => {
      const errorCode = error.data?.message;
      if (error.status === 403) {
        return t('api.sessionExpiredError');
      }
      if (errorCode === ErrorCode.PasswordChangeFailed) {
        return t('forgotPassword.changePassword.passwordChangeError');
      }
      if (errorCode === ErrorCode.LoginFailed) {
        return t('createAccount.passwordCreation.loginFailedError');
      }
      if (errorCode === ErrorCode.RecaptchaError) {
        return t('api.recaptchaError');
      }
      return t('api.unknownError');
    },
    [t],
  );

  useEffect(() => {
    if (changePasswordQueryState.isSuccess) {
      login(changePasswordQueryState.data);
      toast(t('forgotPassword.changePassword.passwordChangeSuccess'), { type: 'success' });
    }
  }, [changePasswordQueryState.isSuccess, changePasswordQueryState.data, login, t]);

  useEffect(() => {
    if (changePasswordQueryState.isError) {
      const error = changePasswordQueryState.error as ApiError;
      const errorMessage = getChangePasswordErrorMessage(error);
      toast(errorMessage, { type: 'error' });
    }
  }, [changePasswordQueryState.isError, changePasswordQueryState.error, getChangePasswordErrorMessage, t]);

  return (
    <Box className="container">
      <SubHeader title={t('forgotPassword.changePassword.subHeaderTitle')} />
      <Box
        className="content"
        sx={(theme) => ({
          paddingTop: theme.other.spacing(10),
          paddingBottom: theme.other.spacing(6),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: '55vh',
          maxHeight: '600px',
          [theme.other.smallerThanHeight(900)]: {
            paddingTop: theme.other.spacing(6),
          },
          [theme.other.largerThanHeight(1100)]: {
            paddingTop: theme.other.spacing(15),
          },
        })}
      >
        <Box
          sx={(theme) => ({
            width: '100%',
            maxWidth: '800px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: theme.other.spacing(4),
          })}
        >
          <Title
            order={2}
            sx={(theme) => ({
              fontSize: '1.2rem',
              fontFamily: 'ProximaNova',
              letterSpacing: '0.02em',
              textAlign: 'center',
              color: theme.colors.brandGray,
              [theme.fn.smallerThan(960)]: {
                fontSize: '1rem',
              },
            })}
          >
            {t('forgotPassword.changePassword.formTitle')}
          </Title>
          <EnterPasswordForm
            submitButtonLabel={t('forgotPassword.changePassword.save')}
            onSubmit={handleSubmitPassword}
            loading={changePasswordQueryState.isLoading}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ChangePassword;
