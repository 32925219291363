import { api, QueryParamKey, RecaptchaProtected } from '.';

interface RegisterCardAndCheckProfileDataExistenceResponsePayload {
  email?: string;
  profileDataExists: boolean;
}

interface RegisterCardAndCheckProfileDataExistenceQueryPayload extends RecaptchaProtected {
  cardNumber: string;
}

interface CardStateResponsePayload {
  accountId: number;
  state: number;
}

interface CardStateQueryPayload {
  cardNumber: string;
}

interface JoinCardsQueryPayload {
  cardNr: string;
  newCardNumber: string;
}

const cardApi = api.injectEndpoints({
  endpoints: (builder) => ({
    registerCardAndCheckProfileDataExistence: builder.mutation<
      RegisterCardAndCheckProfileDataExistenceResponsePayload,
      RegisterCardAndCheckProfileDataExistenceQueryPayload
    >({
      query: ({ cardNumber, recaptchaToken }) => ({
        url: `/card/${cardNumber}/obscured-email`,
        params: {
          [QueryParamKey.recaptchaToken]: recaptchaToken,
        },
        method: 'GET',
      }),
    }),
    getCardState: builder.mutation<CardStateResponsePayload, CardStateQueryPayload>({
      query: ({ cardNumber }) => ({
        url: `/card/${cardNumber}/state`,
        method: 'GET',
      }),
    }),
    blockCard: builder.mutation<CardStateResponsePayload, CardStateQueryPayload>({
      query: ({ cardNumber }) => ({
        url: `/card/${cardNumber}/block`,
        method: 'POST',
      }),
    }),
    activateCard: builder.mutation<CardStateResponsePayload, CardStateQueryPayload>({
      query: ({ cardNumber }) => ({
        url: `/card/${cardNumber}/activate`,
        method: 'POST',
      }),
    }),
    joinCards: builder.mutation<CardStateResponsePayload, JoinCardsQueryPayload>({
      query: ({ cardNr, newCardNumber }) => ({
        url: `/card/${cardNr}/join`,
        method: 'POST',
        body: {
          newCardNumber,
        },
      }),
    }),
  }),
});

export const {
  useRegisterCardAndCheckProfileDataExistenceMutation,
  useGetCardStateMutation,
  useBlockCardMutation,
  useActivateCardMutation,
  useJoinCardsMutation,
} = cardApi;

export default cardApi.reducer;
