import { Box, Title, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { GetAccountBalanceResponsePayload } from '../../store/api/accountBalanceApi';
import useConvertCentsToEur from '../../utility/hooks/useConvertCentsToEur';
import useFormatDate from '../../utility/hooks/useFormatDate';

interface AccountBalanceProps {
  accountBalanceData?: GetAccountBalanceResponsePayload;
  isError?: boolean;
}

const getExpiringBalance = (
  termBalances: { endDate: string; balance: number; moneyValidUntil: string }[] | undefined,
) => {
  if (!termBalances) return null;

  const expiringBalance = [...termBalances].sort(
    (balance1, balance2) => new Date(balance1.endDate).getTime() - new Date(balance2.endDate).getTime(),
  )[0];

  if (!expiringBalance || !expiringBalance.balance || !expiringBalance.moneyValidUntil) return null;

  const expirationDate = new Date(expiringBalance.moneyValidUntil);
  const currentDate = new Date();

  if (expirationDate.getFullYear() == currentDate.getFullYear() && currentDate.getTime() < expirationDate.getTime())
    return expiringBalance;

  return null;
};

const AccountBalance = ({ accountBalanceData, isError }: AccountBalanceProps) => {
  const { t } = useTranslation();
  const convertCentsToEur = useConvertCentsToEur();
  const formatDate = useFormatDate();
  const expiringMoney = getExpiringBalance(accountBalanceData?.termBalances);

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={(theme) => ({
          fontFamily: 'ProximaNova',
          marginTop: theme.other.spacing(3),
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            [theme.fn.smallerThan(600)]: {
              alignItems: 'flex-end',
              flexWrap: 'wrap',
            },
          })}
        >
          <Text
            sx={(theme) => ({
              fontSize: '1.3rem',
              color: theme.colors.brandGray,
              fontFamily: 'ProximaNovaSemibold',
              marginRight: theme.other.spacing(0.8),
            })}
          >
            {t('purchaseHistory.maximaMoneyBalance')}
          </Text>
          <Title
            sx={(theme) => ({
              color: theme.colors.brandBlue,
              fontSize: '1.6rem',
              fontFamily: 'ProximaNovaSemibold',
              letterSpacing: '0.01em',
              [theme.fn.smallerThan(800)]: {
                fontSize: '2.2rem',
              },
              [theme.fn.smallerThan(600)]: {
                fontSize: '1.8rem',
              },
            })}
          >
            {accountBalanceData ? convertCentsToEur(accountBalanceData.total) : '-'}
          </Title>
        </Box>
        {isError && (
          <Box>
            <Text
              sx={(theme) => ({
                color: theme.colors.errorDefault,
              })}
            >
              {t('purchaseHistory.maximaMoneyError')}
            </Text>
          </Box>
        )}
      </Box>

      {expiringMoney && !isError && (
        <Box>
          <Text>
            {t('purchaseHistory.maximaMoneyValidUntil', {
              amount: convertCentsToEur(expiringMoney.balance),
              date: formatDate(expiringMoney.moneyValidUntil),
            })}
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default AccountBalance;
