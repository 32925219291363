import { Grid } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form/lib/use-form';
import { useTranslation } from 'react-i18next';
import TextInput from '../../../components/form/TextInput';
import { ProfileDataFormValues } from '../../../utility/saveProfileUtils';

interface CustomerNameRowProps {
  form: UseFormReturnType<ProfileDataFormValues>;
  disabled?: boolean;
}

const CustomerNameRow = ({ form, disabled }: CustomerNameRowProps) => {
  const { t } = useTranslation();

  return (
    <Grid
      gutter="lg"
      sx={(theme) => ({
        marginTop: theme.other.spacing(2),
        alignItems: 'flex-start',
      })}
    >
      <Grid.Col sm={6}>
        <TextInput
          {...form.getInputProps('firstName')}
          label={t('registerCard.saveProfileData.firstName')}
          placeholder={t('registerCard.saveProfileData.firstName')}
          disabled={disabled}
        />
      </Grid.Col>
      <Grid.Col sm={6}>
        <TextInput
          {...form.getInputProps('lastName')}
          label={t('registerCard.saveProfileData.lastName')}
          placeholder={t('registerCard.saveProfileData.lastName')}
          disabled={disabled}
        />
      </Grid.Col>
    </Grid>
  );
};

export default CustomerNameRow;
