import { Box } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { PdiList } from '../../../store/api/purchaseHistoryApi';
import { groupBy, sum } from '../../../utility';
import useFormatCurrency from '../../../utility/hooks/useFormatCurrency';

interface DiscountLinesProps {
  data: PdiList;
  totalDiscounts: number;
}

const DiscountLines = ({ data, totalDiscounts }: DiscountLinesProps) => {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();

  const discountGroups = groupBy(data.Pdi, (discount) => discount.SchemeId);
  const discounts = Object.keys(discountGroups)
    .filter((schemeId) => schemeId != '0')
    .map((schemeId) => {
      return {
        DiscountTitle: discountGroups[schemeId][0].DiscountTitle,
        Discount: sum(discountGroups[schemeId].map((discount) => Number(discount.Discount))),
      };
    });

  const discountNames = discounts.map((discount, i) => {
    const { DiscountTitle } = discount;
    return <Box key={i}>{DiscountTitle}</Box>;
  });

  const discountAmounts = discounts.map((pdi, i) => {
    const { Discount } = pdi;
    return <Box key={i}>{`-${formatCurrency(Discount)}`}</Box>;
  });

  return (
    <Box
      sx={(theme) => ({
        padding: `${theme.other.spacing(1.5)} 0`,
      })}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginRight: theme.other.spacing(5),
            textAlign: 'right',
          })}
        >
          <Box>{t('purchaseHistory.discount.total')}</Box>
          {discountNames}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            fontFamily: 'ProximaNovaBold',
            textAlign: 'right',
          }}
        >
          <Box>{`-${formatCurrency(totalDiscounts)}`}</Box>
          {discountAmounts}
        </Box>
      </Box>
    </Box>
  );
};

export default DiscountLines;
