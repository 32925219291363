import { ChangeEvent, useCallback, useEffect } from 'react';
import * as Yup from 'yup';
import { Link as RouterLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Box, Text } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import Button from '../../../components/Button';
import TextInput from '../../../components/form/TextInput';
import Link from '../../../components/Link';
import { checkIfHasPhoneOrEmail, ESTONIAN_MOBILE_NUMBER_REGEX } from '../../../utility';
import useRoutes from '../../../i18n/useRoutes';
import PhoneNumberInput from '../../../components/form/PhoneNumberInput';
import RecaptchaAction from '../../../models/RecaptchaAction';
import { ApiError, ErrorCode } from '../../../models/ApiError';
import { useAuthenticateUserMutation } from '../../../store/api/authApi';
import useAppLogin from '../../../hooks/useAppLogin';

interface LoginFormValues {
  email: string;
  phone: string;
  password: string;
}

const LoginForm = (props: { cancel: () => void }) => {
  const routes = useRoutes();
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [login] = useAppLogin();

  const [authenticate, authenticateQueryState] = useAuthenticateUserMutation();

  const isLoading = authenticateQueryState.isLoading;

  const form = useForm<LoginFormValues>({
    schema: yupResolver(
      Yup.object().shape({
        email: Yup.string()
          .email(t('landing.loginForm.emailInvalidError'))
          .test('empty-field', t('landing.loginForm.emailOrPhoneRequiredError'), checkIfHasPhoneOrEmail),
        phone: Yup.string().when('email', {
          is: (email: string) => !email || email.length === 0,
          then: Yup.string()
            .matches(ESTONIAN_MOBILE_NUMBER_REGEX, t('landing.loginForm.mobileInvalidError'))
            .test('empty-field', t('landing.loginForm.emailOrPhoneRequiredError'), checkIfHasPhoneOrEmail),
          otherwise: Yup.string(),
        }),
        password: Yup.string().required(t('landing.loginForm.passwordRequiredError')),
      }),
    ),
    initialValues: {
      email: '',
      phone: '',
      password: '',
    },
  });

  const getLoginErrorMessage = useCallback(
    (error: ApiError) => {
      const errorCode = error.data?.message;
      if (error.status === 403) {
        return t('api.sessionExpiredError');
      }
      if (errorCode === ErrorCode.RecaptchaError) {
        return t('api.recaptchaError');
      }
      return t('api.unknownError');
    },
    [t],
  );

  // eslint-disable-next-line
  const setFieldError = useCallback(form.setFieldError, []);
  const formValues = form.values;

  useEffect(() => {
    if (authenticateQueryState.isSuccess) {
      login(authenticateQueryState.data);

      // Google Tag Manager event
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'login',
      });
    }
  }, [authenticateQueryState.isSuccess, authenticateQueryState.data, login]);

  useEffect(() => {
    if (authenticateQueryState.isError && authenticateQueryState.error) {
      const error = authenticateQueryState.error as ApiError;
      if (error.status === 401) {
        if (formValues.email) {
          setFieldError('email', t('landing.loginForm.invalidLoginCredentials'));
        }
        if (formValues.phone) {
          setFieldError('phone', t('landing.loginForm.invalidLoginCredentials'));
        }
        setFieldError('password', t('landing.loginForm.invalidLoginCredentials'));
      }
    }
  }, [authenticateQueryState.isError, authenticateQueryState.error, setFieldError, formValues, t]);

  useEffect(() => {
    if (authenticateQueryState.isError && authenticateQueryState.error) {
      const error = authenticateQueryState.error as ApiError;
      if (error.status !== 401) {
        const errorMessage = getLoginErrorMessage(error);
        toast(errorMessage, { type: 'error' });
      }
    }
  }, [authenticateQueryState.isError, authenticateQueryState.error, getLoginErrorMessage, t]);

  const resetFields = () => {
    authenticateQueryState.reset();
    form.clearErrors();
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue('email', e.target.value);
    form.setFieldValue('phone', '');
    if (form.errors.email) {
      resetFields();
    }
  };

  const handlePhoneNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    form.setFieldValue('phone', value);
    form.setFieldValue('email', '');
    if (form.errors.phone) {
      resetFields();
    }
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    form.setFieldValue('password', value);
    if (form.errors.password) {
      resetFields();
    }
  };

  const handleFormSubmit = useCallback(
    async (values: LoginFormValues) => {
      if ((values.email || values.phone) && values.password && executeRecaptcha) {
        const recaptchaToken = await executeRecaptcha(RecaptchaAction.Login);
        const emailOrPhone = values.email || values.phone;
        authenticate({ emailOrPhone, password: values.password, recaptchaToken });
      }
    },
    [executeRecaptcha, authenticate],
  );

  return (
    <Box
      sx={(theme) => ({
        '&, & form': {
          display: 'flex',
          flexDirection: 'column',
        },
        '& .mantine-TextInput-root': {
          marginBottom: theme.other.spacing(1),
        },
        '& .mantine-Checkbox-root': {
          marginTop: theme.other.spacing(1.5),
          marginBottom: theme.other.spacing(2),
        },
        '& .mantine-Button-root': {
          marginTop: theme.other.spacing(1.75),
          marginBottom: theme.other.spacing(2),
        },
      })}
    >
      <form noValidate onSubmit={form.onSubmit(handleFormSubmit)}>
        <TextInput
          name="email"
          label={t('landing.loginForm.email')}
          placeholder={t('landing.loginForm.emailPlaceholder')}
          autoComplete="username"
          error={form.errors.email}
          value={form.values.email}
          onChange={handleEmailChange}
          required={form.values.email.length > 0}
          disabled={isLoading}
        />
        <Text
          sx={(theme) => ({
            marginTop: theme.other.spacing(0.5),
            marginBottom: theme.other.spacing(0.5),
            color: theme.colors.maximaNavyBlueDefault,
            fontSize: '0.9rem',
            fontFamily: 'ProximaNovaSemibold',
          })}
        >
          {t('landing.loginForm.or')}
        </Text>
        <PhoneNumberInput
          name="phone"
          label={t('landing.loginForm.mobile')}
          error={form.errors.phone}
          value={form.values.phone}
          onChange={handlePhoneNumberChange}
          required={(form.values.phone || '').length > 0}
          disabled={isLoading}
        />
        <TextInput
          name="password"
          type="password"
          autoComplete="current-password"
          label={t('landing.loginForm.password')}
          placeholder={t('landing.loginForm.password')}
          error={form.errors.password}
          value={form.values.password}
          onChange={handlePasswordChange}
          required
          disabled={isLoading}
        />
        <Button type="submit" loading={isLoading}>
          {t('landing.loginForm.login')}
        </Button>
      </form>
      <Button type="button" onClick={props.cancel} variant="outline">
        {t('landing.formCard.hasPersonalCode')}
      </Button>
      <Link component={RouterLink} to={routes.forgotPassword} align="center">
        {t('landing.loginForm.forgotPassword')}
      </Link>
    </Box>
  );
};

export default LoginForm;
