import {
  DateRangePicker as MantineDateRangePicker,
  DateRangePickerProps as MantineDateRangePickerProps,
} from '@mantine/dates';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useTranslation } from 'react-i18next';
import 'dayjs/locale/et';
import 'dayjs/locale/ru';
import 'dayjs/locale/en';

const DateRangeInput = (props: MantineDateRangePickerProps) => {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <MantineDateRangePicker
      key={language}
      locale={language}
      inputFormat={'DD.MM.YYYY'}
      radius={5}
      sx={(theme) => ({
        '& .mantine-DateRangePicker-wrapper': {
          minWidth: '214px',
        },
        '& .mantine-DateRangePicker-label': {
          color: theme.colors.maximaBlueLight,
          letterSpacing: '0.02em',
          fontSize: '0.75rem',
        },
        '& .mantine-DateRangePicker-input': {
          paddingTop: '2px',
          transition: 'all 0.1s',
          borderRadius: '5px',
          color: theme.colors.brandGray,
          fontWeight: 400,
          fontSize: '0.875rem',
          '&:not(.mantine-DateRangePicker-disabled, .mantine-DateRangePicker-invalid)': {
            borderColor: theme.colors.maximaBlueLight,
          },
          '&.mantine-DateRangePicker-invalid': {
            borderColor: theme.colors.errorLight,
          },
          '&::placeholder': {
            color: theme.colors.maximaBlueLight,
          },
          '&:focus': {
            borderColor: theme.colors.orange,
          },
        },
        '& .mantine-DateRangePicker-icon': {
          color: props.disabled ? theme.colors.disabledIcon : theme.colors.maximaBlueDefault,
          '& .MuiSvgIcon-root': {
            color: props.disabled ? theme.colors.disabledIcon : theme.colors.maximaBlueDefault,
          },
          '&.mantine-DateRangePicker-disabled': {
            '&, & *': {
              color: theme.colors.disabledText,
            },
          },
        },
        '& .mantine-DateRangePicker-error': {
          color: theme.colors.errorLight,
        },
      })}
      icon={<CalendarMonthIcon fontSize="small" />}
      {...props}
    />
  );
};

export default DateRangeInput;
