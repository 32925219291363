import { Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { Line } from '../../../store/api/purchaseHistoryApi';
import useFormatCurrency from '../../../utility/hooks/useFormatCurrency';

interface ProductLinesProps {
  data: Line[];
}

const ProductLines = ({ data }: ProductLinesProps) => {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();
  const receiptLines = data.map(
    ({ name, quantity = '', measure = '', price, petName, Disc, petBottleCount, petBottlePrice }, i) => {
      return (
        <View style={{ borderBottom: '1 dashed #aeaeae', marginBottom: 4 }} key={i}>
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ width: '60%', lineHeight: 1.55 }}>{name}</Text>
            <Text style={{ width: '20%', flexDirection: 'row', textAlign: 'right' }}>{`${quantity} ${measure} `}</Text>
            <Text style={{ width: '20%', flexDirection: 'row', textAlign: 'right', fontWeight: 'bold' }}>
              {formatCurrency(price)}
            </Text>
          </View>
          {(Disc && (
            <View style={{ flexDirection: 'row' }}>
              <Text style={{ opacity: 0.6, width: '80%', lineHeight: 1.55 }}>
                {Disc.name || t('purchaseHistory.discount.default')}
              </Text>
              <Text
                style={{ opacity: 0.6, width: '20%', flexDirection: 'row', textAlign: 'right', fontWeight: 'bold' }}
              >
                {`-${formatCurrency(Disc.discount)}`}
              </Text>
            </View>
          )) || (
            <View>
              <Text> </Text>
            </View>
          )}
          {petName !== undefined && (
            <View style={{ flexDirection: 'row' }}>
              <Text style={{ width: '60%' }}>{`${petName}`}</Text>
              <Text style={{ width: '20%', flexDirection: 'row', textAlign: 'right' }}>{`${petBottleCount} ${t(
                'purchaseHistory.productLines.pcs',
              )}`}</Text>
              <Text style={{ width: '20%', flexDirection: 'row', textAlign: 'right', fontWeight: 'bold' }}>
                {formatCurrency(petBottlePrice)}
              </Text>
            </View>
          )}
        </View>
      );
    },
  );

  return <View>{receiptLines}</View>;
};

export default ProductLines;
