import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text, Title } from '@mantine/core';
import BlockOrActivateCard from './BlockOrActivateCard';
import LoyaltyExit from './LoyaltyExit';
import Loading from './Loading';
import SubHeaderWhenAuthenticated from '../../components/SubHeader/SubHeaderWhenAuthenticated';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { setCardState } from '../../store/cardSlice';
import { useGetCardStateMutation } from '../../store/api/cardApi';

const CardSettings = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const fetchInitialized = useRef(false);

  const cardState = useAppSelector((state) => state.card.cardState);
  const cardNumber = useAppSelector((state) => state.card.cardNumber);

  const [getCardState, queryState] = useGetCardStateMutation();

  useEffect(() => {
    if (cardState === null && cardNumber && !fetchInitialized.current) {
      fetchInitialized.current = true;
      getCardState({ cardNumber });
    }
  }, [cardState, cardNumber, getCardState]);

  useEffect(() => {
    if (queryState.isSuccess) {
      fetchInitialized.current = false;
      dispatch(setCardState(queryState.data.state));
    }
  }, [queryState.isSuccess, queryState.data, dispatch]);

  return (
    <Box className="container">
      <SubHeaderWhenAuthenticated title={t('cardSettings.subHeaderTitle')} />
      <Box
        className="content"
        sx={(theme) => ({
          paddingTop: theme.other.spacing(10),
          paddingBottom: theme.other.spacing(6),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: '45vh',
          [theme.other.smallerThanHeight(900)]: {
            paddingTop: theme.other.spacing(6),
          },
          [theme.other.largerThanHeight(1100)]: {
            paddingTop: theme.other.spacing(15),
          },
          [theme.fn.smallerThan(768)]: {
            paddingTop: theme.other.spacing(8),
            minHeight: '40vh',
          },
        })}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: '960px',
          }}
        >
          {queryState.isLoading ? (
            <Loading />
          ) : cardState !== null ? (
            <Box
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                gap: theme.other.spacing(2),
                '& .mantine-Button-root': {
                  paddingLeft: theme.other.spacing(1),
                  paddingRight: theme.other.spacing(1),
                },
                [theme.fn.smallerThan(900)]: {
                  paddingLeft: 0,
                  paddingRight: 0,
                },
              })}
            >
              <BlockOrActivateCard cardState={cardState} />
              <LoyaltyExit />
            </Box>
          ) : (
            <Box
              sx={(theme) => ({
                marginTop: theme.other.spacing(2),
                display: 'flex',
                flexFlow: 'column wrap',
                gap: theme.other.spacing(1),
              })}
            >
              <Title
                order={3}
                sx={(theme) => ({
                  fontSize: '1.3rem',
                  color: theme.colors.errorDefault,
                  textAlign: 'center',
                  fontFamily: 'ProximaNovaBold',
                  letterSpacing: '0.02em',
                  [theme.fn.smallerThan(600)]: {
                    fontSize: '1.1rem',
                  },
                })}
              >
                {t('api.errorTitle')}
              </Title>
              <Text
                sx={(theme) => ({
                  textAlign: 'center',
                  color: theme.colors.brandGray,
                })}
              >
                {t('cardSettings.cardStateFetchError')}
              </Text>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CardSettings;
