import { Navigate } from 'react-router';
import SendCode from './SendCode';
import VerifyCode from './VerifyCode';
import CreatePassword from './CreatePassword';
import { useAppSelector } from '../../hooks/store';
import useRoutes from '../../i18n/useRoutes';
import useScrollToTopOnStateChange from '../../utility/hooks/useScrollToTopOnStateChange';

const CreateAccount = () => {
  const cardAccountStage = useAppSelector((state) => state.card.cardAccountStage);
  const routes = useRoutes();
  useScrollToTopOnStateChange(cardAccountStage);

  if (cardAccountStage === 'verifyEmail') {
    return <SendCode />;
  }
  if (cardAccountStage === 'verifyCode') {
    return <VerifyCode />;
  }
  if (cardAccountStage === 'addPassword') {
    return <CreatePassword />;
  }

  return <Navigate to={routes.login} />;
};

export default CreateAccount;
