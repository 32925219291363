import { PopoverProps as MantinePopoverProps, Box, Image, Text } from '@mantine/core';
import infoIcon from '../assets/svg/icons/Info.svg';

interface InfoBoxProps extends Omit<MantinePopoverProps, 'children' | 'opened' | 'target'> {
  text: string;
}

const InfoBox = (props: InfoBoxProps) => {
  return (
    <Box
      sx={() => ({
        display: 'flex',
        padding: '16px',
        alignItems: 'flex-start',
        gap: '8px',
        alignSelf: 'stretch',
        borderRadius: '8px',
        background: 'var(--illustration-shadow, #E6ECF3)',
      })}
    >
      <Image
        src={infoIcon}
        sx={() => ({
          width: '24px',
          height: '24px',
        })}
      ></Image>
      <Text
        sx={(theme) => ({
          flex: '1 0 0',
          color: theme.colors.maximaBlueDefault,
          fontFeatureSettings: "'clig' off, 'liga' off",
          fontFamily: 'ProximaNova',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '22px' /* 137.5% */,
        })}
      >
        {props.text}
      </Text>
    </Box>
  );
};

export default InfoBox;
