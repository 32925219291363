import { Box, Text, Image, createStyles } from '@mantine/core';
import CircleBlue from '../../../assets/svg/circle_blue.svg';
import CircleGray from '../../../assets/svg/circle_gray.svg';
import CircleBlueSolid from '../../../assets/svg/circle_blue_solid.svg';
import CheckWhite from '../../../assets/svg/check_white.svg';
import { pad } from '../../../utility';

enum StepState {
  Active = 1,
  Complete = 2,
  Inactive = 3,
}

interface StepProps {
  // onSubmit: (email: string) => void;
  // onCancel: () => void;
  title: string;
  number: number;
  state: StepState;
}

const useStyles = createStyles((theme) => ({
  activeStep: {
    color: theme.colors.maximaBlueDefault,
  },
  inactiveStep: {
    color: theme.colors.midGrey,
  },
}));

const Step = (props: StepProps) => {
  const { classes } = useStyles();

  return (
    <Box
      sx={() => ({
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
      })}
    >
      <Box sx={() => ({})}>
        <Box
          sx={() => ({
            width: '32px',
            height: '32px',
            flexShrink: 0,
            position: 'relative',
          })}
        >
          {props.state == StepState.Inactive ? (
            <Image
              src={CircleGray}
              sx={() => ({
                width: '32px',
                height: '32px',
                flexShrink: 0,
                position: 'absolute',
              })}
            ></Image>
          ) : props.state == StepState.Active ? (
            <Image
              src={CircleBlue}
              sx={() => ({
                width: '32px',
                height: '32px',
                flexShrink: 0,
                position: 'absolute',
              })}
            ></Image>
          ) : (
            <>
              <Image
                src={CircleBlueSolid}
                sx={() => ({
                  width: '32px',
                  height: '32px',
                  flexShrink: 0,
                  position: 'absolute',
                })}
              ></Image>
              <Image
                src={CheckWhite}
                sx={() => ({
                  width: '24px',
                  height: '24px',
                  flexShrink: 0,
                  top: '4px',
                  left: '4px',
                  position: 'absolute',
                })}
              ></Image>
            </>
          )}

          {props.state != StepState.Complete ? (
            <Text
              className={props.state != StepState.Inactive ? classes.activeStep : classes.inactiveStep}
              sx={() => ({
                textAlign: 'center',
                fontFamily: 'Inter',
                fontSize: '13px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 'normal',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              })}
            >
              {pad(props.number, 2)}
            </Text>
          ) : (
            <></>
          )}
        </Box>
      </Box>
      <Box
        sx={() => ({
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          gap: '8px',
        })}
      >
        <Text
          className={props.state != StepState.Inactive ? classes.activeStep : classes.inactiveStep}
          sx={() => ({
            textAlign: 'center',
            fontFamily: 'ProximaNova',
            fontSize: '1em',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '1.375em',
          })}
        >
          {props.title}
        </Text>
      </Box>
    </Box>
  );
};

export default Step;
export { StepState };
