import { configureStore, combineReducers, ThunkAction, Action } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import forgotPasswordReducer from './forgotPasswordSlice';
import apiReducer, { api } from './api';
import cardReducer from './cardSlice';
import jwtMiddleware from './middlewares/jwtMiddleware';
import strongAuthRegisterReducer from './strongAuthRegisterSlice';
import profileLandingReducer from './profileLandingSlice';
import strongAuthReducer from './strongAuthSlice';

const rootReducer = combineReducers({
  user: userReducer,
  forgotPassword: forgotPasswordReducer,
  card: cardReducer,
  api: apiReducer,
  strongAuthRegister: strongAuthRegisterReducer,
  profileLanding: profileLandingReducer,
  strongAuth: strongAuthReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware, jwtMiddleware.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
