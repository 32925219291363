import { ReactNode } from 'react';
import { Container as MantineContainer } from '@mantine/core';

interface Props {
  children: ReactNode;
}

const Container = (props: Props) => {
  return (
    <MantineContainer
      fluid
      px={0}
      sx={(theme) => ({
        margin: 0,
        overflowX: 'hidden',
        display: 'flex',
        flex: '1 0 auto',
        '& .container': {
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
        '& .subheader, .subfooter, .content, .gutters': {
          paddingLeft: '8%',
          paddingRight: '8%',
          '&.centered': {
            justifyContent: 'center',
          },
          [theme.fn.smallerThan(960)]: {
            paddingLeft: '4%',
            paddingRight: '4%',
          },
        },
        '& .content': {
          display: 'flex',
          flex: '1 1 auto',
          backgroundColor: theme.colors.contentBackground,
        },
        '& .subheader, .subfooter': {
          backgroundColor: theme.colors.maximaBlueDefault,
        },
      })}
    >
      {props.children}
    </MantineContainer>
  );
};

export default Container;
