interface ConnectorProps {
  isComplete: boolean;
  isHorizontal: boolean;
}

const Connector = (props: ConnectorProps) => {
  return (
    <svg width="32" height="50" viewBox="0 0 32 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="15"
        y="50"
        width="50"
        height="2"
        transform="rotate(-90 15 50)"
        fill={props.isComplete ? '#004990' : '#A1AEBE'}
      />
    </svg>
  );
};

const HorizontalConnector = (props: ConnectorProps) => {
  return (
    <svg width="400" height="2" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0" y="0" width="400" height="2" fill={props.isComplete ? '#004990' : '#A1AEBE'} />
    </svg>
  );
};

export { HorizontalConnector };
export default Connector;
