import { Anchor, Box, Text, Title } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Button from '../../../components/Button';
import Checkbox from '../../../components/form/Checkbox/Checkbox';
import DateInput from '../../../components/form/DateInput';
import TextInput from '../../../components/form/TextInput';
import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import useAppLogin from '../../../hooks/useAppLogin';
import { ApiError, ErrorCode } from '../../../models/ApiError';
import RecaptchaAction from '../../../models/RecaptchaAction';
import { useStrongAuthRegisterFinalizeMutation } from '../../../store/api/strongAuthRegisterApi';
import { decodeTempToken, profileConfirmed } from '../../../store/strongAuthRegisterSlice';
import { birthDateFromPersonalCode } from '../../../utility';
import Marketing5RewardNotice from '../../../components/Rewards/Marketing5';

interface ProfileInfoFormValues {
  agreeToLoyaltyTerms: boolean;
  offersConsent: boolean;
  channelSms: boolean;
  channelEmail: boolean;
}

const ProfileInfo = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [createAccount, createAccountQueryState] = useStrongAuthRegisterFinalizeMutation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const tempToken = useAppSelector((state) => state.strongAuthRegister.tempToken)!;
  const [login] = useAppLogin();
  const tokenData = decodeTempToken(tempToken);
  const [dataIsCorrect, setDataIsCorrect] = useState(false);
  const [offersConsent, setOffersConsent] = useState(false);
  const [channelSms, setChannelSms] = useState(false);
  const [channelEmail, setChannelEmail] = useState(false);

  const form = useForm<ProfileInfoFormValues>({
    schema: yupResolver(
      Yup.object().shape({
        agreeToLoyaltyTerms: Yup.boolean(),
        offersConsent: Yup.boolean(),
        channelSms: Yup.boolean(),
        channelEmail: Yup.boolean(),
      }),
    ),
    initialValues: {
      agreeToLoyaltyTerms: false,
      offersConsent: false,
      channelSms: false,
      channelEmail: false,
    },
  });

  const getSaveProfileErrorMessage = useCallback(
    (error: ApiError) => {
      const errorCode = error.data?.message;
      if (error.status === 403) {
        return t('api.sessionExpiredError');
      }
      if (errorCode === ErrorCode.CardBirthdateVerificationFailed) {
        return t('registerCard.saveProfileData.dateOfBirthIncorrectError');
      }
      if (errorCode === ErrorCode.EmailOrPhoneCannotBeUsed) {
        return t('myProfile.profileDataForm.emailOrPhoneCannotBeUsedError');
      }
      if (errorCode === ErrorCode.CardNotFoundOrInInvalidState) {
        return t('registerCard.saveProfileData.cardNotFoundOrInInvalidStateError');
      }
      if (errorCode === ErrorCode.CardBirthdateVerificationFailed) {
        return t('registerCard.saveProfileData.dateOfBirthIncorrectError');
      }
      if (errorCode === ErrorCode.CreatingOrUpdatingProfileFailed) {
        return t('registerCard.saveProfileData.creatingProfileFailed');
      }
      if (errorCode === ErrorCode.RecaptchaError) {
        return t('api.recaptchaError');
      }
      return t('api.unknownError');
    },
    [t],
  );

  const tagManagerCompleteRegistration = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'strongAuthRegisterComplete',
    });
  };

  useEffect(() => {
    if (
      createAccountQueryState.isSuccess &&
      createAccountQueryState.data?.token &&
      createAccountQueryState.data?.personalData
    ) {
      login({
        jwt: createAccountQueryState.data.token,
        personalData: createAccountQueryState.data.personalData,
      });
      tagManagerCompleteRegistration();
      dispatch(profileConfirmed());
    }
  }, [
    createAccountQueryState.isSuccess,
    createAccountQueryState.data?.token,
    createAccountQueryState.data?.personalData,
    dispatch,
    login,
  ]);

  useEffect(() => {
    if (createAccountQueryState.isError && createAccountQueryState.error) {
      const error = createAccountQueryState.error as ApiError;
      const errorMessage = getSaveProfileErrorMessage(error);
      toast(errorMessage, { type: 'error' });
    }
  }, [createAccountQueryState.isError, createAccountQueryState.error, getSaveProfileErrorMessage, t]);

  const handleFormSubmit = useCallback(
    async (values: ProfileInfoFormValues) => {
      if (!values.agreeToLoyaltyTerms) {
        toast(t('strongAuthRegister.profileInfoStep.agreeToLoyaltyTerms.notAgreed'), { type: 'error' });
        return;
      }

      const recaptchaToken = await executeRecaptcha?.(RecaptchaAction.StrongAuthRegisterCreateAccount);
      createAccountQueryState.reset();
      createAccount({
        tempToken: tempToken,
        recaptchaToken: recaptchaToken,
        offersConsent: offersConsent,
        channelSms: channelSms,
        channelEmail: channelEmail,
      });
    },
    [executeRecaptcha, createAccountQueryState, createAccount, tempToken, t, channelEmail, channelSms, offersConsent],
  );

  const handleDataIsCorrectChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDataIsCorrect(e.target.checked);
  };

  const handleOffersConsentChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOffersConsent(e.target.checked);
  };

  const handleChannelSmsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChannelSms(e.target.checked);
  };

  const handleChannelEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChannelEmail(e.target.checked);
  };

  const handleConsentChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked;
    form.setFieldValue('agreeToLoyaltyTerms', value);
    if (form.errors.agreeToLoyaltyTerms) {
      form.clearErrors();
    }
  };

  return (
    <Box
      sx={(theme) => ({
        '&, & form': {
          gap: '8px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          [theme.fn.largerThan(500)]: {
            alignItems: 'flex-start',
          },
        },
        '& .mantine-TextInput-root': {
          marginBottom: theme.other.spacing(1),
          width: '100%',
        },
        '& .mantine-Button-root': {
          marginTop: theme.other.spacing(2.5),
          marginBottom: theme.other.spacing(2),
          width: '60%',
        },
        '& .mantine-TextInput-root input, & .mantine-DatePicker-root input': {
          opacity: 1,
        },
      })}
    >
      <form noValidate onSubmit={form.onSubmit(handleFormSubmit)}>
        <Title
          sx={(theme) => ({
            width: '60%',
            color: theme.colors.brandGray,
            fontFamily: 'ProximaNovaBold',
            fontSize: '1.25rem',
            fontWeight: 700,
            letterSpacing: '0.01em',
            [theme.fn.smallerThan(800)]: {
              fontSize: '2.2rem',
            },
            [theme.fn.smallerThan(600)]: {
              fontSize: '1.6rem',
              width: '100%',
            },
          })}
        >
          {t('strongAuthRegister.profileInfoStep.title')}
        </Title>
        <Text
          sx={(theme) => ({
            marginTop: theme.other.spacing(2),
            color: '#828282',
            width: '60%',
            [theme.fn.smallerThan(600)]: {
              width: '100%',
            },
          })}
        >
          <Text>{t('strongAuthRegister.profileInfoStep.description')}</Text>
        </Text>
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'flex-start',
            gap: '24px',
            flexWrap: 'wrap',
            [theme.fn.smallerThan(960)]: {
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            },
            '& .formSection': {
              display: 'flex',
              width: '250px',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '8px',
              flexShrink: 0,
              color: 'red',
              [theme.fn.smallerThan(550)]: {
                width: '100%',
              },
            },
          })}
        >
          <Box className="formSection">
            <TextInput
              label={t('strongAuthRegister.profileInfoStep.firstName')}
              disabled={true}
              value={tokenData.name}
            />
          </Box>
          <Box className="formSection">
            <TextInput
              label={t('strongAuthRegister.profileInfoStep.lastName')}
              disabled={true}
              value={tokenData.surname}
            />
          </Box>
          <Box className="formSection">
            <DateInput
              label={t('strongAuthRegister.profileInfoStep.birthdate')}
              placeholder={t('strongAuthRegister.profileInfoStep.birthdate')}
              clearable={false}
              disabled={true}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              value={new Date(birthDateFromPersonalCode(tokenData.personalCode)!)}
              sx={() => ({
                width: '100%',
              })}
            />
          </Box>
          <Box className="formSection">
            <TextInput
              label={t('strongAuthRegister.profileInfoStep.personalCode')}
              disabled={true}
              value={tokenData.personalCode}
            />
          </Box>
          <Box className="formSection">
            <TextInput label={t('strongAuthRegister.profileInfoStep.email')} disabled={true} value={tokenData.email} />
          </Box>
          <Box className="formSection">
            <TextInput
              label={t('strongAuthRegister.profileInfoStep.phoneNumber')}
              disabled={true}
              value={tokenData.phone}
            />
          </Box>
        </Box>
        <Box
          sx={(theme) => ({
            marginTop: theme.other.spacing(6),

            alignItems: 'flex-start',
          })}
        >
          <Checkbox
            onChange={handleOffersConsentChange}
            checked={offersConsent}
            label={t('strongAuthRegister.profileInfoStep.offersConsent')}
          ></Checkbox>
          <Marketing5RewardNotice accountId={tokenData.accountId} />
          <>
            <h3>
              <Text>{t('strongAuthRegister.profileInfoStep.channelsTitle')}</Text>
            </h3>
          </>
          <Checkbox
            onChange={handleChannelSmsChange}
            checked={channelSms}
            label={t('strongAuthRegister.profileInfoStep.channelSms')}
          ></Checkbox>
          <Checkbox
            onChange={handleChannelEmailChange}
            checked={channelEmail}
            label={t('strongAuthRegister.profileInfoStep.channelEmail')}
          ></Checkbox>
        </Box>
        <Box
          sx={(theme) => ({
            marginTop: theme.other.spacing(2),

            alignItems: 'flex-start',
          })}
        >
          <Checkbox
            onChange={handleDataIsCorrectChange}
            checked={dataIsCorrect}
            label={t('strongAuthRegister.profileInfoStep.dataIsCorrect')}
            required
          ></Checkbox>
          <Checkbox
            onChange={handleConsentChange}
            value={[form.values.agreeToLoyaltyTerms.toString()]}
            checked={form.values.agreeToLoyaltyTerms}
            required
            label={
              <Trans i18nKey="strongAuthRegister.profileInfoStep.agreeToLoyaltyTerms.label">
                I agree with personal data profiling. Read more at loyalty
                <Anchor
                  href={t('strongAuthRegister.profileInfoStep.agreeToLoyaltyTerms.TCUrl')}
                  target="_blank"
                  sx={() => ({
                    '&': {
                      fontSize: '0.9rem',
                    },
                  })}
                >
                  terms and conditions
                </Anchor>
                .
              </Trans>
            }
          ></Checkbox>
        </Box>
        <Button type="submit" disabled={!dataIsCorrect}>
          {t('strongAuthRegister.profileInfoStep.createAccount')}
        </Button>
      </form>
    </Box>
  );
};

export default ProfileInfo;
