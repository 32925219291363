import { useCallback, useEffect } from 'react';
import * as Yup from 'yup';
import { Box } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { useTranslation } from 'react-i18next';
import TextInput from '../../../components/form/TextInput';
import Button from '../../../components/Button';
import { ErrorCode } from '../../../models/ApiError';

interface VerifyContactFormProps {
  loading?: boolean;
  disabled?: boolean;
  onSubmit: (email: string) => void;
  error?: ErrorCode | string;
}

const VerifyContactForm = (props: VerifyContactFormProps) => {
  const { t } = useTranslation();
  const form = useForm({
    schema: yupResolver(
      Yup.object().shape({
        email: Yup.string()
          .email(t('landing.loginForm.emailInvalidError'))
          .required(t('registerCard.saveProfileData.emailRequiredError')),
      }),
    ),
    initialValues: {
      email: '',
    },
  });

  // eslint-disable-next-line
  const setFieldError = useCallback(form.setFieldError, []);

  useEffect(() => {
    if (props.error) {
      if (props.error === ErrorCode.EmailDoesNotMatch) {
        setFieldError('email', t('createAccount.sendCode.emailDoesNotMatchError'));
      }
    }
  }, [props.error, setFieldError, t]);

  const handleFormSubmit = (values: typeof form.values) => {
    props.onSubmit(values.email);
  };

  return (
    <Box
      sx={(theme) => ({
        '&, & form': {
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          [theme.fn.largerThan(500)]: {
            alignItems: 'center',
          },
        },
        '& .mantine-TextInput-root': {
          width: '100%',
          marginBottom: theme.other.spacing(3),
          [theme.fn.smallerThan(500)]: {
            marginBottom: theme.other.spacing(1),
          },
        },
        '& .mantine-Button-root': {
          marginTop: theme.other.spacing(1),
          marginBottom: theme.other.spacing(-3),
        },
      })}
    >
      <form noValidate onSubmit={form.onSubmit(handleFormSubmit)}>
        <TextInput
          {...form.getInputProps('email')}
          label={t('landing.loginForm.email')}
          placeholder={t('landing.loginForm.email')}
          required
          disabled={props.loading || props.disabled}
        />
        <Button type="submit" loading={props.loading}>
          {t('createAccount.sendCode.sendCode')}
        </Button>
      </form>
    </Box>
  );
};

export default VerifyContactForm;
