import { Anchor } from '@mantine/core';

interface TopNavLinkProps {
  title: string;
  href: string;
}

const TopNavLink = ({ title, href }: TopNavLinkProps) => (
  <Anchor
    key={title}
    href={href}
    title={title}
    target="_blank"
    rel="noreferrer"
    color="dimmed"
    underline={false}
    sx={(theme) => ({
      color: theme.colors.brandGray,
      margin: `0 ${theme.other.spacing(1.5)}`,
      '&:hover': {
        color: 'black',
      },
    })}
  >
    {title}
  </Anchor>
);

export default TopNavLink;
