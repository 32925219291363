import { DatePicker as MantineDatePicker, DatePickerProps as MantineDatePickerProps } from '@mantine/dates';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useTranslation } from 'react-i18next';
import 'dayjs/locale/et';
import 'dayjs/locale/ru';
import 'dayjs/locale/en';

const DateInput = (props: MantineDatePickerProps) => {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <MantineDatePicker
      key={language}
      locale={language}
      inputFormat={'DD. MMMM YYYY'}
      radius={5}
      sx={(theme) => ({
        '& .mantine-DatePicker-label': {
          color: theme.colors.maximaBlueLight,
          letterSpacing: '0.02em',
          fontSize: '0.75rem',
        },
        '& .mantine-DatePicker-input': {
          paddingTop: '2px',
          transition: 'all 0.1s',
          borderRadius: '5px',
          color: theme.colors.brandGray,
          fontWeight: 400,
          fontSize: '0.875rem',
          '&:not(.mantine-DatePicker-disabled, .mantine-DatePicker-invalid)': {
            borderColor: theme.colors.maximaBlueLight,
          },
          '&.mantine-DatePicker-invalid': {
            borderColor: theme.colors.errorLight,
          },
          '&::placeholder': {
            color: theme.colors.maximaBlueLight,
          },
          '&:focus': {
            borderColor: theme.colors.orange,
          },
        },
        '& .mantine-DatePicker-icon': {
          '& .MuiSvgIcon-root': {
            color: props.disabled ? theme.colors.disabledIcon : theme.colors.maximaBlueDefault,
          },
          '&.mantine-DatePicker-disabled': {
            '&, & *': {
              color: theme.colors.disabledText,
            },
          },
        },
        '& .mantine-DatePicker-error': {
          color: theme.colors.errorLight,
        },
      })}
      icon={<CalendarMonthIcon fontSize="small" />}
      {...props}
    />
  );
};

export default DateInput;
