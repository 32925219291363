import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Box } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { useTranslation } from 'react-i18next';
import TextInput from '../../../components/form/TextInput';
import Button from '../../../components/Button';
import { useChangePasswordMutation, useValidatePasswordMutation } from '../../../store/api/authApi';
import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { ApiError, ErrorCode } from '../../../models/ApiError';
import { setToken } from '../../../store/userSlice';

interface ChangePasswordFormProps {
  onChangeToProfileView: () => void;
}

const ChangePasswordForm = ({ onChangeToProfileView }: ChangePasswordFormProps) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const profileData = useAppSelector((state) => state.user.personalData?.profileData);

  const [changePassword, changePasswordQueryState] = useChangePasswordMutation();
  const [validatePassword, validatePasswordQueryState] = useValidatePasswordMutation();

  const form = useForm({
    schema: yupResolver(
      Yup.object().shape({
        oldPassword: Yup.string().required(t('myProfile.changePassword.oldPasswordRequiredError')),
        newPassword: Yup.string().required(t('forgotPassword.changePassword.passwordRequiredError')),
        repeatPassword: Yup.string()
          .required(t('forgotPassword.changePassword.passwordRequiredError'))
          .oneOf([Yup.ref('newPassword')], t('forgotPassword.changePassword.passwordsDoNotMatchError')),
      }),
    ),
    initialValues: {
      oldPassword: '',
      newPassword: '',
      repeatPassword: '',
    },
  });

  const getChangePasswordErrorMessage = useCallback(
    (error: ApiError) => {
      const errorCode = error.data?.message;
      if (error.status === 403) {
        return t('api.sessionExpiredError');
      }
      if (errorCode === ErrorCode.PasswordChangeFailed) {
        return t('forgotPassword.changePassword.passwordChangeError');
      }
      return t('api.unknownError');
    },
    [t],
  );

  // eslint-disable-next-line
  const setFieldError = useCallback(form.setFieldError, []);

  useEffect(() => {
    if (
      validatePasswordQueryState.isSuccess &&
      form.values.newPassword === validatePasswordQueryState.originalArgs?.password
    ) {
      if (validatePasswordQueryState.data.isValid) {
        changePassword({
          currentPassword: form.values.oldPassword,
          newPassword: form.values.newPassword,
          emailOrPhoneNo: profileData?.email || profileData?.phoneNumber || '',
        });
      } else {
        const errorMessage = validatePasswordQueryState.data.messages.find(
          (message) => message.languageCode === i18n.language,
        );
        if (errorMessage) {
          setFieldError('newPassword', errorMessage.message);
        }
      }
    }
  }, [
    validatePasswordQueryState.data,
    validatePasswordQueryState.isSuccess,
    validatePasswordQueryState.originalArgs?.password,
    setFieldError,
    changePassword,
    form.values.newPassword,
    form.values.oldPassword,
    profileData?.email,
    profileData?.phoneNumber,
    i18n.language,
  ]);

  useEffect(() => {
    if (validatePasswordQueryState.isError) {
      toast(t('api.unknownError'), { type: 'error' });
    }
  }, [validatePasswordQueryState.isError, t]);

  useEffect(() => {
    if (changePasswordQueryState.isSuccess) {
      dispatch(setToken(changePasswordQueryState.data.jwt));
      toast(t('forgotPassword.changePassword.passwordChangeSuccess'), { type: 'success' });
      onChangeToProfileView();
    }
  }, [changePasswordQueryState.isSuccess, changePasswordQueryState.data, dispatch, onChangeToProfileView, t]);

  useEffect(() => {
    if (changePasswordQueryState.isError) {
      const error = changePasswordQueryState.error as ApiError;
      const errorCode = error.data?.message;
      if (errorCode === ErrorCode.InvalidPassword) {
        setFieldError('oldPassword', t('myProfile.changePassword.oldPasswordInvalidError'));
      } else {
        toast(getChangePasswordErrorMessage(error), { type: 'error' });
      }
    }
  }, [
    changePasswordQueryState.isError,
    changePasswordQueryState.error,
    setFieldError,
    getChangePasswordErrorMessage,
    t,
  ]);

  // eslint-disable-next-line
  const handleFormSubmit = (values: typeof form.values) => {
    validatePassword({
      password: values.newPassword,
    });
  };

  return (
    <Box
      sx={(theme) => ({
        maxWidth: '500px',
        width: '100%',
        '&, & form': {
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        },
        '& .mantine-TextInput-root': {
          marginBottom: theme.other.spacing(1),
          width: '100%',
        },
      })}
    >
      <form noValidate onSubmit={form.onSubmit(handleFormSubmit)}>
        <TextInput
          name="oldPassword"
          type="password"
          autoComplete="new-password"
          label={t('myProfile.changePassword.oldPassword')}
          required
          {...form.getInputProps('oldPassword')}
          disabled={changePasswordQueryState.isLoading}
        />
        <TextInput
          name="newPassword"
          type="password"
          autoComplete="new-password"
          label={t('forgotPassword.changePassword.newPassword')}
          required
          {...form.getInputProps('newPassword')}
          disabled={changePasswordQueryState.isLoading}
        />
        <TextInput
          name="repeatPassword"
          type="password"
          autoComplete="new-password"
          label={t('forgotPassword.changePassword.repeatPassword')}
          required
          {...form.getInputProps('repeatPassword')}
          disabled={changePasswordQueryState.isLoading}
        />
        <Box
          sx={(theme) => ({
            marginTop: theme.other.spacing(2),
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            gap: theme.other.spacing(1),
            [theme.fn.smallerThan(500)]: {
              flexDirection: 'column-reverse',
              gap: theme.other.spacing(3),
            },
          })}
        >
          <Button variant="outline" onClick={onChangeToProfileView} disabled={changePasswordQueryState.isLoading}>
            {t('myProfile.changePassword.cancel')}
          </Button>
          <Button type="submit" loading={changePasswordQueryState.isLoading}>
            {t('myProfile.changePassword.confirm')}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default ChangePasswordForm;
