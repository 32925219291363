import { Box, Image, Text, Title } from '@mantine/core';
import loyaltyCardTilted from '../../../assets/svg/logos/loyalty_card_tilted.svg';

interface RegisterCardSubHeaderProps {
  title: string;
  subtitle?: string;
}

const RegisterCardSubHeader = (props: RegisterCardSubHeaderProps) => {
  return (
    <Box
      className="subheader"
      sx={(theme) => ({
        paddingTop: theme.other.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: '110px',
        [theme.fn.smallerThan(600)]: {
          paddingTop: theme.other.spacing(4),
          paddingBottom: theme.other.spacing(3),
          justifyContent: 'center',
        },
      })}
    >
      <Box
        sx={(theme) => ({
          paddingBottom: theme.other.spacing(1),
        })}
      >
        <Title
          sx={(theme) => ({
            color: theme.colors.brandWhite,
            fontWeight: 500,
            fontSize: '2.4rem',
            fontFamily: 'ProximaNova',
            letterSpacing: '0.01em',
            [theme.fn.smallerThan(800)]: {
              fontSize: '2.2rem',
            },
            [theme.fn.smallerThan(600)]: {
              fontSize: '1.8rem',
            },
          })}
        >
          {props.title}
        </Title>
        {props.subtitle && (
          <Text
            sx={(theme) => ({
              color: theme.colors.brandWhite,
              letterSpacing: '0.02em',
            })}
          >
            {props.subtitle}
          </Text>
        )}
      </Box>
      <Image
        width={140}
        src={loyaltyCardTilted}
        alt=""
        sx={(theme) => ({
          filter: 'drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.16))',
          [theme.fn.smallerThan(600)]: {
            display: 'none',
          },
        })}
      />
    </Box>
  );
};

export default RegisterCardSubHeader;
