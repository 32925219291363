import { Popover, PopoverProps as MantinePopoverProps, Box } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { ReactNode } from 'react';

interface HelpTooltipProps extends Omit<MantinePopoverProps, 'children' | 'opened' | 'target'> {
  label: ReactNode;
}

const HelpTooltip = (props: HelpTooltipProps) => {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <Popover
      {...props}
      width={220}
      position="bottom"
      shadow="sm"
      withArrow
      opened={opened}
      target={
        <Box
          sx={(theme) => ({
            display: 'flex',
            '& .MuiSvgIcon-root': {
              color: theme.colors.maximaBlueDefault,
              cursor: 'default',
            },
          })}
          onMouseEnter={open}
          onMouseLeave={close}
        >
          <HelpOutlineIcon onClick={(e) => e.preventDefault()} />
        </Box>
      }
    >
      <Box
        sx={(theme) => ({
          color: theme.colors.brandGray,
          fontSize: '0.9rem',
        })}
      >
        {props.label}
      </Box>
    </Popover>
  );
};

export default HelpTooltip;
