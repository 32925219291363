import decode from 'jwt-decode';
import { TestFunction } from 'yup';
import MaximaAuthDecodedToken from '../models/MaximaAuthDecodedToken';

export const STORAGE_MAXIMA_AUTH_TOKEN_KEY = 'maximaAuthToken';
export const PERSONAL_DATA_KEY = 'personalData';
export const BIRTHDAY_OFFERS_DECLINED_KEY = 'birthdayOffersDeclinedKey';

export const ESTONIAN_MOBILE_NUMBER_REGEX = /^((\+|00)?372)?([015]([0-9]){6,7}|8([0-9]){7})$/;
export const EMAIL_REGEX = /^\s*\w+([-+%.]\w+)*[-+.]?@\w+([-.]\w+)*\.(\w+([-]\w+)*){2,63}\s*$/;
export const ONLY_NUMERICS_REGEX = /^\d+$/;
export const MATCH_ROOT_ROUTE_PATH = /^\/$/;

/**
 * @author Hardi Kõvamees <hardikovamees@gmail.com>
 * @param {string|number} a
 * @returns {boolean} Is valid Estonian NIN?
 */
export function isValidNIN(a: string | number) {
  if (!a) return true;

  const nin = String(a).split('').map(Number);

  if (nin.length !== 11) return false; // length invalid

  const c = ((x) => [null, '18', '18', '19', '19', '20', '20'][x])(nin[0]);
  if (!c) return false; // sex and century invalid

  const date = `${c + nin[1] + nin[2]}-${nin[3]}${nin[4]}-${nin[5]}${nin[6]}`;
  if (isNaN(Date.parse(date))) return false; // birth date invalid

  const mod = (nin2: number[], weights: number[]) =>
    nin2.slice(0, 10).reduce((sum: number, num: number, i: number) => {
      return num * weights[i] + sum;
    }, 0) % 11;
  return (
    nin[10] ===
    [mod(nin, [1, 2, 3, 4, 5, 6, 7, 8, 9, 1]), mod(nin, [3, 4, 5, 6, 7, 8, 9, 1, 2, 3]), 0].find(
      (modulus) => modulus !== 10,
    )
  );
}

export const checkIfHasPersonalCode: TestFunction = (_value, context) => (context.parent.personalCode ? true : false);

export const checkIfHasPhone: TestFunction = (_value, context) => (context.parent.phone ? true : false);

export const checkIfHasEmail: TestFunction = (_value, context) => (context.parent.email ? true : false);

export const checkIfHasPhoneOrEmail: TestFunction = (_value, context) =>
  context.parent.phone || context.parent.email ? true : false;

export const checkIfPhoneNumberIsInvalid: TestFunction = (value) => {
  return !value || typeof value !== 'string' ? true : ESTONIAN_MOBILE_NUMBER_REGEX.test(value) ? false : true;
};

export const checkIfPersonalCodeIsValid: TestFunction = (value) =>
  !value || typeof value !== 'string' ? true : isValidNIN(value);

export const getMaximaAuthTokenFromStorage = () => {
  const token = localStorage.getItem(STORAGE_MAXIMA_AUTH_TOKEN_KEY);
  if (token) {
    try {
      const { Exp } = decode<MaximaAuthDecodedToken>(token);
      if (Date.now() >= +Exp * 1000) {
        return null;
      }
    } catch (err) {
      return null;
    }
  }
  return token;
};

export const getMaximaAuthTokenPayload = (token?: string | null) => {
  if (token) {
    try {
      const { AccountId, CardNo } = decode<MaximaAuthDecodedToken>(token);
      return {
        accountId: AccountId,
        cardNumber: CardNo,
      };
    } catch (err) {
      return null;
    }
  }
  return null;
};

export const getMaximaAuthTokenPayloadFromStorage = () => {
  const token = localStorage.getItem(STORAGE_MAXIMA_AUTH_TOKEN_KEY);
  return getMaximaAuthTokenPayload(token);
};

export const getCardNumberFromMaximaAuthToken = () => {
  const token = localStorage.getItem(STORAGE_MAXIMA_AUTH_TOKEN_KEY);
  const payload = getMaximaAuthTokenPayload(token);
  return payload?.cardNumber || '';
};

export const getIsBirthdayOfferDeclined = (accId: number) => {
  const dataStr = localStorage.getItem(BIRTHDAY_OFFERS_DECLINED_KEY);
  if (dataStr) {
    const arr = JSON.parse(dataStr) as number[];
    return arr.includes(accId);
  }
  return false;
};

export const setBirthdayOfferDeclined = (accId: number | undefined) => {
  if (!accId) return;
  const dataStr = localStorage.getItem(BIRTHDAY_OFFERS_DECLINED_KEY);
  let arr: number[] = [];
  if (dataStr) {
    arr = JSON.parse(dataStr) as number[];
  }
  if (!arr.includes(accId)) arr.push(accId);
  localStorage.setItem(BIRTHDAY_OFFERS_DECLINED_KEY, JSON.stringify(arr));
};

export const getDateOfBirthString = (date: Date | string | number) => {
  const dateOfBirth = new Date(date);
  const day = dateOfBirth.getDate();
  const month = dateOfBirth.getMonth() + 1;
  const year = dateOfBirth.getFullYear();
  return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
};

export const getDateOfBirthObjectFromPersonalCode = (personalCode: string) => {
  return {
    year: (
      1800 +
      Math.floor((parseInt(personalCode.charAt(0), 10) - 1) / 2) * 100 +
      parseInt(personalCode.substring(1, 3), 10)
    ).toString(),
    month: personalCode.substring(3, 5),
    day: personalCode.substring(5, 7),
  };
};

export enum Language {
  ESTONIAN = '1',
  ENGLISH = '2',
  RUSSIAN = '3',
}

export enum StrongAuthMode {
  SmartId = 1,
  MobileId = 2,
  IdCard = 3,
}

export const populateCheckboxGroupValue = (formValues: { [field: string]: boolean | undefined }) => {
  const checkboxGroupValues: string[] = [];
  for (const field in formValues) {
    if (formValues[field] === true) {
      checkboxGroupValues.push(field);
    }
  }
  return checkboxGroupValues;
};

export const capitalizeFirstLetter = (string: string) => {
  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
};

export const convertToCamelCase = (strings: string[]) => {
  return strings.map((string, index) => (index === 0 ? string : capitalizeFirstLetter(string))).join('');
};

export const pad = (num: number, size: number) => {
  return `000000000${num}`.slice(-size);
};

export const isValidEstonianMobilePhoneOrEmail = (value?: string) => {
  if (!value) return false;
  const isPhone = ESTONIAN_MOBILE_NUMBER_REGEX.test(value.replaceAll(' ', ''));
  const isEmail = EMAIL_REGEX.test(value);
  if (!isEmail && !isPhone) return false;
  return true;
};

// taken from: https://stackoverflow.com/a/62765924
// eslint-disable-next-line
export const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
  arr.reduce((groups, item) => {
    (groups[key(item)] ||= []).push(item);
    return groups;
  }, {} as Record<K, T[]>);

export const sum = (arr: number[]) => arr.reduce((a, b) => a + b, 0);

export const birthDateFromPersonalCode = (personalCode: string | null | undefined): string | null => {
  if (!personalCode) return null;

  const GetYear = (yearPart: string, genderPart: string) => {
    const hundredMultiplier = Math.ceil(Number(genderPart) / 2) - 1;
    const year = Number(yearPart) + 1800 + hundredMultiplier * 100;

    return year;
  };

  const year = GetYear(personalCode.substring(1, 3), personalCode.substring(0, 1)).toString();
  const month = personalCode.substring(3, 5);
  const day = personalCode.substring(5, 7);

  return `${year}-${month}-${day}`;
};
