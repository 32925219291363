import { Anchor as MantineAnchor, AnchorProps } from '@mantine/core';

function Link<C = 'a'>(props: AnchorProps<C>) {
  return (
    <MantineAnchor
      {...props}
      component={props.component as 'symbol' | undefined}
      underline
      sx={(theme) => ({
        '&.mantine-Anchor-root': {
          color: theme.colors.maximaBlueDefault,
          transition: 'all 0.1s',
          textDecoration: 'none',
          '&:hover': {
            color: theme.colors.maximaBlueLight,
          },
        },
      })}
    />
  );
}

export default Link;
