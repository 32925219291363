import { useAppSelector } from '../../hooks/store';

function useGetName() {
  const firstName = useAppSelector((state) => state.user.personalData?.profileData.firstName);
  const lastName = useAppSelector((state) => state.user.personalData?.profileData.lastName);

  if (firstName && lastName) return `${firstName} ${lastName}`;
  if (firstName) return firstName;
  return null;
}

export default useGetName;
